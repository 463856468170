<template>
  <b-modal id="confirmDeleteLocation" :title="`Delete Location ${location ? location.name : ''}`" ok-title="Delete"
           no-close-on-backdrop
           @ok="deleteItem">
    <div class="text-center">
      <p class="m-auto">
        Are you sure you want to delete
        <br/>
        {{ location ? location.name : '' }}
        <br/>
      </p>
    </div>
  </b-modal>
</template>

<script>
import {deleteLocation} from "@/utils/actions";

export default {
  name: "confirmDeleteLocation",
  props: {
    'location': Object,
  },
  methods: {
    deleteItem() {
      deleteLocation(this.location).then(() => {
        this.$bvModal.hide("confirmDeleteLocation");
        this.$emit('locationDeleted');
      }).catch(() => {
        this.$bvModal.hide("confirmDeleteLocation");
      });
    }
  }
}
</script>

