/*
    General Application Utilities
 */
import {APP_UPDATE_ITEM_ID} from "@/utils/actionConstants";

export default {
    data() {
        return {
            debounceTimer: null,
        }
    },
    methods: {
        getUserFullName(profile) {
            if (!profile) {
                profile = this.$store.getters.profile
            }

            if (profile.first_name || profile.last_name) {
                const name = `${profile.first_name ? profile.first_name.trim() : ''} ${profile.last_name ? profile.last_name.trim() : ''}`.trim();
                if (name.length > 0) {
                    return name;
                }
            }
            return 'Unknown User';
        },
        isGlobalProductImage(item) {
            return !item.image && (item.product && item.product.image);
        },
        hasImage(item) {
            return item.image || (item.product && item.product.image) || (item.imageData && item.imageData.imageUrl);
        },
        isVideo(item) {
            let image_name = null;
            if (item.image === 'new-image' && item.imageData) {
                image_name = item.imageData.file.name.toLowerCase();
            } else if (item.image) {
                image_name = item.image.toLowerCase();
            } else {
                return false;
            }
            return !(image_name.endsWith('jpg') || image_name.endsWith('jpeg') || image_name.endsWith('png') || image_name.endsWith('webp') || image_name.endsWith('heic'));
        },
        getImageUrl(item) {
            if (item.imageData && item.imageData.imageUrl) {
                return item.imageData.imageUrl;
            } else {
                return this.getProductUrl(item.image || (item.product && item.product.image));
            }
        },
        getProductUrl(productUrl) {
            return productUrl ? `${process.env.VUE_APP_SERVER_BASE_URL}/static/product_images/${productUrl}` : `/assets/img/no_image.png`;
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        capitalize(str) {
            const parts = str.split(' ');
            for (let i = 0; i < parts.length; i++) {
                parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
            }
            return parts.join(' ');
        },
        formatTagList(tagList) {
            if (tagList) {
                return tagList.map(t => t.tag).sort().join(', ');
            } else {
                return '';
            }
        },
        ctmhDebounce(callback, time) {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(callback, time);
        },
        updateRoutePage(routeName, pageNum) {
            this.$router.replace({name: routeName, query: {page: pageNum}}).catch(() => {});
        },
        uniqueItemId() {
            this.$store.dispatch(APP_UPDATE_ITEM_ID).catch(() => {});
            return this.$store.getters.uniqueItemId;
        },
        /*
        First ensures that all items in the selectedList are contained in the optionList just in case a new item has
        been added to the selectedList. Then removes the items in selectedList from the optionList.
        Note that the optionList parameter is modified in place so the original optionList will be updated. The returned
        list contains the reduced options => final optionList - selectedList.
         */
        reduceList(optionList, selectedList, sortKey) {
            // Ensure the selected items are in the master optionsList to start
            for (let i=0; i < selectedList.length; i++) {
                if (optionList.findIndex((o) => o.id === selectedList[i].id) < 0) {
                    optionList.push(selectedList[i]);
                }
            }

            // Now get a sub-list that does not contain the items in selectedList
            let options = optionList;
            for (let i = 0; i < selectedList.length; i++) {
                const tag = selectedList[i];
                options = options.filter(obj => {
                    return obj.id !== tag.id;
                });
            }
            options.sort((i1, i2) => i1[sortKey].localeCompare(i2[sortKey]));
            return options;
        },
    }
}
