<script>
export default {
  name: "legalDocument",
  props: ['title', 'content']
}
</script>

<template>
  <div class="w-100 bg-white p-4">
    <div>
      <h1 class="text-center">{{ title }}</h1>
    </div>
    <div>
      <span v-html="content"></span>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>