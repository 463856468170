import {
    API_LOGOUT,
    API_SET_API_ERROR,
    APP_UPDATE_ITEM_ID,
    SET_AUTH_TOKEN,
    SET_FORGOT_PASSWORD,
    SET_LAST_VERSION_CHECK,
    SET_PROFILE
} from "@/utils/actionConstants";
import {DateTime} from "luxon";

const state = {
    perPageChoices: [5, 10, 25, 50],
    profile: {
        id: localStorage.makerId,
        subscription: null
    },
    lastVersionCheck: localStorage.lastVersionCheck || DateTime.now().minus({days: 1}).toISO(),
    apiStatus: 0,
    apiStatusText: null,
    accessToken: localStorage.token,
    refreshToken: localStorage.refreshToken,
    nextTokenRefresh: DateTime.now().plus({hours: 23}),
    forgotPassword: false,
    uniqueId: 0,
    startTrial: false
};

const getters = {
    profile: state => state.profile,
    apiStatus: state => {
        return {status: state.apiStatus, statusText: state.apiStatusText}
    },
    isLoggedIn: state => !!(state.accessToken),
    isProfileValid: state => !!state.profile.email,
    isAdmin: state => !!state.profile.is_superuser,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    nextTokenRefresh: state => state.nextTokenRefresh,
    makerId: state => state.profile.id,
    appVersion: () => process.env.VUE_APP_VERSION,
    lastVersionCheck: () => DateTime.fromISO(state.lastVersionCheck),
    userCompletedForgotPassword: state => state.forgotPassword,
    perPage: state => state.profile && state.profile.preferences ? state.profile.preferences.perPage : 25,
    productView: state => state.profile && state.profile.preferences ? state.profile.preferences.productView : 0,
    seenFirstTimeTutorial: state => state.profile && state.profile.preferences ? !!state.profile.preferences.seenFirstTimeTutorial : false,
    perPageChoices: state => state.perPageChoices,
    uniqueItemId: state => state.uniqueId,
    hasSubscription: state => !!(state.profile.subscription && state.profile.subscriptionEnd > DateTime.now().toUTC())
}

const mutations = {
    [SET_FORGOT_PASSWORD](state, payload) {
        state.forgotPassword = payload;
    },
    [SET_LAST_VERSION_CHECK](state, payload= null) {
        if (payload == null) {
            state.lastVersionCheck = DateTime.now().toISO();
        } else {
            state.lastVersionCheck = payload;
        }
        localStorage.lastVersionCheck = state.lastVersionCheck;
    },
    [API_LOGOUT](state) {
        const lastVersionCheck = state.lastVersionCheck;
        localStorage.clear();
        state.profile = {id: 0, subscription: null};
        state.userProductList = [];
        state.refreshToken = null;
        state.accessToken = null;

        // Put the last version check back into localStorage
        localStorage.lastVersionCheck = lastVersionCheck;
    },
    [SET_PROFILE](state, payload) {
        state.profile = {...payload.data};
        if (state.profile.subscription) {
            state.profile.subscriptionEnd = DateTime.fromISO(state.profile.subscription.end_date);
        } else {
            state.profile.subscriptionEnd = DateTime.now().toUTC();
        }
        state.profile.preferences = JSON.parse(state.profile.preferences);
        localStorage.makerId = state.profile.id;
    },
    [API_SET_API_ERROR](state, payload) {
        state.apiStatus = payload.status;
        state.apiStatusText = payload.statusText;
    },
    [SET_AUTH_TOKEN](state, payload) {
        state.accessToken = payload.data.access;
        state.refreshToken = payload.data.refresh;
        state.nextTokenRefresh = DateTime.now().plus({hours: 23});

        // Set persistent data
        localStorage.token = state.accessToken;
        localStorage.refreshToken = state.refreshToken;
    },
    [APP_UPDATE_ITEM_ID](state) {
        state.uniqueId -= 1;
    }
}

const actions = {
    [API_LOGOUT]({commit}) {
        commit(API_LOGOUT);
    },
    [API_SET_API_ERROR]({commit}, payload) {
        commit(API_SET_API_ERROR, payload);
    },
    [APP_UPDATE_ITEM_ID]({commit}) {
        commit(APP_UPDATE_ITEM_ID);
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
};
