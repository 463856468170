<template>
  <b-card class="p-0 m-0">
    <b-card-body class="p-0">
      <b-card-title class="p-0 mb-1">
        <b-row>
          <b-col cols="9" lg="10">{{ item.product.item_number }}: {{ item.product.name }}</b-col>
          <b-col class="px-1 text-center">
            <font-awesome-icon class="action-button mr-3" icon="edit" @click="$emit('editItem', item)"/>
            <font-awesome-icon class="action-button text-danger" icon="trash" @click="$emit('deleteItem', item)"/>
          </b-col>
        </b-row>
      </b-card-title>
      <b-row>
        <b-col cols="8" class="pr-2">
          <div class="location-text mb-1">{{ item.location ? item.location.name : '' }}</div>
          <div tabindex="0" class="tag-wrapper" :id="`item-tags-${item.id}`">
            <span :id="`item-tags-${item.id}`" class="w-100 item-tags">
              {{ formatTagList(item.tags) }}
            </span>
            <b-tooltip v-if="item.tags && item.tags.length > 0" :ref="`item-tags-${item.id}-tooltip`"
                       :target="`item-tags-${item.id}`" triggers="hover"
                       custom-class="tag-tip-wrapper" boundary="viewport" placement="top">
              <div class="tag-tip">
                {{ formatTagList(item.tags) }}
              </div>
            </b-tooltip>
          </div>
        </b-col>
        <b-col col class="p-0 pt-3">
          <div class="product-picture-wrapper">
            <div class="w-100 h-100 position-relative text-center">
              <b-img :id="`ctmh_row_${item.id}`" :src="getImageUrl(item)" class="product-list-thumb"
                     style="object-fit: scale-down; -o-object-fit: scale-down"/>
              <span v-if="!hasImage(item)" class="text-center no-image-text">No Image</span>
            </div>
          </div>
          <b-tooltip v-if="hasImage(item)" :target="`ctmh_row_${item.id}`" triggers="hover"
                     placement="right"
                     boundary="viewport">
            <img :src="getImageUrl(item)" width="256px" height="256px" style="object-fit: contain"
                 :alt="`${item.item_number} image`"/>
            <font-awesome-icon v-if="!isGlobalProductImage(item)" class="tip-trash" icon="trash" size="lg"
                               @click="deleteMakerProductImage(item)"></font-awesome-icon>
          </b-tooltip>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "userProductCard",
  props: {
    item: Object,
  },
  methods: {
    deleteMakerProductImage(item) {
      this.$root.$emit('bv::hide::tooltip', `ctmh_row_${item.id}`)
      this.$emit('deleteMakerImage', item);
    }
  }
}
</script>

<style scoped lang="scss">
@import '/src/assets/scss/colors';

.card-title {
  font-size: 16px;
}

.card-bg {
  background-color: rgba($active-control, .5);
  border-radius: 15px;
}

.product-picture-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.product-list-thumb {
  border-radius: 15px;
  width: 100px;
  height: auto;
  border: black solid 1px;
  background-color: #D4E0E6;
}

.no-image-text {
  margin: 0;
  padding: 0;
  font-size: x-small;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.product-title {
  margin-left: 1.25rem;
  padding-top: 1.2rem;
  padding-right: 1.0rem;
  font-size: 16pt;
}

.edit-actions {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}

.location-text {
  font-size: small;
  font-weight: bold;
}

.item-tags {
  white-space: normal;
  font-size: smaller;
}

.tag-wrapper {
  max-height: 71px !important;
  height: 71px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tag-tip {
  text-align: start;
}

.tag-tip-wrapper {
  max-width: 70% !important;
}

.tip-trash {
  color: red;
  padding: 2px;
  margin-left: 3px;
  vertical-align: top;
  cursor: pointer;
}

</style>
