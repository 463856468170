<template>
  <transition name="fade">
    <div
        v-if="show"
        :class="alertType"
        class="alert alert-dismissible fade show"
        role="alert"
    >
      <slot></slot>
      <button v-if="showClose" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span
          aria-hidden="true"
          @click="$emit('dismissed')"
      >&times;</span>
      </button>
    </div>
  </transition>

</template>

<script>
export default {
  name: 'AlertDialog',
  props: {
    'variant': String,
    'show': Boolean,
    'showClose': {
      default: true,
      type: Boolean,
      required: false
    }
  },
  computed: {
    alertType() {
      return `alert-${this.variant}`
    }
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
