<template>
  <div>
    <AuthCarousel/>

    <!-- Login Dialog -->
    <div class="login-modal">
      <b-row class="m-0">
        <Entice/>
        <div class="col-12 col-md-6 p-0 order-first order-md-last">
          <div class="min-vh-100" style="background-color: rgba(1, 1, 1, .35);">
          <b-row class="m-0">
            <div class="col-12 justify-content-end">
                <LoginPage v-if="mode==='login'" @transition="doTransition"/>
                <RegisterPage v-if="mode==='register'" @transition="doTransition"/>
                <ForgotPassword v-if="mode==='forgotPassword'" @transition="doTransition"/>
              </div>
            </b-row>
          </div>
        </div>
      </b-row>
    </div>
  </div>

</template>

<script>
import Entice from "./auth-comp-entice"
import AuthCarousel from "./auth-comp-carousel";
import LoginPage from "./auth-comp-login";
import RegisterPage from "./auth-comp-register";
import ForgotPassword from "./auth-comp-forgotPassword";

export default {
  name: "authPage",
  components: {
    ForgotPassword,
    RegisterPage,
    LoginPage,
    Entice,
    AuthCarousel
  },
  data() {
    return {
      mode: 'login',
    }
  },
  methods: {
    doTransition(mode) {
      this.mode = mode;
    }
  }
}
</script>

<style lang="scss">

.login-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  z-index: 10;
}

</style>
