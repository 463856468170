<template>
  <div id="community-list" class="p-2 rounded-2 bg-white">
    <div :class="{'recipe-hide': showRecipeCard}" class="row mb-2 align-items-center">
      <div class="col-12 col-md-6 col-lg-4">
        <b-input-group>
          <b-input id="communitySearchBar" type="text" placeholder="Search..."
                   v-model="searchText" @input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-12 col-md-5 col-lg-3 align-items-center my-1">
        <b-checkbox v-model="favoritesOnly">Favorites only</b-checkbox>
      </div>
    </div>
    <b-row :class="{'recipe-hide': showRecipeList}" class="m-0 back-navigation">
      <b-button class="ml-2" @click="backToList">
        <font-awesome-icon icon="arrow-left"/>
      </b-button>
    </b-row>
    <b-row>
      <b-col :class="{'recipe-hide': showRecipeCard}" cols="12" lg="4">
        <div class="table-responsive">
          <b-table
              ref="recipeTable"
              no-local-sorting
              primary-key="id"
              thead-class="d-none"
              class="mt-2"
              :items="filteredRecipes"
              :fields="fields"
              selectable
              select-mode="single"
              selected-variant="info"
              @row-selected="onRowSelected">
            <template #cell(record)="data">
              <div :id="`community-recipe-${data.index}`" class="position-relative">
                <!-- use ['far', 'heart'] for non-favorite recipes -->
                <font-awesome-icon class="position-absolute text-primary" style="right: 0" :icon="[data.item.isFavorite ? 'fas' : 'far', 'heart']" role="button" @click="toggleFavorite(data.index)"/>
                <div class="d-flex align-items-center">
                  <div class="d-inline-block" style="max-width: 64px">
                    <b-carousel :interval="3500">
                      <b-carousel-slide v-for="image in data.item.images" :key="image.id">
                        <template #img>
                          <div v-if="isVideo(image)" class="community-list-image">
                          <div class="embed-responsive embed-responsive-4by3">
                            <video :src="getImageUrl(image)" loop muted></video>
                          </div>
                          </div>
                          <b-img v-else :src="getImageUrl(image)" class="community-list-image"/>
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="d-inline-block ml-2">
                    <div class="font-weight-bold community-list-text">{{ data.item.name }}</div>
                    <div :id="`notes_${data.item.id}`" class="recipe community-list-text" v-html="data.item.notes"></div>
                    <b-tooltip v-if="data.item.notes" :target="`notes_${data.item.id}`" triggers="hover"
                               placement="right"
                               boundary="viewport">
                    </b-tooltip>
                    <div class="pt-1" style="font-size: smaller">
                      <span class="font-weight-bolder">{{ data.item.maker.first_name }}&nbsp;{{data.item.maker.last_name }}</span>, {{ formatRecipeDate(data.item) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

          </b-table>
        </div>
      </b-col>
      <b-col :class="{'recipe-hide': showRecipeList}" cols="12" lg="8">
        <b-row class="justify-content-evenly m-0">
          <RecipeCard v-if="currentRecipe" :static-card="true" :recipe="currentRecipe" class="col-12"
                      :key="currentRecipe.id"/>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {defineComponent} from 'vue'
import RecipeCard from "@/pages/recipes/recipe-card.vue";
import {addFavorite, deleteFavorite, fetchMakerRecipe, fetchMakerRecipes} from "@/pages/recipes/recipes-service";
import {DateTime} from "luxon";

export default defineComponent({
  name: "recipe-list",
  components: {RecipeCard},
  data() {
    return {
      favoritesOnly: false,
      currentRecipe: null,
      recipes: [],
      filteredRecipes: [],
      searchText: '',
      searchActive: false,
      fields: [
        {key: 'record'},
      ],
      showRecipeCard: false,
      showRecipeList: true,
    }
  },
  created() {
    this.currentRecipe = null;
    this.refresh();
  },
  watch: {
    favoritesOnly() {
      this.filterFavorites();
    }
  },
  methods: {
    refresh() {
      this.currentRecipe = null;
      fetchMakerRecipes(this.$store.getters.makerId, true, this.searchText.trim()).then((response) => {
        this.recipes = response.data.results;
        this.filterFavorites();
      });
    },
    filterFavorites() {
      this.$refs.recipeTable.clearSelected();
      this.filteredRecipes = this.recipes.filter(recipe => {
        return (!this.favoritesOnly || recipe.isFavorite);
      });
    },
    clearSearch() {
      this.searchText = '';
      this.refresh();
    },
    searchList() {
      this.ctmhDebounce(this.refresh, 500);
    },
    onRowSelected(items) {
      if (items.length > 0) {
        fetchMakerRecipe(items[0].id).then(response => {
          this.currentRecipe = response.data;
          this.showRecipeList = false;
          this.showRecipeCard = true;
        });
      } else {
        this.currentRecipe = null;
      }
    },
    formatRecipeDate(recipe) {
      return DateTime.fromISO(recipe.created).toLocaleString();
    },
    toggleFavorite(index) {
      this.filteredRecipes[index].isFavorite = !this.filteredRecipes[index].isFavorite;
      if (this.filteredRecipes[index].isFavorite) {
        addFavorite(this.$store.getters.makerId, this.filteredRecipes[index].id);
      } else {
        deleteFavorite(this.$store.getters.makerId, this.filteredRecipes[index].id);
      }
    },
    backToList() {
      this.$refs.recipeTable.clearSelected();
      this.showRecipeList = true;
      this.showRecipeCard = false;
    }
  }
})
</script>

<style scoped lang="scss">

.recipe-hide {
  display: none;
}

// Change up view at bootstrap "large" size
@media (min-width: 992px) {
  .back-navigation {
    display: none !important;
  }

  .recipe-hide {
    display: flex;
  }
}

.community-list-image {
  width: 64px;
  height: 64px;
  object-fit: scale-down;
  -o-object-fit: scale-down
}

.community-list-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>