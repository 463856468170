<template>
  <div class="p-2 rounded-2 bg-white">
    <div class="row mb-2 d-flex align-items-center">
      <div class="col-12 col-lg-3 mb-1 d-flex align-items-center">
        <b-input-group>
          <b-input id="searchBar" type="text" placeholder="Search..."
                   v-model="searchText" v-on:input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-3 d-inline-flex align-items-center">
        <h6 class="mb-0 mr-2">Per Page</h6>
        <b-form-select v-model="perPage" style="width: 65px" @change="updatePerPage"
                       :options="$store.getters.perPageChoices">
        </b-form-select>
      </div>
      <div class="col d-inline-flex align-items-center justify-content-end">
        <ul class="list-inline my-2">
          <li class="list-inline-item">
            <b-btn id="makerProductsView" variant="primary" @click="toggleDisplay">
              <font-awesome-icon role="button" class="align-middle"
                                 :icon="displayCards ? 'list' : 'grip'"/>
              {{ displayCards ? 'Table View' : 'Card View' }}
            </b-btn>
          </li>
          <li class="list-inline-item">
            <b-btn id="makerProductsAdd" variant="primary" v-b-modal.addProducts>
              <font-awesome-icon role="button" class="align-middle"
                                 icon="circle-plus"/>
              Add
            </b-btn>
          </li>
        </ul>
      </div>
    </div>
    <div ref="scroll-container">
      <UserProductList v-if="!displayCards"
                       :user-product-list="listItems"
                       @editItem="editItem"
                       @deleteItem="deleteItem"
                       @deleteMakerImage="deleteMakerImage"/>

      <UserProductCardView v-else
                           :user-product-list="listItems"
                           @editItem="editItem"
                           @deleteItem="deleteItem"
                           @deleteMakerImage="deleteMakerImage"/>

      <div ref="paging" class="paging">
        <b-row class="m-0 page-label">
          <b-col cols="4" lg="4" class="px-0 small">{{ countLabel }}</b-col>
          <b-col cols="8" lg="4">
            <b-pagination v-if="rows > 0"
                          class="mb-0"
                          align="center" size="md"
                          :total-rows="rows"
                          v-model="currentPage"
                          :per-page="perPage"
                          @page-click="pageClick"
                          aria-controls="userProductList"
                          :disabled="searchActive">
            </b-pagination>
          </b-col>
          <b-col cols="4"></b-col>
        </b-row>
      </div>
    </div>
    <EditProduct :item="currentItem" :act-as-admin="false" @editComplete="onEditDone"
                 :is-custom="currentItem && currentItem.product ? currentItem.product.isCustom : false"/>

    <ConfirmDelete :item="currentItem" @deleteMakerProduct="deleteMakerProductConfirmed"/>

    <AddProducts @addComplete="onAddDone"/>

    <!-- Confirm Image Delete -->
    <b-modal v-model="confirmImageDelete" id="confirmImageDelete" centered title="Confirm Image Delete"
             no-close-on-backdrop
             ok-title="Yes"
             cancel-title="No" @ok="deleteConfirmed">
      <div class="text-center">
        <p>Are you sure you want to delete the image for</p>
        <p>{{ currentItem ? currentItem.product.name : '' }} ({{
            currentItem ? currentItem.product.item_number : ''
          }})</p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {
  deleteMakerProduct,
  deleteMakerProductImage,
  searchMakerProductList,
} from "../product-services";
import EditProduct from "@/pages/products/user-products/user-products-edit.vue";
import AddProducts from "@/pages/products/user-products/user-products-add.vue";
import UserProductList from "./user-products-comp-listView.vue"
import UserProductCardView from "./user-products-comp-cardView.vue";
import ConfirmDelete from "@/pages/products/user-products/user-product-confirmDelete.vue";
import {updateMakerPagingPreferences} from "@/pages/makers/maker-services";

export default {
  name: "userProducts",
  components: {
    ConfirmDelete,
    UserProductCardView,
    EditProduct,
    AddProducts,
    UserProductList
  },
  data() {
    return {
      userProductList: [],
      searchText: '',
      searchActive: false,
      currentPage: 1,
      perPage: this.$store.getters.perPage,
      totalCount: 0,
      currentItem: null,
      confirmImageDelete: false,
      displayCards: false,
      countLabel: '',
      scrollMax: 0,
      paddingHeight: 0
    };
  },
  computed: {
    listItems() {
      if (this.searchText.length > 0 && this.useLocal) {
        const searchText = this.searchText.toLowerCase();
        return this.userProductList.filter(i => ((i.product.name.toLowerCase().indexOf(searchText) >= 0) ||
            (i.product.item_number.toLowerCase().indexOf(searchText) >= 0) ||
            (i.location && i.location.name.toLowerCase().indexOf(searchText) >= 0) ||
            (i.tags && i.tags.some(t => t.tag.toLowerCase().indexOf(searchText) >= 0)) ||
            (i.product.ideabook && i.product.ideabook.name.toLowerCase().indexOf(searchText) >= 0)));
      } else {
        return this.userProductList;
      }
    },
    rows() {
      return this.totalCount;
    }
  },
  created() {
    if (this.$route.query.subscription) {
      // Show welcome dialog
    } else if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }
    this.getProducts(this.currentPage);
    this.displayCards = this.$store.getters.productView === 1;
    window.addEventListener('scroll', this.captureWindowPosition);
    window.addEventListener('resize', this.setPagingSizes);
  },
  destroyed() {
    window.removeEventListener('scroll', this.captureWindowPosition);
    window.removeEventListener('resize', this.setPagingSizes);
  },
  updated: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.setPagingSizes();
    });
  },
  methods: {
    captureWindowPosition() {
      if (window.scrollY < this.scrollMax) {
        this.$refs.paging.classList.add("sticky");
        this.$refs["scroll-container"].setAttribute('style', `padding-bottom: ${this.paddingHeight}px !important;`);
      } else {
        this.$refs.paging.classList.remove("sticky");
        this.$refs["scroll-container"].removeAttribute('style');
      }
    },
    setPagingSizes() {
      // Remove the extra padding before calculating the new dimensions
      this.$refs["scroll-container"].removeAttribute('style');
      this.$refs.paging.classList.remove("sticky");

      // Since we manually updated the DOM, need to ensure that the re-render has completed before performing the
      // calculations. That is done with the requestAnimationFrame + setTimeout
      requestAnimationFrame(() => {
        setTimeout(() => {
          const footer = document.getElementsByTagName('footer')[0]
          this.paddingHeight = (footer.offsetHeight + this.$refs.paging.clientHeight + 15);
          this.scrollMax = document.documentElement.scrollHeight - window.innerHeight - this.paddingHeight + this.$refs.paging.clientHeight;
          // Reset the stickiness if needed
          this.captureWindowPosition();
        });
      });
    },
    getProducts(pageNum, search = null) {
      this.searchActive = true;
      // Perform the search
      searchMakerProductList(search, pageNum).then((response) => {
        this.updateRoutePage('dashboard', pageNum);
        this.userProductList = response.data.results;
        this.totalCount = response.data.count;
        this.currentPage = pageNum;
        this.searchActive = false;
        const start = this.perPage * (this.currentPage - 1);
        this.countLabel = this.totalCount > 0 ? `Showing ${start + 1} to ${Math.min(start + this.perPage, this.totalCount)} of ${this.totalCount} entries` : '';
      }).catch((err) => {
        this.searchActive = false;
        if (err.response && err.response.status === 404) {
          this.pageClick(null, 1);
        }
      });
    },
    doSearch() {
      this.getProducts(1, this.searchText);
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    pageClick(event, pageNum) {
      // Perform the fetch
      this.getProducts(pageNum, this.searchText);
    },
    onEditDone() {
      this.currentItem = null;
      this.getProducts(this.currentPage, this.searchText);
    },
    onAddDone() {
      this.searchText = '';
      this.getProducts(1);
    },
    editItem(item) {
      this.currentItem = {...item};
      this.$bvModal.show("editProduct");
    },
    deleteItem(item) {
      this.currentItem = item;
      this.$bvModal.show("deleteMakerProduct");
    },
    deleteMakerImage(item) {
      this.currentItem = item;
      this.confirmImageDelete = true;
    },
    deleteConfirmed() {
      deleteMakerProductImage(this.currentItem.id).catch(() => {
      });
      this.currentItem.image = null;
      const curItem = this.userProductList.find((p) => p.id === this.currentItem.id);
      if (curItem) {
        Object.assign(curItem, this.currentItem);
      }
      this.currentItem = null;
    },
    deleteMakerProductConfirmed(item) {
      deleteMakerProduct(item).then(() => {
        this.getProducts(this.currentPage, this.searchText);
        this.$bvModal.hide("deleteMakerProduct");
      }).catch(() => {
        this.$bvModal.hide("deleteMakerProduct");
      });
    },
    updatePerPage() {
      updateMakerPagingPreferences(this.perPage, this.displayCards ? 1 : 0).then(() => this.getProducts(1));
    },
    toggleDisplay() {
      this.displayCards = (this.$store.getters.productView === 0);
      updateMakerPagingPreferences(this.perPage, this.displayCards ? 1 : 0);
    }
  }
}
</script>

<style lang="scss">

.paging {
  background-color: rgba(255, 255, 255, .75);
}

.sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .page-label {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

</style>