// noinspection CheckImageSize

import introJs from "intro.js";
import store from '@/store';
import router from '@/router';
import {updateMaker} from "@/pages/makers/maker-services";

let _displayCards = false;

const config = {
    tutor: introJs(),
    lastStep: 0,
    tutorial: {
        showBullets: false,
        tooltipClass: 'onboarding',
        disableInteraction: true,
        exitOnOverlayClick: false,
        overlayOpacity: 0.25,
        steps: [
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                intro: '<span class="d-flex justify-content-center">Hey there! May we show you around?</span>',
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_products',
                intro: 'The <em>Products</em> page is where you will see and manage your products after logging in. It will be blank until you add products to your inventory.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#makerProductsAdd',
                intro: 'Click this button to add new products to your inventory list.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#makerProductsView',
                intro: 'Click this button to toggle how you view your products.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_locations',
                intro: 'The <em>Locations</em> page is where you can manage the list of places you store your inventory. It can be a labeled box, a drawer, a cabinet, anywhere you will store product.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_tags',
                intro: 'The <em>Tags</em> page is where you manage your tags. Use tags to group your products by theme, saying, season, or any other short phrase to help you search your products.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_recipes',
                intro: "The <em>Recipes</em> page is where you can save your project's instructions and/or share them with the My Stamping Stuff community."
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_community',
                intro: "The <em>Community</em> page is where all the wonderful recipes are shared with the My Stamping Stuff community. See what your fellow crafters have created."
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#community-recipe-0',
                intro: '<div class="d-inline-block">While in Community, click on a recipe to see the full details - images, videos, instructions, and the product list. Click the&nbsp;<svg data-v-5ba4d096="" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-heart"><path data-v-5ba4d096="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path></svg>&nbsp;to add it to your favorites.</div>'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_support',
                intro: 'The <em>Support</em> page is filled with useful information on how to best use this site.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                element: '#menu_profile',
                intro: 'You can import information from Evernote using the <strong><em>Import</em></strong> option under the <em>Profile</em> menu.'
            },
            {
                title: '<img src="assets/img/logo.png" alt="" width="32px"/><span class="ml-2">My Stamping Stuff</span>',
                intro: 'Thanks for looking around with us. We hope you enjoy My Stamping Stuff!'
            }
        ]
    }
};

function prepForProducts(toggleView = false) {
    // Make sure we are on the products tab
    const productMenu = document.getElementById('menu_products');
    productMenu.children.item(0).click();

    // Need to refresh after page transition - hopefully happens within 10ms.
    setTimeout(() => config.tutor.refresh(true), 10);

    if (toggleView) {
        setTimeout(() => {
            const el = document.getElementById('makerProductsView');
            if (el) {
                el.click();
            }
        }, 1500);
    }
}

function prepForLocations(showModal = false) {
    // Make sure we are on the locations tab
    const locationMenu = document.getElementById('menu_locations');
    locationMenu.children.item(0).click();

    // Need to refresh after page transition - hopefully happens within 10ms.
    setTimeout(() => config.tutor.refresh(true), 10);

    if (showModal) {
        setTimeout(() => {
            const el = document.getElementById('addUserLocation');
            if (el) {
                el.click();
            }
        }, 0);
    }
}

function prepForTags(showModal = false) {
    // Make sure we are on the tags tab
    const tagMenu = document.getElementById('menu_tags');
    tagMenu.children.item(0).click();

    // Need to refresh after page transition - hopefully happens within 10ms.
    setTimeout(() => config.tutor.refresh(true), 10);

    if (showModal) {
        setTimeout(() => {
            const el = document.getElementById('addUserTag');
            if (el) {
                el.click();
            }
        }, 0);
    }
}

function prepForRecipes(showModal = false) {
    // Make sure we are on the recipes tab
    const recipesMenu = document.getElementById('menu_recipes');
    recipesMenu.children.item(0).click();

    // Need to refresh after page transition - hopefully happens within 10ms.
    setTimeout(() => config.tutor.refresh(true), 10);

    if (showModal) {
        setTimeout(() => {
            const el = document.getElementById('addUserRecipe');
            if (el) {
                el.click();
            }
        }, 0);
    }
}

function prepForCommunity(showRecipe = false) {
    // Make sure we are on the community tab
    const communityMenu = document.getElementById('menu_community');
    communityMenu.children.item(0).click();

    // Need to refresh after page transition - hopefully happens within 50ms.
    setTimeout(() => config.tutor.refresh(true), 500);

    if (showRecipe) {
        setTimeout(() => {
            const el = document.getElementById('community-recipe-0');
            if (el) {
                el.click();
            }
        }, 1750);
    }
}

function prepForSupport() {
    // Make sure we are on the support tab
    const route = router.currentRoute;
    if (route.name !== 'support') {
        const supportMenu = document.getElementById('menu_support');
        supportMenu.children.item(0).click();
    }

    // Need to refresh after page transition - hopefully happens within 10ms.
    setTimeout(() => config.tutor.refresh(true), 10);
}

function beforeChange() {
    const currentStep = config.tutor.currentStep();
    console.log(`${currentStep}`)
    if (currentStep >= 1 && currentStep <= 3) {
        prepForProducts(currentStep === 3);
    } else if (currentStep >= 4 && currentStep <= 4) {
        prepForLocations(false);
    } else if (currentStep >= 5 && currentStep <= 5) {
        prepForTags(false);
    } else if (currentStep >= 6 && currentStep <= 6) {
        prepForRecipes(false);
    } else if (currentStep >= 7 && currentStep <= 8) {
        prepForCommunity(currentStep === 8);
    } else if (currentStep === 9) {
        prepForSupport();
    }

    config.lastStep = currentStep;
}

function beforeExit() {

    updateMaker({id: store.getters.makerId, preferences: JSON.stringify({seenFirstTimeTutorial: true})})
        .then((response) => {
            console.log(response)
        })
        .catch(err => console.log(err));

    const productMenu = document.getElementById('menu_products');
    productMenu.children.item(0).click();

    setTimeout(() => {
        const el = document.getElementById('makerProductsView');
        if (el) {
            const displayView = el.innerText.trim();
            if (displayView !== _displayCards) {
                el.click();
            }
        }
    }, 0);
}

export async function showWelcomeTutorial() {
    _displayCards = store.getters.productView === 1 ? "Table View" : "Card View";

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw < 992) {
        config.tutorial.steps[1].element = '.navbar-toggler.collapsed';
        config.tutorial.steps[4].element = '.navbar-toggler.collapsed';
        config.tutorial.steps[5].element = '.navbar-toggler.collapsed';
        config.tutorial.steps[6].element = '.navbar-toggler.collapsed';
        config.tutorial.steps[7].element = '.navbar-toggler.collapsed';
        config.tutorial.steps[9].element = '.navbar-toggler.collapsed';
    } else {
        config.tutorial.steps[1].element = '#menu_products';
        config.tutorial.steps[4].element = '#menu_locations';
        config.tutorial.steps[5].element = '#menu_tags';
        config.tutorial.steps[6].element = '#menu_recipes';
        config.tutorial.steps[7].element = '#menu_community';
        config.tutorial.steps[9].element = '#menu_support';
    }

    config.tutor.onbeforechange(beforeChange);
    // eslint-disable-next-line no-unused-vars
    config.tutor.onbeforeexit(beforeExit);
    await config.tutor.setOptions(config.tutorial).start();
}