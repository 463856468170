import {API_REQUEST} from "@/utils/actionConstants";
import store from "@/store";

export function getFaqs() {
    const request = {
        type: API_REQUEST,
        url: '/support',
        method: 'get'
    }
    return store.dispatch(API_REQUEST, request);
}
