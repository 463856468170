<template>
  <b-modal :id="modalId" centered :title="title" :ok-title="getOkAction()"
           :ok-disabled="!validForSave" no-close-on-backdrop
           @ok="save" @show="initModal" @hidden="resetModal">
    <div class="product-picture-wrapper text-center d-flex align-items-center">
      <b-img v-if="hasImage(localItem)" class="product-list-thumb" :style="imageStyle" :src="displayUrl"
             :alt="`${localItem.product.item_number} image`"/>
      <div v-else class="d-flex align-items-center justify-content-center no-image w-100 h-100">
        <span>No Image</span>
      </div>
      <div>
        <input type="file" class="file-input" @change="imageFileChanged($event.target.files)"/>
        <div class="change-photo">
          <font-awesome-icon v-if="showPhotoControls" icon="undo" class="rotate-icon mt-1 float-left"
                             @click="rotateLeft($event)"></font-awesome-icon>
          <font-awesome-icon icon="camera"></font-awesome-icon>&nbsp;{{ photoAction }}
          <font-awesome-icon v-if="showPhotoControls" icon="redo" class="rotate-icon mt-1 float-right"
                             @click="rotateRight($event)"></font-awesome-icon>
        </div>
      </div>
    </div>
    <div class="mx-3">
      <div class="row mt-3">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Item #:</span>
        </b-input-group>
        <b-input v-if="isCustom" class="col" id="item_number" placeholder="enter item number" maxlength="20"
                 :value="`${localItem.product.item_number}`" v-model="localItem.product.item_number"/>
        <span v-else class="col">{{ localItem.product.item_number }}</span>
      </div>

      <div class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Item Name:</span>
        </b-input-group>
        <b-input v-if="isCustom" class="col" id="name" placeholder="enter item name" maxlength="128"
                 :value="`${localItem.product.name}`" v-model="localItem.product.name"/>
        <span v-else class="col">{{ localItem.product.name }}</span>
      </div>

      <div v-if="!isCustom" class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Idea Book:</span>
        </b-input-group>
        <span class="col">{{ localItem.product.ideabook ? localItem.product.ideabook.name : '' }}</span>
      </div>
    </div>

    <div class="w-100 my-4" style="height: 1px; background-color: rgba(0,0,0,.2);"/>
    <div class="mx-3">

      <div class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Location:</span>
        </b-input-group>
        <b-input-group class="col-9 p-0">
          <div class="w-100">
            <v-select
                id="editProductLocation"
                :multiple="false"
                :clearable="true"
                :taggable="true"
                :options="makerLocations"
                :appendToBody="false"
                label="name"
                :closeOnSelect="true"
                placeholder="Search for or enter new location"
                v-model="localItem.location"
                :createOption="addNewLocation"
                :calculatePosition="calculatePosition"
                @input="locationUpdated"
            />
          </div>
        </b-input-group>
      </div>

      <div class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Tags:</span>
        </b-input-group>
        <b-input-group class="col-9 p-0">
          <div class="w-100">
            <v-select
                id="editProductTags"
                :multiple="true"
                :clearable="true"
                :taggable="true"
                :options="makerTags"
                :appendToBody="false"
                label="tag"
                :closeOnSelect="false"
                placeholder="Search for or enter new tags"
                v-model="localItem.tags"
                :createOption="addNewTag"
                :calculatePosition="calculatePosition"
                @option:selected="onSelectedTag"
                @option:deselected="onDeSelectedTag"
            >
              <template #search="{ attributes, events }">
                <input
                    maxlength="30"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
            </v-select>
          </div>
        </b-input-group>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {fetchMakerLocations, fetchMakerTags} from "@/utils/actions";
import {
  getMakerProductByItem,
  getProductByItem,
  saveMakerProduct,
  uploadMakerProductImage
} from "@/pages/products/product-services";
import {API_SET_API_ERROR} from "@/utils/actionConstants";

export default {
  name: "editProduct",
  props: {
    'modalId': {
      type: String,
      default: 'editProduct'
    },
    'item': {
      type: Object,
      required: false,
    },
    'isCustom': {
      type: Boolean,
      required: false,
      default: false
    },
    'customTitle': {
      type: String,
      required: false,
      default: null
    },
    'value': {
      type: Object,
      required: false,
      default: null
    },
    'fromAdd': {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      localItem: this.item ? {...this.item} : this.newItem(),
      file: null,
      imageUrl: null,
      rotation: 0,
      saveIsValid: false,
      makerTags: [],
      makerLocations: [],
      tags: [],
      locations: [],
    }
  },
  watch: {
    item(newItem) {
      if (!newItem || Object.keys(newItem).length === 0) {
        this.localItem = this.newItem();
      } else {
        this.localItem = {...newItem}
        if (newItem.imageData) {
          this.localItem.imageData = newItem.imageData;
          this.file = newItem.imageData.file;
          this.imageUrl = newItem.imageData.imageUrl;
          this.rotation = newItem.imageData.rotation;
        }
      }
      if (this.localItem.location) {
        this.makerLocations = this.reduceList(this.locations, [this.localItem.location], 'name');
      }
      this.makerTags = this.reduceList(this.tags, this.localItem.tags, 'tag');
    }
  },
  computed: {
    title() {
      if (this.localItem.product) {
        return this.customTitle || `Edit Product ${this.localItem.product.item_number}`;
      } else {
        return '';
      }
    },
    validForSave() {
      return this.localItem.product.item_number && this.localItem.product.item_number.length > 0 && this.localItem.product.name && this.localItem.product.name.length > 0;
    },
    imageStyle() {
      return {cursor: 'pointer', transform: `rotate(${-this.rotation}deg)`};
    },
    displayUrl() {
      if (this.imageUrl) {
        return this.imageUrl;
      }
      return this.getImageUrl(this.localItem);
    },
    photoAction() {
      return (this.localItem.image || this.imageUrl) ? 'Change' : (!this.localItem.product || !this.localItem.product.image) ? 'Add' : 'Replace';
    },
    showPhotoControls() {
      return !!((this.item && this.item.image) || this.imageUrl);
    }
  },
  methods: {
    processComplete(success=true) {
      if (success) {
        const item = {...this.localItem};
        this.$nextTick(() => this.$bvModal.hide(this.modalId));
        this.$emit('editComplete', item);
      }
    },
    async save(event) {
      event.preventDefault();

      if (this.fromAdd) {
        if (this.localItem.product.isCustom && (!this.localItem.id || this.localItem.id <= 0)) {
          // Make sure user is not duplicating an item already in their list.
          try {
            const response = await getMakerProductByItem(this.localItem.product.item_number);
            if (response.status === 200) {
              // Product already exists!
              console.log('MakerProduct already exists!');
              this.$store.commit(API_SET_API_ERROR, {
                status: 'Duplicate Item',
                statusText: `Item # ${this.localItem.product.item_number} already exists in your product list. It cannot be added as a custom product.`
              });
              return;
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status !== 404) {
                this.$store.commit(API_SET_API_ERROR, {
                  status: error.response.status,
                  statusText: error.response.statusText
                });
                return;
              }
            } else {
              this.$store.commit(API_SET_API_ERROR, {
                status: 500,
                statusText: error.message
              });
              return;
            }
            console.log('makerProduct err', error);
          }

          // Make sure user is not duplicating an item already in their list.
          try {
            const response = await getProductByItem(this.localItem.product.item_number);
            if (response.status === 200) {
              // Product already exists!
              console.log('Product already exists!');
              this.$store.commit(API_SET_API_ERROR, {
                status: 'Duplicate Item',
                statusText: `Item # ${this.localItem.product.item_number} already exists in the MYSS master list. It cannot be added as a custom product.`
              });
              return;
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status !== 404) {
                this.$store.commit(API_SET_API_ERROR, {
                  status: error.response.status,
                  statusText: error.response.statusText
                });
                return;
              }
            } else {
              this.$store.commit(API_SET_API_ERROR, {
                status: 500,
                statusText: error.message
              });
              return;
            }
            console.log('product err', error);
          }
        }

        this.processComplete(true);
        return;
      }

      const itemEdited = this.localItem;
      const imageData = itemEdited.imageData;
      delete itemEdited.imageData;

      saveMakerProduct(itemEdited).then((response) => {
        const newItem = {...response.data};
        if (imageData) {
          const formData = new FormData();

          // append the files to FormData
          formData.append('rotation', imageData.rotation);

          if (imageData.file) {
            formData.append('file', imageData.file, imageData.file.name);
          }
          uploadMakerProductImage(newItem.id, formData).then(() => {
            this.processComplete();
          }).catch(() => {
            this.processComplete(false);
          });
        } else {
          this.processComplete();
        }
      }).catch(() => {
        this.processComplete(false);
      });
    },
    initModal() {
      if (this.value === null || !this.value.locations) {
        fetchMakerLocations().then((response) => {
          this.locations = response.data.results;
          if (this.localItem.location) {
            this.makerLocations = this.reduceList(this.locations, [this.localItem.location], 'name');
          } else {
            this.makerLocations = this.locations;
          }
        });
      } else {
        this.locations = this.value.locations;
        this.makerLocations = this.locations;
      }

      if (this.value === null || !this.value.tags) {
        fetchMakerTags().then((response) => {
          this.tags = response.data;
          this.makerTags = this.reduceList(this.tags, this.localItem.tags, 'tag');
        });
      } else {
        this.tags = this.value.tags;
        this.makerTags = this.tags;
      }
    },
    resetModal() {
      // Only need to update the v-model when the dialog usage is completed
      if (this.value != null) {
        this.$emit('input', {tags: this.tags, locations: this.locations});
      }
      this.file = null;
      this.imageUrl = null;
      this.rotation = 0;
      this.tags = [];
      this.locations = [];
      this.localItem = this.newItem();
    },
    newItem() {
      return {
        image: null,
        product: {id: null, name: null, item_number: null, image: null},
        location: null,
        tags: [],
        id: null
      };
    },
    getOkAction() {
      if (this.localItem.id > 0) {
        return 'Update'
      } else if (this.localItem && this.localItem.product && this.localItem.product.isCustom) {
        return 'Add';
      } else {
        return 'Save';
      }
    },
    calculatePosition(dropdownList, component, {width, top, left}) {
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
      dropdownList.style.zIndex = 1500;
    },
    locationUpdated(updatedLoc) {
      const selectedList = updatedLoc ? [updatedLoc] : [];
      this.makerLocations = this.reduceList(this.locations, selectedList, 'name');
    },
    onSelectedTag() {
      this.makerTags = this.reduceList(this.tags, this.localItem.tags, 'tag');
    },
    onDeSelectedTag() {
      this.makerTags = this.reduceList(this.tags, this.localItem.tags, 'tag');
    },
    addNewTag(newTag) {
      const existingTag = this.tags.find(obj => {
        return obj.tag.toLowerCase() === newTag.toLowerCase();
      });
      return existingTag || {
        id: this.uniqueItemId(),
        tag: newTag
      };
    },
    addNewLocation(newLocation) {
      const existingLocation = this.locations.find(obj => {
        return obj.name.toLowerCase() === newLocation.toLowerCase();
      });
      return existingLocation || {
        id: this.uniqueItemId(),
        name: this.capitalize(newLocation),
      };
    },
    setRotation(rotation) {
      if (this.localItem.imageData) {
        this.localItem.imageData.rotation = rotation;
      } else {
        this.localItem.imageData = {
          rotation: rotation
        };
      }
      this.rotation = rotation;
    },
    rotateLeft(event) {
      event.preventDefault();
      this.setRotation((this.rotation + 90) % 360);
    },
    rotateRight(event) {
      event.preventDefault();
      this.setRotation((this.rotation - 90) % 360);
    },
    imageFileChanged(fileList) {
      // handle file changes
      this.rotation = 0;
      if (fileList.length > 0) {
        this.imageUrl = URL.createObjectURL(fileList[0]);
        this.file = fileList[0];
        this.localItem.imageData = {
          imageUrl: this.imageUrl,
          file: this.file,
          rotation: this.rotation
        };
      } else {
        delete this.localItem.imageData;
        this.imageUrl = null;
        this.file = null;
      }
    },
  }
}
</script>

<style scoped lang="scss">

.product-picture-wrapper {
  position: relative;
  overflow: hidden;
  width: 192px;
  min-height: 192px !important;
  margin: auto;
  border: rgba(204, 204, 204, 0.6) solid 1px;
  border-radius: 0 0 5px 5px;

  .product-list-thumb {
    width: 192px;
    height: auto;
    border-radius: 0 0 5px 5px;
    font-size: 10pt;
  }

  .no-image {
    height: 192px !important;
    background-color: rgba(204, 204, 204, 0.6);
  }

  .change-photo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 5px 7px;
    background-color: rgba(204, 204, 204, 0.6);
    font-family: "Poppins", sans-serif;
    border-radius: 0 0 5px 5px;
    pointer-events: none;

    .rotate-icon {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
