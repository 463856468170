import store from "@/store";
import {API_LOGIN, API_REQUEST} from "@/utils/actionConstants";

export function login(email, password) {
    const request = {
        payload: {email, password},
    };
    return store.dispatch(API_LOGIN, request);
}

export function forgotPassword(email) {
    const request = {
        url: '/forgot-password/',
        method: 'post',
        payload: {email},
        noAuth: true,
        noAlert: true,
    };
    return store.dispatch(API_REQUEST, request);
}

export function resetPassword(password, _password, token) {
    const request = {
        url: '/reset-password/',
        method: 'post',
        payload: {password, _password, token},
        noAuth: true,
        noAlert: true,
    };
    return store.dispatch(API_REQUEST, request);
}

export function register(maker) {
    const request = {
        url: '/register/',
        method: 'post',
        payload: maker,
        noAuth: true,
        noAlert: true,
    };
    return store.dispatch(API_REQUEST, request);
}
