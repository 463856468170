<script>
import content from '@/assets/json/terms_of_use.json';
import LegalDocument from "@/pages/app/legalDocument.vue";

export default {
  name: "termsOfUse",
  components: {LegalDocument},
  data() {
    return {
      content: content
    }
  }
}
</script>

<template>
  <legal-document :content="content.content"/>
</template>

<style scoped lang="scss">

</style>