import Router from 'vue-router'
import userProducts from '@/pages/products/user-products/user-products-page.vue'
import store from '@/store'
import ctmhProductList from '@/pages/products/global-products/ctmhProductList.vue'
import {fetchMaker} from '@/pages/makers/maker-services'
import userLocations from '@/pages/locations/userLocations'
import Simple from '@/layouts/simpleLayout.vue'
import Main from '@/layouts/main'
import pageNotFound from '@/pages/app/pageNotFound'
import makerManagement from '@/pages/makers/makerManagement.vue'
import updatePassword from '@/pages/auth/auth-comp-updatePassword'
import printLocation from '@/pages/locations/printLocation'
import userTags from '@/pages/tags/userTags'
import printTag from '@/pages/tags/printTag'

import authPage from "@/pages/auth/auth-page.vue";
import recipeList from "@/pages/recipes/recipe-list.vue";
import communityList from "@/pages/recipes/community/community-list.vue";
import profileEdit from "@/pages/makers/profile-edit.vue";
import supportPage from "@/pages/support/support-page.vue";
import importEvernote from "@/pages/products/product-import/import-evernote.vue";
import privacyPolicy from "@/pages/app/privacyPolicy.vue";
import termsOfUse from "@/pages/app/termsOfUse.vue";
import helpDoc from "@/pages/support/help-doc.vue";

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'root',
            component: Simple,
            redirect: '/auth',
            children: [
                {
                    path: '/auth',
                    name: 'auth',
                    component: authPage,
                    meta: {
                        title: 'Login'
                    },
                },
                {
                    path: '/reset-password',
                    name: 'reset-password',
                    component: updatePassword,
                    meta: {
                        title: 'Reset Password'
                    }
                },
                {
                    path: '/privacy',
                    name: 'privacy',
                    component: privacyPolicy,
                    meta: {
                        title: 'Privacy Policy'
                    }
                },
                {
                    path: '/terms-of-use',
                    name: 'terms-of-use',
                    component: termsOfUse,
                    meta: {
                        title: 'Terms Of Use'
                    }
                },
            ]
        },
        {
            path: '/',
            name: 'Main',
            component: Main,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: userProducts,
                    meta: {
                        title: 'My Products',
                    },
                },
                {
                    path: '/locations',
                    name: 'locations',
                    component: userLocations,
                    meta: {
                        title: 'Locations',
                    },
                },
                {
                    path: '/tags',
                    name: 'tags',
                    component: userTags,
                    meta: {
                        title: 'Tags',
                    },
                },
                {
                    path: '/recipes',
                    name: 'recipes',
                    component: recipeList,
                    meta: {
                        title: 'Maker Recipes'
                    }
                },
                {
                    path: '/community',
                    name: 'community',
                    component: communityList,
                    meta: {
                        title: 'Maker Recipes'
                    }
                },
                {
                    path: '/maker-management',
                    name: 'maker-management',
                    component: makerManagement,
                    meta: {
                        title: 'Maker Management',
                        requiresAdmin: true,
                    },
                },
                {
                    path: '/productMgmt',
                    name: 'product-mgmt',
                    component: ctmhProductList,
                    meta: {
                        title: 'Master Products',
                        requiresAdmin: true,
                    },

                },
                {
                    path: '/print_location',
                    name: 'print_location',
                    component: printLocation,
                    meta: {
                        title: 'Print Locations',
                    },
                },
                {
                    path: '/print_tag',
                    name: 'print_tag',
                    component: printTag,
                    meta: {
                        title: 'Print Tag',
                    },
                },
                {
                    path: '/import/evernote',
                    name: 'importEvernote',
                    component: importEvernote,
                    meta: {
                        title: 'Import Evernote'
                    }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: profileEdit,
                    meta: {
                        title: 'Edit Profile'
                    }
                },
                {
                    path: '/support',
                    name: 'support',
                    component: supportPage,
                    meta: {
                        title: 'Support'
                    }
                },
                {
                    path: '/support/doc/:fileName',
                    name: 'helpDoc',
                    component: helpDoc,
                    meta: {
                        title: 'Support'
                    }
                },
                {
                    path: "*", component: pageNotFound,
                    meta: {
                        title: 'Not Found',
                    },
                },

            ]
        },
    ]
})

let entryUrl = null

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = 'MySS | ' + nearestWithTitle.meta.title
    } else {
        document.title = 'MySS'
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if (!store.getters.isProfileValid) {
                if (!entryUrl) {
                    entryUrl = to.fullPath
                }
                fetchMaker(store.getters.makerId).then(() => {
                    const redirectUrl = entryUrl
                    entryUrl = null
                    next(redirectUrl)
                }).catch(() => {
                })
            } else if (to.matched.some(record => record.meta.requiresAdmin) && !store.getters.isAdmin) {
                next('/unauthorized')
            } else {
                if (entryUrl) {
                    let redirectUrl = entryUrl
                    entryUrl = null
                    next(redirectUrl)
                } else {
                    next()
                }
            }
        }
        else if (to.name !== 'auth' && to.name !== 'root') {
            // If the route is valid, remember it for later
            if (to.name) {
                entryUrl = to.fullPath
            }
            next('/auth')
        }
    } else if (to.name === 'auth' && store.getters.isLoggedIn) {
        next('/dashboard')
    } else {
        if (store.state.debug) {
            console.log('Going to non-auth page', to)
        }
        next()
    }
})

export default router
