<template>
  <b-modal id="editLocation" :title="`${ title } Location`" ok-title="Save" no-close-on-backdrop @ok="saveItem" @hidden="$emit('editLocationClosed')">
    <div class="text-center">
      <b-input class="edit-control" id="location_name" placeholder="location name" maxlength="50"
               :value="`${localItem.name}`" v-model="localItem.name"/>
    </div>
  </b-modal>
</template>

<script>

import {saveLocation} from "@/utils/actions";

export default {
  name: "editLocation",
  props: {
    'location': Object,
  },
  data() {
    return {
      localItem: {},
      title: 'Add',
    }
  },
  watch: {
    location: function (newLocation) {
      this.localItem = newLocation ? {...newLocation} : {};
      this.title = this.localItem.id ? "Edit" : "Add";
    }
  },
  methods: {
    saveItem() {
      this.localItem.maker_id = this.$store.getters.profile.id;
      saveLocation(this.localItem).then(() => {
        this.$bvModal.hide("editLocation");
        this.$emit('locationUpdated');
      }).catch(() => this.$bvModal.hide("editLocation"));
    }
  }
}

</script>

<style scoped>

</style>
