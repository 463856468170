<template>
  <div class="p-2 rounded-2 bg-white">
    <div class="row mb-2 d-flex align-items-center">
      <div class="col-12 col-lg-3 mb-1 d-flex align-items-center">
        <b-input-group>
          <b-input id="searchBar" type="text" placeholder="Search..."
                   v-model="searchText" v-on:input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-3 d-inline-flex align-items-center">
        <h6 class="mb-0 mr-2">Per Page</h6>
        <b-form-select v-model="perPage" style="width: 65px" @change="updatePerPage" :options="$store.getters.perPageChoices">
        </b-form-select>
      </div>
      <div class="col d-inline-flex align-items-center justify-content-end">
        <span class="section-title font-weight-bold mr-3">{{rows}} Makers</span>
        <span v-if="sub_count !== null" class="section-title font-weight-bold mr-3">&nbsp;{{sub_count}}&nbsp;Sub{{sub_count==1 ? '' : 's'}}</span>
      </div>

    </div>

    <div class="table-responsive">
      <b-table :sort-by="sortField.charAt(0) === '-' ? sortField.substring(1): sortField" :sort-desc="sortField.charAt(0) === '-'" no-local-sorting @sort-changed="sortingChanged" primary-key="id" :items="makers" :fields="fields" sort-icon-left>
        <template #cell(hasSubscription)="data">
          <div>
            <font-awesome-icon v-if="data.value" icon="check-circle" class="text-primary" size="lg"></font-awesome-icon>
          </div>
        </template>
        <template #cell(is_active)="data">
          <div role="button" :class="activeClass(data.value)" @click="activate(data.item)">
            <font-awesome-icon icon="check-circle" role="button" size="sm"></font-awesome-icon>
          </div>
        </template>
        <template #cell(delete)="data">
          <div role="button" class="btn btn-danger" @click="deleteMaker(data.item)">
            <font-awesome-icon icon="trash" role="button" size="sm"></font-awesome-icon>
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination v-if="rows > 0"
                  align="center"
                  size="md"
                  :total-rows="rows"
                  :per-page="perPage"
                  v-model="currentPage"
                  @page-click="pageClick"
                  aria-controls="makerList"
                  :disabled="searchActive"
    ></b-pagination>

    <b-modal id="confirmActivateAction" :title="`Confirm ${!currentMaker.is_active ? 'Activation' : 'De-Activation'}`"
             ok-title="Yes" cancel-title="No"
             auto-focus-button="cancel"
             no-close-on-backdrop
             @ok="doActivate(currentMaker.id, !currentMaker.is_active)">
      <div class="text-center">
        <p class="m-auto">
          Are you sure you want to {{ !currentMaker.is_active ? 'activate' : 'de-activate' }}
          <br/>
          {{ currentMaker.first_name }} {{ currentMaker.last_name }}
          <br/>
        </p>
      </div>
    </b-modal>

    <b-modal id="confirmDeleteAction" title="Confirm Maker Delete"
             ok-title="Yes" cancel-title="No"
             auto-focus-button="cancel"
             no-close-on-backdrop
             @ok="doDelete(currentMaker.id)">
      <div class="text-center">
        <p class="m-auto">
          Delete
          {{ currentMaker.first_name }} {{ currentMaker.last_name }}
          from the system?
          <br/>
          <br/>
          All of the current data associated with the account will be removed and un-recoverable.
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {activateMaker, deleteMaker, fetchMakers, getSubscriptionCount, updateMaker} from "./maker-services";
import {DateTime} from 'luxon';

export default {
  name: "makerManagement",
  data() {
    return {
      makers: [],
      fields: [
        {key: 'first_name', sortable: true},
        {key: 'last_name', sortable: true},
        {key: 'date_joined', sortable: true, label: 'Joined', formatter: this.formatDate},
        {key: 'last_login', sortable: true, label: 'Last Login', formatter: this.formatDate},
        {key: 'email', sortable: true},
        {key: 'hasSubscription', label: 'Sub', sortable: true},
        {key: 'productCount', label: 'Products', sortable: true},
        {key: 'is_active', label: 'Active', sortable: true},
        {key: 'delete'},
      ],
      sortField: 'first_name',
      currentMaker: {},
      perPage: this.$store.getters.perPage,
      currentPage: 1,
      searchActive: false,
      searchText: '',
      totalCount: 0,
      sub_count: null
    }
  },
  created() {
    this.getMakers(1, this.sortField);
    getSubscriptionCount().then((response) => {
      this.sub_count = response.data.subscription_count;
    });
  },
  computed: {
    rows() {
      return this.totalCount;
    }
  },
  methods: {
    sortingChanged(ctx) {
      const desc = ctx.sortDesc ? '-' : '';
      this.sortField = `${desc}${ctx.sortBy}`;
      this.getMakers(this.currentPage, this.sortField, this.searchText);
    },
    formatDate(value) {
      if (value) {
        const date = DateTime.fromISO(value);
        return `${date.toLocaleString(DateTime.DATE_SHORT)} ${date.toLocaleString(DateTime.TIME_SIMPLE)}`;
      } else {
        return '--';
      }
    },
    activeClass(value) {
      return `btn btn-${value ? 'primary' : 'secondary'}`;
    },
    activate(maker) {
      this.currentMaker = maker;
      this.$bvModal.show("confirmActivateAction");
    },
    doActivate(maker_id, setActive) {
      activateMaker(maker_id, setActive).then((response) => {
        const makerIdx = this.makers.map((m) => m.id).indexOf(response.data.id);
        if (makerIdx >= 0) {
          Object.assign(this.makers[makerIdx], response.data);
        }
      }).finally(() => this.$bvModal.hide("confirmActivateAction"));
    },
    deleteMaker(maker) {
      this.currentMaker = maker;
      this.$bvModal.show("confirmDeleteAction");
    },
    doDelete(maker_id) {
      deleteMaker(maker_id).then(() => {
        const makerIdx = this.makers.map((m) => m.id).indexOf(maker_id);
        if (makerIdx >= 0) {
          this.makers.splice(makerIdx, 1);
        }
        this.$bvModal.hide("confirmDeleteAction");
      }).catch(() => {
        this.$bvModal.hide("confirmDeleteAction");
      });
    },
    updatePerPage() {
      updateMaker({id: this.$store.getters.makerId, preferences: JSON.stringify({perPage: this.perPage})}).then(() => this.getMakers(1, this.sortField));
    },
    getMakers(pageNum, sorting = null, search = null) {
      // Perform the search
      this.searchActive = true;
      fetchMakers(pageNum, sorting, search).then((response) => {
        this.updateRoutePage('maker-management', pageNum);
        this.makers = response.data.results;
        this.totalCount = response.data.count;
        this.currentPage = pageNum;
        this.searchActive = false;
      }).catch((err) => {
        this.searchActive = false;
        if (err.response && err.response.status === 404) {
          this.pageClick(null, 1);
        }
      });
    },
    doSearch() {
      this.getMakers(1, this.sortField, this.searchText);
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    pageClick(event, pageNum) {
      // Perform the fetch
      this.getMakers(pageNum, this.sortField, this.searchText);
    }
  }
}
</script>

<style scoped>

</style>
