<template>
  <footer class="footer mt-auto w-100 d-inline-flex py-2 px-3 border-top">
    <b-row class="w-100 m-0 p-0">
      <b-col cols="12" lg="4" class="text-center text-lg-left">
        <ul class="footer-links m-0 p-0">
          <li class="nav-item mr-3">
            <a href="mailto:info.mystampingstuff@gmail.com">Email Us</a>
          </li>
          <li class="nav-item mr-3">
            <router-link to="privacy" target="_blank">Privacy</router-link>
          </li>
          <li class="nav-item">
            <router-link to="terms-of-use" target="_blank">Terms of Use</router-link>
          </li>
        </ul>
      </b-col>
      <b-col cols="6" lg="4" class="text-left text-lg-center">{{ mention }}</b-col>
      <b-col cols="6" lg="4" class="text-right">Version: {{ $store.getters.appVersion }}</b-col>
    </b-row>
  </footer>
</template>

<script>
import {COPYRIGHT_NOTICE} from "@/utils/constants";

export default {
  name: "footerView",
  props: {
    'fixed': {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      mention: COPYRIGHT_NOTICE,
    }
  },
}
</script>

<style scoped lang="scss">

.footer {
  background-color: #bfc3ba;
  font-size: smaller;
}

.footer-links li {
  display: inline;
  //padding-left: .5rem;
  //padding-right: .5rem;
}
</style>
