<template>
  <div>
    <b-modal size="xxl" id="addProducts" centered title="Add Products" ok-title="Save"
             no-close-on-backdrop
             no-close-on-esc
             hide-header-close
             :ok-disabled="!validForSave"
             @ok="save" @show="initModal" @hidden="resetModal">

      <b-row>
        <b-col cols="12" md="6">
          <b-input-group id="addProductsSearchWrapper">
            <b-input id="addProductsSearch" type="text" placeholder="Find products by Item Number, Name, or Idea Book..."
                     v-model="searchText" @input="searchList"/>
            <b-tooltip target="addProductsSearch" :show.sync="showTip" triggers="manual" placement="top">
              <span>Enter at least 3 characters to search</span>
            </b-tooltip>
            <template #append>
              <b-input-group-text>
                <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-2 mt-md-0 float-right">
          <b-btn variant="primary" class="float-right" @click="addCustomItem">
            <font-awesome-icon role="button" class="align-middle"
                               icon="circle-plus"/>
            Add Custom Item
          </b-btn>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <hr/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="4">
          <b-row class="justify-content-center">
            <h5>Search Results</h5>
          </b-row>
          <div id="selectProduct_wrapper" class="table-responsive overflow-scroll result-table">
            <b-table
                class="w-100 small"
                style="min-height: calc(60vh); max-height: calc(60vh);"
                small
                fixed
                primary-key="id"
                sticky-header="150px"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :no-border-collapse="true"
                :filter="null"
                :items="listItems"
                :fields="sourceFields">
              <template v-slot:table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: field.width}"
                >
              </template>
              <template #cell(image)="data">
                <div class="position-relative">
                  <div class="d-inline-flex">
                    <div class="position-relative" style="width: 32px; height: 32px">
                      <b-img :id="`ctmh_row_${data.item.id}`" :src="getImageUrl(data.item)" class="product-list-thumb"/>
                      <span v-if="!hasImage(data.item)" class="no-image-text">No Image</span>
                    </div>
                  </div>
                  <b-tooltip v-if="hasImage(data.item)" :target="`ctmh_row_${data.item.id}`" triggers="hover"
                             placement="right"
                             boundary="viewport">
                    <img :src="getImageUrl(data.item)" width="256px" height="256px" style="object-fit: contain"
                         :alt="`${data.item.item_number} image`"/>
                  </b-tooltip>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button :id="`selectProduct_${data.index}`" class="bg-white" size="sm" @click="confirmAdd(data.item)">
                  <font-awesome-icon icon="plus" class="text-primary"></font-awesome-icon>
                </b-button>
              </template>
            </b-table>
            <div v-if="searchActive" class="absolute-outer-center">
              <vue-simple-spinner class="absolute-inner-center"></vue-simple-spinner>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="8">
          <b-row class="justify-content-center">
            <h5>Add to Inventory</h5>
          </b-row>
          <div class="table-responsive overflow-scroll">
            <b-table
                class="w-100 small"
                style="min-height: calc(60vh); max-height: calc(60vh);"
                sort-by="id"
                small
                fixed
                primary-key="id"
                sticky-header="150px"
                :no-border-collapse="true"
                :items="addedItems"
                :fields="resultFields">
              <template v-slot:table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: field.width}"
                >
              </template>
              <template #cell(image)="data">
                <div class="position-relative">
                  <div class="d-inline-flex">
                    <div class="position-relative" style="width: 32px; height: 32px">
                      <b-img :id="`ctmh_row_${data.item.id}`" :style="getImageStyle(data.item)"
                             :src="getImageUrl(data.item)" class="product-list-thumb"/>
                      <span v-if="!hasImage(data.item)" class="no-image-text">No Image</span>
                    </div>
                  </div>
                  <b-tooltip v-if="hasImage(data.item)" :target="`ctmh_row_${data.item.id}`" triggers="hover"
                             placement="right"
                             boundary="viewport">
                    <img :src="getImageUrl(data.item)" :style="getImageStyle(data.item)" width="256px" height="256px"
                         :alt="`${data.item.item_number} image`"/>
                  </b-tooltip>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button class="bg-white mr-2" size="sm" @click="confirmAdd(data.item, true)">
                  <font-awesome-icon icon="edit" class="text-primary"></font-awesome-icon>
                </b-button>
                <b-button class="bg-white" size="sm" @click="removeItem(data.item)">
                  <font-awesome-icon icon="trash" class="text-danger"></font-awesome-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

    </b-modal>

    <!-- Show a dialog that lets the user add a location and tags if desired -->
    <b-modal ref="confirmAddProducts" centered
             :title="`${currentItem.product.item_number}: ${currentItem.product.name}`"
             ok-title="OK"
             no-close-on-backdrop
             @ok="addItem(currentItem)">
      <b-row>
        <div class="col-3 d-inline-flex align-items-center">
          <h5 class="mb-0 pr-2">Location:</h5>
        </div>
        <div class="col d-inline-flex align-items-center">
          <b-input-group class="col p-0">
            <div class="w-100">
              <v-select
                  id="addProductLocation"
                  :multiple="false"
                  :clearable="true"
                  :taggable="true"
                  :options="makerLocations"
                  :appendToBody="false"
                  label="name"
                  :closeOnSelect="true"
                  placeholder="Search for or enter new location"
                  v-model="currentItem.location"
                  :createOption="addNewLocation"
                  :calculatePosition="calculatePosition"
                  @input="locationUpdated"
              />
            </div>
          </b-input-group>
        </div>
      </b-row>
      <b-row>
        <div class="col-3 d-inline-flex align-items-center">
          <h5 class="mb-0 pr-2">Tags:</h5>
        </div>
        <div class="col mt-1 d-inline-flex align-items-center">
          <b-input-group class="col p-0">
            <div class="w-100">
              <v-select
                  id="addProductTags"
                  :multiple="true"
                  :clearable="true"
                  :taggable="true"
                  :options="makerTags"
                  :appendToBody="false"
                  label="tag"
                  :closeOnSelect="false"
                  placeholder="Search for or enter new tags"
                  v-model="currentItem.tags"
                  :createOption="addNewTag"
                  :calculatePosition="calculatePosition"
                  @option:selected="onSelectedTag"
                  @option:deselected="onDeSelectedTag"
              >
                <template #search="{ attributes, events }">
                  <input
                      maxlength="30"
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                  />
                </template>
              </v-select>
            </div>
          </b-input-group>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <div class="mt-1 text-black-50 font-weight-light">Location and Tags are optional. Click OK when done.</div>
        </b-col>
      </b-row>
    </b-modal>

    <EditProduct modal-id="addCustomProduct" :is-custom="true" :item="currentItem"
                 custom-title="Add Custom Item"
                 :from-add="true"
                 v-model="tagLocLists" @editComplete="addItem"/>
  </div>
</template>

<script>

import EditProduct from "@/pages/products/user-products/user-products-edit.vue";
import {fetchMakerLocations, fetchMakerTags} from "@/utils/actions";
import VueSimpleSpinner from 'vue-simple-spinner'
import {productSearchForAdd, saveMakerProduct, uploadMakerProductImage} from "@/pages/products/product-services";

export default {
  name: "addProducts",
  components: {
    EditProduct,
    VueSimpleSpinner
  },
  data() {
    return {
      currentItem: {
        image: null,
        product: {image: null, item_number: null, name: null},
        location: null,
        tags: [],
        id: null
      },
      searchText: '',
      searchActive: false,
      lastSearch: null,
      hasSearched: false,
      showTip: false,
      tagLocLists: {
        locations: [],
        tags: []
      },
      makerTags: [],
      makerLocations: [],
      lastLocation: null,
      filteredItems: [],
      addedItems: [],
      sortBy: 'item_number',
      sortDesc: false,
      sourceFields: [
        {key: "image", label: "", tdClass: 'align-middle p-1', width: '41px', thClass: "text-center", formatter: this.formatImage},
        {key: "item_number", label: 'Item #', width: '70px', thClass: "text-center", tdClass: "text-center align-middle"},
        {key: "name", label: 'Name', width: '120px', thClass: "text-center", tdClass: "align-middle"},
        {key: "actions", label: "", thClass: "list-edit-controls", width: '41px', tdClass: "text-center align-middle"},
      ],
      resultFields: [
        {key: "image", label: "", tdClass: 'align-middle p-1', width: '41px', formatter: this.formatImage},
        {key: "product.item_number", label: 'Item #', tdClass: "align-middle", width: '70px'},
        {key: "product.name", label: 'Name', tdClass: "align-middle", width: '150px'},
        {key: "location.name", label: "Location", tdClass: "align-middle", width: '100px'},
        {key: "tags", formatter: this.formatTagList, tdClass: "align-middle", width: '150px'},
        {key: "actions", label: "", thClass: "list-edit-controls", tdClass: "text-center align-middle", width: '85px'},
      ],
      queueSearchTimer: null,
    }
  },
  methods: {
    initModal() {
      fetchMakerLocations().then((response) => {
        this.tagLocLists.locations = response.data.results;
        this.makerLocations = this.reduceList(this.tagLocLists.locations, [], 'name');
      });
      fetchMakerTags().then((response) => {
        this.tagLocLists.tags = response.data;
        this.makerTags = this.reduceList(this.tagLocLists.tags, [], 'tag');
      });
    },
    doSearch() {
      if (this.searchText.length > 0 && this.searchText.length < 3) {
        this.showTip = true;
      } else {
        this.showTip = false;
        this.getProducts(this.searchText);
      }
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    formatImage(value, key, item) {
      return this.getImageUrl(item);
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    getProducts(search = null) {
      // If there is no search string or there is an existing search, abort this search attempt.
      if (!search || this.searchActive) {
        if (search) {
          clearTimeout(this.queueSearchTimer);
          this.queueSearchTimer = setTimeout(this.doSearch, 750);
        } else {
          clearTimeout(this.queueSearchTimer);
          this.queueSearchTimer = null;
        }
        return;
      }
      this.searchActive = true;

      // Perform the search
      productSearchForAdd(search).then((response) => {
        // The table can take a while to render if the response.data contains 1000's of rows of data which will hang the UI
        this.filteredItems = response.data;
        this.searchActive = false;
      }).catch(() => {
        this.searchActive = false;
      });
    },
    async save(event) {
      event.preventDefault();

      for (let i = 0; i < this.addedItems.length; i++) {
        const imageData = this.addedItems[i].imageData;
        delete this.addedItems[i].imageData;

        try {
          let response = await saveMakerProduct(this.addedItems[i]);
          if (imageData) {
            const formData = new FormData();

            // append the files to FormData
            formData.append('rotation', imageData.rotation);

            if (imageData.file) {
              formData.append('file', imageData.file, imageData.file.name);
            }
            response = await uploadMakerProductImage(response.data.id, formData);
          }
        } catch (e) {
          console.log(e);
        }
      }
      this.$bvModal.hide("addProducts");
      this.$emit("addComplete");
    },
    resetModal() {
      this.addedItems = [];
      this.filteredItems = [];
      this.searchText = '';
      this.lastLocation = null;
    },
    calculatePosition(dropdownList, component, {width, top, left}) {
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
      dropdownList.style.zIndex = 1500;
    },
    locationUpdated(updatedLoc) {
      const selectedList = updatedLoc ? [updatedLoc] : [];
      this.makerLocations = this.reduceList(this.tagLocLists.locations, selectedList, 'name');
    },
    onSelectedTag() {
      this.makerTags = this.reduceList(this.tagLocLists.tags, this.currentItem.tags, 'tag');
    },
    onDeSelectedTag() {
      this.makerTags = this.reduceList(this.tagLocLists.tags, this.currentItem.tags, 'tag');
    },
    addNewTag(newTag) {
      const existingTag = this.tagLocLists.tags.find(obj => {
        return obj.tag.toLowerCase() === newTag.toLowerCase();
      });
      return existingTag || {
        id: this.uniqueItemId(),
        tag: newTag
      };
    },
    addNewLocation(newLocation) {
      const existingLocation = this.tagLocLists.locations.find(obj => {
        return obj.name.toLowerCase() === newLocation.toLowerCase();
      });
      return existingLocation || {
        id: this.uniqueItemId(),
        name: this.capitalize(newLocation),
      };
    },
    addCustomItem() {
      this.currentItem = {
        product: {
          isCustom: true,
          image: null,
          name: null,
          item_number: null
        },
        id: null,
        location: this.lastLocation,
        tags: []
      }
      this.$bvModal.show("addCustomProduct");
    },
    confirmAdd(item, update = false) {
      const itemToConfirm = update ? {...item} : {product: {...item}};
      if (!update) {
        itemToConfirm.tags = [];
        itemToConfirm.location = this.lastLocation;
      }
      this.makerTags = this.tagLocLists.tags;

      this.currentItem = itemToConfirm;
      if (this.currentItem.product.isCustom) {
        this.$bvModal.show("addCustomProduct");
      } else {
        this.$refs.confirmAddProducts.show();
      }
    },
    addItem(item) {
      // If there is no id yet, then assign it a temporary one.
      if (!item.id) {
        item.id = this.uniqueItemId();
      }

      this.lastLocation = item.location;
      this.filteredItems = this.filteredItems.filter((fi) => fi.id !== item.product.id);

      // Was this an edit? Is this item already in the list?
      const idx = this.addedItems.findIndex(i => i.id === item.id);
      if (idx < 0) {
        this.addedItems.push(item);
      } else {
        Object.assign(this.addedItems[idx], item);
      }

      // Update the location list
      if (item.location !== null && this.tagLocLists.locations.findIndex((l) => l.id === item.location.id) < 0) {
        this.tagLocLists.locations.push(item.location);
      }

      this.tagLocLists.locations.sort((l1, l2) => {
        return l1.name.toLowerCase().localeCompare(l2.name.toLowerCase());
      });

      // Update the tag list
      for (let i = 0; i < item.tags.length; i++) {
        if (this.tagLocLists.tags.findIndex((t) => t.id === item.tags[i].id) < 0) {
          this.tagLocLists.tags.push(item.tags[i]);
        }
      }
      this.tagLocLists.tags.sort((t1, t2) => {
        return t1.tag.toLowerCase().localeCompare(t2.tag.toLowerCase());
      });
    },
    removeItem(item) {
      this.addedItems = this.addedItems.filter((fi) => fi.product.id !== item.product.id);
      item.tags = [];
      item.location = null;
      if (!item.product.isCustom) {
        this.filteredItems.push(item.product);
      }
      this.filteredItems.sort((i1, i2) => (this.sortDesc ? -1 : 1) * i1[this.sortBy].toLowerCase().localeCompare(i2[this.sortBy].toLowerCase()));
    },
    getImageStyle(item) {
      if (item.imageData) {
        return {objectFit: 'contain', transform: `rotate(${-item.imageData.rotation}deg)`};
      } else {
        return '';
      }
    },
  },
  computed: {
    validForSave() {
      return this.addedItems.length > 0;
    },
    listItems() {
      return this.filteredItems;
    },
  }
}
</script>

<style scoped lang="scss">

.product-list-thumb {
  border-radius: 3px;
  border: black solid 1px;
  width: 32px;
  height: 32px;
  background-color: #D4E0E6;
}

.no-image-text {
  margin: 0;
  padding: 0;
  font-size: x-small;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absolute-outer-center {
  position: absolute;
  background-color: rgba(0, 0, 0, 5%);
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 16px);
  z-index: 1500;
}

.absolute-inner-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media (max-width: 767.9px) {
  .result-table {
    max-height: 200px;
  }
}

</style>
