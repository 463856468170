import store from "@/store";
import {API_REQUEST, SET_PROFILE} from "@/utils/actionConstants";
import {getSearchParams} from "@/utils/actions";

export function fetchMakers(page, sorting = null, search = null) {
    let params = getSearchParams(search, page)
    if (sorting) {
        params.ordering = sorting;
    }
    const request = {
        url: `/makers/`,
        method: 'get',
        params: params
    };
    return store.dispatch(API_REQUEST, request);
}

export function fetchMaker(maker_id) {
    const request = {
        url: `/makers/${maker_id}/`,
        method: 'get',
        onSuccess: SET_PROFILE,
    };
    return store.dispatch(API_REQUEST, request);
}

export function updateMaker(payload) {
    const request = {
        url: `/makers/${payload.id}/`,
        method: 'patch',
        payload: payload,
        onSuccess: SET_PROFILE,
    };
    return store.dispatch(API_REQUEST, request);
}

export function updateMakerPagingPreferences(perPage, productView) {
    const payload = {
        perPage,
        productView
    }
    return updateMaker({id: store.getters.makerId, preferences: JSON.stringify(payload)});
}

export function activateMaker(maker_id, activate) {
    const request = {
        url: `/makers/${maker_id}/`,
        method: 'patch',
        payload: {is_active: activate},
    };
    return store.dispatch(API_REQUEST, request);
}

export function deleteMaker(maker_id) {
    const request = {
        url: `/makers/${maker_id}/`,
        method: 'delete',
    };
    return store.dispatch(API_REQUEST, request);
}

export function getSubscriptionCount() {
    const request = {
        url: '/makers/subscription-count',
        method: 'get'
    };
    return store.dispatch(API_REQUEST, request);
}