<template>
  <b-modal id="addEditRecipeModal" size="xxl" centered :title="title" :ok-title="getOkAction()"
           :ok-disabled="!validForSave"
           no-close-on-backdrop
           no-close-on-esc
           hide-header-close
           @ok="save" @hidden="resetModal">
    <b-row class="m-0">
      <b-col class="col-12 p-0 mx-2 mb-0">
        <draggable v-model="localRecipe.images" @end="updateRotations">
          <transition-group tag="div" name="list" class="d-flex overflow-auto pt-5 pt-xl-0"
                            :class="{'justify-content-center': localRecipe.images.length <= 1}">
            <div v-for="(image, index) in localRecipe.images" :key="`image-${index}`" :ref="`image-${index}`"
                 class="recipe-picture-wrapper mx-2">
              <div class="w-100 h-100 text-center">
                <div v-if="isVideo(image)" class="embed-responsive embed-responsive-4by3 recipe-image recipe-video">
                  <video :src="getImageUrl(image)" loop controls muted></video>
                </div>
                <b-img v-else :ref="`img-${index}`" :src="getImageUrl(image)" class="recipe-image"/>
                <span v-if="!image.image && !image.imageData" class="text-center no-image-text">No Image</span>
                <div v-else class="icon-position">
                  <font-awesome-icon icon="trash" role="button" class="mt-1 text-danger"
                                     @click="removeImage($event, index)"/>
                </div>
                <div>
                  <input type="file" :ref="`selectedFile-${index}`" class="file-input"
                         @change="imageFileChanged($event.target.files, index)"
                         @click="$refs[`selectedFile-${index}`]['0'].value = ''"/>
                  <div class="change-photo">
                    <font-awesome-icon v-if="!isVideo(image)" icon="undo" class="rotate-icon mt-1 float-left"
                                       @click="rotateLeft($event, index)"></font-awesome-icon>
                    <font-awesome-icon icon="camera"></font-awesome-icon>&nbsp;{{ photoAction(index) }}
                    <font-awesome-icon v-if="!isVideo(image)" icon="redo" class="rotate-icon mt-1 float-right"
                                       @click="rotateRight($event, index)"></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <div v-if="localRecipe.images.length > 1 || localRecipe.images[0].image || localRecipe.images[0].imageData"
             class="d-flex mx-2 mt-2 justify-content-center">
          <input type="file" ref="selectedFile" class="d-none" @change="imageFileChanged($event.target.files, -1)"
                 @click="$refs.selectedFile.value = ''"/>
          <b-button variant="primary" @click="$refs.selectedFile.click()">Add additional image</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mx-0 mt-3">
      <div class="hb"/>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" sm="8">
        <b-input v-model="localRecipe.name" placeholder="recipe name..."/>
      </b-col>
      <b-col class="mt-2 justify-content-center">
        <b-checkbox v-model="localRecipe.isGlobal">Show in community</b-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3 recipe" id="recipe-notes">
        <ckeditor :editor="editor" v-model="localRecipe.notes" :config="editorConfig"></ckeditor>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <span class="mt-3 mb-1 p-0 font-weight-bold">Recipe Items</span>
    </b-row>
    <div class="mx-0">
      <draggable v-model="localRecipe.items" :disabled="editActive" handle=".handle">
        <transition-group tag="div" name="list" class="pt-1 pt-xl-0">
          <b-row v-for="(item, index) in localRecipe.items" :key="`products-${index}`"
                 class="mx-0 p-0 align-items-center">
            <b-row class="w-100 m-0 p-0 border">
              <div class="p-0 m-0" :class="{'col-12': activeEditIndex >= 0, 'col-11': activeEditIndex === -1}">
                <b-row class="align-items-center mx-0 p-0 col"
                       :class="{'border-dark': activeEditIndex === index}">
                  <b-col style="width: 80px" cols="4" lg="1" class="p-0 m-1">
                    <div v-if="activeEditIndex !== index">
                      <span class="ml-1">{{ item.product.item_number }}</span>
                    </div>
                    <div v-else>
                      <b-input v-model="item.product.item_number"
                               v-on:input="updateProductFilter('product.item_number', item.product.item_number)"
                               :placeholder="itemNumberPlaceholder"/>
                    </div>
                  </b-col>
                  <b-col style="width: 50%" cols="7" lg="4" class="align-middle text-truncate p-0 m-1">
                    <div v-if="activeEditIndex !== index" class="align-items-center">
                      <span class="ml-1 ml-lg-0">{{ item.product.name }}</span>
                    </div>
                    <div v-else>
                      <b-input v-model="item.product.name"
                               v-on:input="updateProductFilter('product.name', item.product.name)"
                               :placeholder="namePlaceholder"/>
                    </div>
                  </b-col>
                  <b-col style="width: calc(100% - (185px + 50%))" cols="8" lg="5" class="text-truncate p-0 m-1">
                    <div v-if="activeEditIndex !== index">
                      <span class="ml-1 ml-lg-0">{{ item.notes }}</span>
                    </div>
                    <div v-else>
                      <b-input v-model="item.notes" placeholder="Notes..."/>
                    </div>
                  </b-col>
                  <b-col style="width: 80px" cols="3" lg="1" class="list-edit-controls">
                    <font-awesome-icon class="action-button mr-3"
                                       :icon="activeEditIndex === index ? 'save' : 'edit'"
                                       @click="editRecipeItem(index)"/>
                    <font-awesome-icon class="action-button text-danger"
                                       :icon="activeEditIndex === index ? 'undo' : 'trash'"
                                       @click="deleteRecipeItem(index)"/>
                  </b-col>
                </b-row>
              </div>
              <div :class="{'d-none': editActive, 'd-flex': !editActive}" class="col-1 p-0 m-0 align-items-center justify-content-center handle">
                <font-awesome-icon class="mr-1" icon="bars"/>
              </div>
            </b-row>
            <b-row class="mx-0"
                   v-if="item.product.id === null && (!!productFilter['product.item_number'] || !!productFilter['product.name'])">
              <b-col cols="12" class="px-0 m-0 mb-2 table-container table-responsive table-bordered table-striped">
                <b-table
                    id="recipeProductList"
                    sort-by="item_number"
                    primary-key="item_number"
                    :items="filteredProductList"
                    :fields="productFields"
                    :fixed="true"
                    class="table-hover"
                    thead-tr-class="d-none"
                    @row-clicked="onProductRowClicked">
                  <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{width: field.width || 'auto'}"
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-row>
        </transition-group>
      </draggable>
    </div>
    <b-row class="mx-0">
      <b-button style="width: 160px" variant="primary" class="mt-2" :disabled="hasNewItem" @click="addItem">Add Recipe
        Item
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import {defineComponent} from 'vue'
import {searchMakerProductList} from "@/pages/products/product-services";
import {
  deleteRecipeImage,
  saveMakerRecipe,
  saveMakerRecipeImage,
  uploadRecipeImage
} from "@/pages/recipes/recipes-service";
import draggable from "vuedraggable";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default defineComponent({
  name: "recipe-add",
  components: {draggable, ckeditor: CKEditor.component},
  props: {recipe: Object},
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ['bold', 'italic', '|', 'numberedList', 'bulletedList']
      },
      localRecipe: this.newItem(),
      saveIsValid: false,
      recipeProducts: [],
      productFields: [
        {
          key: "item_number",
          tdClass: 'align-middle text-center py-2',
          label: 'Item #',
          thClass: 'text-center',
          width: '80px'
        },
        {key: "name", tdClass: 'align-middle py-2', label: 'Name'},
      ],
      productList: [],
      productFilter: {
        'product.item_number': '',
        'product.name': ''
      },
      activeEditIndex: -1,
      activeEditItem: null,
      deletedRecipeImages: []
    }
  },
  watch: {
    recipe(newRecipe) {
      if (!newRecipe || Object.keys(newRecipe).length === 0) {
        this.localRecipe = this.newItem();
      } else {
        this.localRecipe = JSON.parse(JSON.stringify(newRecipe));
        this.localRecipe.notes = this.localRecipe.notes || '';
      }
      if (this.localRecipe.images.length === 0) {
        this.localRecipe.images.push({});
      }
      this.deletedRecipeImages = [];
    }
  },
  computed: {
    editActive() {
      return this.activeEditIndex >= 0;
    },
    filteredProductList() {
      if (!!this.productFilter['product.item_number'] || !!this.productFilter['product.name']) {
        return this.productList.filter(p => {
          // Ensure we filter out current items
          return p.item_number.toUpperCase().indexOf(this.productFilter['product.item_number']) > -1 &&
              p.name.toUpperCase().indexOf(this.productFilter['product.name']) > -1 &&
              !this.localRecipe.items.map(i => {
                if (i.product.id) {
                  return i.product.item_number;
                }
              }).includes(p.item_number);
        });
      } else {
        return [];
      }
    },
    hasNewItem() {
      return this.activeEditItem !== null;
    },
    title() {
      return `${this.recipe ? 'Edit ' + this.recipe.name : 'Add Recipe'}`;
    },
    validForSave() {
      return !!this.localRecipe.name && this.activeEditItem === null;
    },
    itemNumberPlaceholder() {
      if (this.activeEditItem && this.activeEditItem.product.item_number) {
        return this.activeEditItem.product.item_number;
      } else {
        return 'Item #';
      }
    },
    namePlaceholder() {
      if (this.activeEditItem && this.activeEditItem.product.name) {
        return this.activeEditItem.product.name;
      } else {
        return 'Item name';
      }
    }
  },
  methods: {
    setImageStyles(index) {
      if (this.localRecipe.images.length > index) {
        this.$refs[`img-${index}`]['0'].style.cursor = 'pointer';

        if (this.localRecipe.images[index].imageData) {
          this.$refs[`img-${index}`]['0'].style.transform = `rotate(${-this.localRecipe.images[index].imageData.rotation}deg)`;
        } else {
          this.$refs[`img-${index}`]['0'].style.transform = `rotate(0deg)`;
        }
      }
    },
    photoAction(index) {
      return (this.localRecipe.images.length > index && (this.localRecipe.images[index].image || this.localRecipe.images[index].imageData)) ? 'Change' : 'Add';
    },
    recipeHasImage(images) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].image || images[i].imageData) {
          return true;
        }
      }
      return false;
    },
    deleteRecipeItem(index) {
      if (this.activeEditItem) {
        // Delete means undo if currently an edit is active
        if (this.activeEditIndex === index) {
          this.localRecipe.items[index] = this.activeEditItem;
          this.productFilter = {'product.name': '', 'product.item_number': ''};
          this.activeEditIndex = -1;
          this.activeEditItem = null;

          // If there was no product selected, then remove this item (should be Add Item without a selected product)
          if (this.localRecipe.items[index].product.id === null) {
            this.deleteRecipeItem(index);
          }
        }
        return;
      }
      this.localRecipe.items.splice(index, 1);
    },
    editRecipeItem(index) {
      // If there is an active edit, then this is a save operation.
      if (this.activeEditItem) {
        // If there is no product selected, then don't save it (should be Add Item without a selected product)
        if (this.localRecipe.items[index].product.id === null && this.activeEditItem.product.id === null) {
          this.deleteRecipeItem(index);
          return;
        }

        // If the user hasn't selected a new product then put back the original one.
        if (this.localRecipe.items[this.activeEditIndex].product.id === null) {
          this.localRecipe.items[this.activeEditIndex].product = {...this.activeEditItem.product};
        }

        // Clear the edit now that the edited item has all of the needed updates.
        this.productFilter = {'product.name': '', 'product.item_number': ''};
        this.activeEditIndex = -1;
        this.activeEditItem = null;
        return;
      }
      this.activeEditIndex = index;
      this.activeEditItem = JSON.parse(JSON.stringify(this.localRecipe.items[index]));
      this.localRecipe.items[index].product.id = null;
      this.localRecipe.items[index].product.item_number = null;
      this.localRecipe.items[index].product.name = null;
    },
    updateProductFilter(cell, item) {
      searchMakerProductList(item, 1).then((response) => {
        this.productList = response.data.results.map(r => r.product);
        this.productFilter[cell] = item.toUpperCase();
      }).catch(() => {
      });
    },
    onProductRowClicked(item) {
      // Find the item being edited
      const index = this.activeEditIndex; // this.localRecipe.items.map(i => i.product.id).indexOf(null);
      this.localRecipe.items[index].product = {...item};
      this.productFilter = {'product.name': '', 'product.item_number': ''};
    },
    async save(event) {
      event.preventDefault();

      if (this.localRecipe.isGlobal && (!this.recipeHasImage(this.localRecipe.images) || this.localRecipe.items.length === 0)) {
        alert("Must have an assigned image and items to add this recipe to the community.");
        return;
      }

      const images = this.localRecipe.images;
      delete this.localRecipe.images;

      try {
        for (let i = 0; i < this.localRecipe.items.length; i++) {
          this.localRecipe.items[i].sequence = i;
        }
        let response = await saveMakerRecipe(this.localRecipe);

        // Delete removed images.
        for (let i = 0; i < this.deletedRecipeImages.length; i++) {
          await deleteRecipeImage(this.deletedRecipeImages[i]);
        }

        // Add / update current images
        if (this.recipeHasImage(images)) {
          for (let i = 0; i < images.length; i++) {
            const imageData = images[i].imageData;
            delete images[i].imageData;

            images[i].recipe = response.data.id;
            images[i].sequence = i;

            let imageResponse = await saveMakerRecipeImage(images[i]);

            if (imageData) {

              // Need to create the RecipeImage record to get its id and then upload the image for that id
              const formData = new FormData();

              // append the files to FormData
              formData.append('rotation', imageData.rotation);

              if (imageData.file) {
                formData.append('file', imageData.file, imageData.file.name);
              }
              await uploadRecipeImage(imageResponse.data, formData);
            }
          }
        }
        this.$bvModal.hide("addEditRecipeModal");
        this.$emit("recipeComplete");
      } catch (e) {
        console.log(e);
      }
    },
    resetModal() {
      this.localRecipe = this.newItem();
      this.activeEditItem = null;
      this.activeEditIndex = -1;
    },
    newItem() {
      return {
        name: null,
        notes: '',
        isGlobal: false,
        images: [{}],
        items: [],
        maker: this.$store.getters.makerId
      };
    },
    addItem() {
      this.localRecipe.items.push({
        notes: '',
        product: {id: null, item_number: '', name: ''}
      });
      this.activeEditIndex = this.localRecipe.items.length - 1;
      this.activeEditItem = JSON.parse(JSON.stringify(this.localRecipe.items[this.activeEditIndex]));
    },
    getOkAction() {
      if (this.localRecipe.id > 0) {
        return 'Update'
      } else {
        return 'Save';
      }
    },
    updateRotations() {
      for (let i=0; i < this.localRecipe.images.length; i++) {
        this.setImageStyles(i);
      }
    },
    setRotation(rotation, index) {
      if (this.localRecipe.images[index].imageData) {
        this.localRecipe.images[index].imageData.rotation = rotation;
      } else {
        this.localRecipe.images[index].imageData = {
          rotation: rotation
        };
      }
      this.setImageStyles(index);
    },
    rotateLeft(event, index) {
      event.preventDefault();
      if (this.localRecipe.images.length > index) {
        if (this.localRecipe.images[index].imageData) {
          this.setRotation((this.localRecipe.images[index].imageData.rotation + 90) % 360, index);
        } else if (this.localRecipe.images[index].image) {
          this.setRotation(90, index);
        }
      }
    },
    rotateRight(event, index) {
      event.preventDefault();
      if (this.localRecipe.images.length > index) {
        if (this.localRecipe.images[index].imageData) {
          this.setRotation((this.localRecipe.images[index].imageData.rotation - 90) % 360, index);
        } else if (this.localRecipe.images[index].image) {
          this.setRotation(-90, index);
        }
      }
    },
    imageFileChanged(fileList, index) {
      // handle file changes
      if (fileList.length > 0) {
        if (index === -1) {
          this.localRecipe.images.push({});
          index = this.localRecipe.images.length - 1;
        }

        this.localRecipe.images[index] = {
          id: this.localRecipe.images[index].id,
          recipe: this.localRecipe.id,
          sequence: this.localRecipe.images.length - 1,
          image: this.localRecipe.images[index].image || 'new-image',
          imageData: {
            imageUrl: URL.createObjectURL(fileList[0]),
            file: fileList[0],
            rotation: 0
          }
        }

        setTimeout(() => {
          this.$refs[`image-${index}`]['0'].scrollIntoView();
        }, 100);
      } else {
        delete this.localRecipe.images.splice(index, 1);
      }
      this.$nextTick(() => this.$forceUpdate());
    },
    removeImage(event, index) {
      event.preventDefault();
      if (this.localRecipe.images[index].id) {
        this.deletedRecipeImages.push(this.localRecipe.images[index]);
      }
      this.localRecipe.images.splice(index, 1);
      if (this.localRecipe.images.length === 0) {
        this.localRecipe.images.push({});
      }
      this.updateRotations();
    }
  }
})
</script>

<style scoped lang="scss">
.recipe-picture-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 180px;
  min-width: 180px;
  height: auto;
  margin: auto;
  border-radius: 5%;

  .recipe-image {
    width: 100%;
    height: 100%;
    border: lightgray solid 1px;
    background-color: #D4E0E6;
    object-fit: contain;
    -o-object-fit: contain;
  }

  .recipe-video {
    z-index: 900;
    margin-bottom: 15% !important;
  }

  .product-list-thumb {
    width: 192px;
    height: auto;
    font-size: 10pt;
  }

  .no-image-text {
    font-size: large;
  }

  .change-photo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 5px 7px;
    background-color: rgba(204, 204, 204, 0.6);
    font-family: "Poppins", sans-serif;
    border-radius: 0 0 5px 5px;
    pointer-events: none;

    .rotate-icon {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .recipe-picture-wrapper {
    position: relative;
    overflow: hidden;
    max-width: 300px;
    min-width: 300px;
    height: auto;
    margin: auto;
    border-radius: 5%;

    .recipe-image {
      width: 100%;
      height: 100%;
      border: lightgray solid 1px;
      background-color: #D4E0E6;
      object-fit: contain;
      -o-object-fit: contain;
    }

    .product-list-thumb {
      width: 192px;
      height: auto;
      font-size: 10pt;
    }

    .no-image-text {
      font-size: large;
    }

    .change-photo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 5px 7px;
      background-color: rgba(204, 204, 204, 0.6);
      font-family: "Poppins", sans-serif;
      border-radius: 0 0 5px 5px;
      pointer-events: none;

      .rotate-icon {
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .file-input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.icon-position {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, .5);
  border: lightgray solid 1px;
}

.ck .ck-editor__editable {
  padding: var(--ck-spacing-standard) var(--ck-spacing-standard);
}

</style>