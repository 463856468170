<template>
  <div class="row">
    <div class="col">
      <auth-header/>
      <div class="d-flex justify-content-center">
        <div class="card m-4">
          <div class="card-body">
            <div class="pt-0 pb-2">
              <h5 class="card-title text-center pb-0">Reset Password</h5>
            </div>
            <div class="mt-4">
              Enter your email address and click Submit and we'll send you a password reset link.
            </div>
            <Alert
                variant="danger"
                :show="showError"
                @dismissed="showError=false"
            >
              {{ this.error }}
            </Alert>

            <b-input-group class="my-3">
              <template #prepend>
                <b-input-group-text>
                  <font-awesome-icon icon="envelope"></font-awesome-icon>
                </b-input-group-text>
              </template>
              <b-form-input v-model="email" type="email" placeholder="email"
                            @keyup.native="checkKeypress"></b-form-input>
            </b-input-group>

            <b-button variant="primary" class="w-100" @click="resetPassword" :disabled="email.length === 0">Submit</b-button>
            <div class="mt-3 text-center">
              <span class="small link-look" @click="$emit('transition', 'login')">Back to login</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Alert from '@/pages/form/alert';
import {forgotPassword} from "./auth-services";
import {SET_FORGOT_PASSWORD} from "@/utils/actionConstants";
import AuthHeader from "@/pages/auth/auth-header.vue";

export default {
  name: "forgotPassword",
  components: {
    AuthHeader,
    Alert
  },
  data() {
    return {
      email: '',
      error: null,
      showError: false,
    }
  },
  methods: {
    resetPassword() {
      forgotPassword(this.email)
          .then(() => {
            this.$store.commit(SET_FORGOT_PASSWORD, true);
            this.$emit('transition', 'login');
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              this.error = err.response.data.error;
              this.showError = true;
            }
          });
    },
    checkKeypress(event) {
      if (event.keyCode === 13 && this.email.length > 0) {
        this.resetPassword();
      }
    },
  }
}
</script>
