<template>
  <b-modal id="subscriptionNotice"
           body-bg-variant="primary-light"
           body-class="rounded-lg"
           no-close-on-esc
           no-close-on-backdrop
           hide-footer
           hide-header
           centered
  >
    <div class="p-0">
      <h2 class="text-center">Welcome to MyStampingStuff</h2>
    </div>
    <div v-if="subscriptionState === 'freeTrial'" class="m-sm-2 m-1">
      <h4 class="mb-3 mt-4">Have questions? Click <em>Support</em> in the top menu bar and explore the extensive FAQs.
      </h4>
      <h4 class="mb-3">You have been granted a 14-day free trial to explore and use the site.</h4>
      <h4 class="mb-3">Sign up for just $40/year at the end of your trial. <strong>That's less than
        80&cent;/week!</strong></h4>
      <b-row>
        <b-col cols="3" sm="4"/>
        <b-col cols="6" sm="4" class="mt-3 text-center align-items-center">
          <b-btn @click="dismissFreeTrial" class="btn-lg w-100" variant="warning">Ok</b-btn>
        </b-col>
        <b-col cols="3" sm="4"/>
      </b-row>
    </div>
    <div v-else-if="subscriptionState === 'trialExpired'" class="m-sm-2 m-1">
      <h4 class="mb-3 mt-4">Looks like your trial has ended. If you'd like to continue using the site,
        please click the <strong>Subscribe</strong> button below.</h4>
      <h4 class="mb-3">Sign up for just $40/year. <strong>That's less than
        80&cent;/week!</strong></h4>
      <h4 class="mb-3">If you do not want to continue, then click the <strong>Logout</strong> button.</h4>
      <b-row>
        <b-col cols="0" sm="1"/>
        <b-col cols="6" sm="5" class="mt-3 text-center align-items-center">
          <b-btn @click="subscribe" class="btn-lg w-100" variant="warning">Subscribe</b-btn>
        </b-col>
        <b-col cols="6" sm="5" class="mt-3 text-center align-items-center">
          <b-btn @click="logout" class="btn-lg w-100" variant="info">Logout</b-btn>
        </b-col>
        <b-col cols="0" sm="1"/>
      </b-row>
    </div>
    <div v-else class="m-sm-2 m-1">
      <h4 class="my-3">Looks like your subscription has ended. If you'd like to continue using the site then
        please click the <strong>Renew</strong> button below.</h4>
      <h4 class="mb-3">The price remains just $40/year. <strong>That's less than 80&cent;/week!</strong></h4>
      <h4 class="mb-3">If you do not want to continue, then click the <strong>Logout</strong> button.</h4>
      <b-row>
        <b-col cols="0" sm="1"/>
        <b-col cols="6" sm="5" class="mt-3 mx-0 text-center align-items-center">
          <b-btn @click="subscribe" class="btn-lg w-100" variant="warning">{{ subscriptionState === 'trialExpired' ? 'Subscribe' : 'Renew' }}</b-btn>
        </b-col>
        <b-col cols="6" sm="5" class="mt-3 text-center align-items-center">
          <b-btn @click="logout" class="btn-lg w-100" variant="info">Logout</b-btn>
        </b-col>
        <b-col cols="0" sm="1"/>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {logout} from "@/utils/actions";
import {startTrialSubscription, subscribe} from "@/pages/subscription/subscription-service";
import {showWelcomeTutorial} from "@/tutorial/tutorial-welcome";

export default {
  name: "subscriptionOverlay",
  data() {
    return {
      subscription: this.$store.getters.profile.subscription,
      subscriptionState: 'subscribed'
    }
  },
  mounted() {
    if (!this.subscription) {
      this.subscriptionState = 'freeTrial';
    } else if (this.subscription.status === 'freeTrial') {
      this.subscriptionState = 'trialExpired';
    } else {
      this.subscriptionState = 'subscriptionExpired';
    }
  },
  methods: {
    dismissFreeTrial() {
      this.startFreeTrial();
    },
    subscribe() {
      // Call subscribe endpoint to redirect to Stripe.
      const rx = /(http(s)?:\/\/.*?)(\/.*)/g;
      const arr = rx.exec(window.location.href);
      const path = arr[3]
      subscribe(path).then((response) => {
        window.location.href = response.data.url;
      }).catch((error) => {
        const status = error.response ? error.response.status : 500;
        const statusText = error.response && error.response.data ? `${error.response.statusText} (${JSON.stringify(error.response.data)})` : error.message;
        alert(`Server Error (${status}): ${statusText}`);
        this.logout();
      });
      this.$bvModal.hide('subscriptionNotice');
    },
    startFreeTrial() {
      startTrialSubscription().then(() => {
        this.$bvModal.hide('subscriptionNotice');
        this.$nextTick(() => showWelcomeTutorial());
      });
    },
    logout() {
      logout().then(() => this.$router.push({name: 'root'})).catch();
    },
  }
}
</script>

<style scoped>

h2 {
  font-size: 1.75rem;
  font-weight: bold;
}

h4 {
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  h2 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.4rem;
  }
}

</style>
