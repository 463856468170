<!-- HTML Template -->
<template>
  <b-modal id="fileUpload" :title="title" no-close-on-backdrop ok-only @ok="dismiss" @hide="dismiss">
    <!--UPLOAD-->
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="dropbox">
        <input type="file" name="file" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
               accept="accept" class="input-file">
        <p v-if="isInitial">
          Drag your file(s) here to begin<br> or click to browse
        </p>
        <p v-if="isSaving">
          {{ uploadingMsg }}
        </p>
      </div>
    </form>

    <!--SUCCESS-->
    <div v-if="isSuccess">
      <h2>Uploaded Succeeded.</h2>
    </div>

    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Uploaded failed.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Try again</a>
      </p>
      <span>{{ uploadError }}</span>
    </div>
  </b-modal>
</template>

<!-- Javascript -->
<script>

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'fileUpload',
  props: {
    title: String,
    accept: String,
    saveFxn: Function,  // This function must return a Promise so this modal knows when the upload is done.
    maxDots: Number,
  },
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadingMsg: '',
      dotLength: 0,
      timeoutId: 0,
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    dismiss() {
      this.reset();
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
    },
    updateWaitingMessage() {
      this.dotLength = this.dotLength + 1;
      if (this.dotLength > (this.maxDots || 5)) {
        this.dotLength = 1;
      }
      const dots = '.'.repeat(this.dotLength);
      this.uploadingMsg = `Uploading ${dots}`;
      this.timeoutId = setTimeout(() => this.updateWaitingMessage(), 250);
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      const formData = new FormData();
      formData.append(fieldName, fileList[0], fileList[0].name);

      this.currentStatus = STATUS_SAVING;
      this.dotLength = 1;
      this.updateWaitingMessage();

      // save it
      this.saveFxn(formData)
          .then(() => {
            clearTimeout(this.timeoutId);
            this.currentStatus = STATUS_SUCCESS;
            this.$emit('completed');
          })
          .catch((err) => {
            clearTimeout(this.timeoutId);
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
    },
  },
  created() {
    this.reset();
  },
}
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

</style>
