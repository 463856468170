import {API_REQUEST, SET_PROFILE} from "@/utils/actionConstants";
import store from "@/store";

export function startTrialSubscription() {
    const request = {
        url: '/subscriptions/start_trial/',
        method: 'post',
        onSuccess: SET_PROFILE
    }
    return store.dispatch(API_REQUEST, request)
}

export function subscribe(success_url) {
    const request = {
        url: '/subscriptions/subscribe/',
        method: 'post',
        payload: { success_url }
    }
    return store.dispatch(API_REQUEST, request)
}

export function cancelSubscription(maker_id, cancelAt = null) {
    const payload = {}
    if (cancelAt !== null) {
        payload['end_date'] = cancelAt.toUTC().toISO();
    } else {
        payload['cancel_at_term_end'] = true;
    }
    const request = {
        url: `/subscriptions/update/maker/${maker_id}/`,
        method: 'post',
        payload: payload
    }
    return store.dispatch(API_REQUEST, request)
}