import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCamera,
    faCheck,
    faCheckCircle,
    faCirclePlus,
    faEdit,
    faEnvelope,
    faExchange,
    faGrip,
    faHeart,
    faList,
    faLock,
    faPlus,
    faPrint,
    faRedo,
    faSave,
    faStar,
    faTh,
    faTimesCircle,
    faTrash,
    faUndo,
    faUser,
    faUsers,
    faChevronDown,
    faChevronRight,
    faArrowLeft,
    faBars
} from '@fortawesome/free-solid-svg-icons'
import {faHeart as faHeartOpen} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import GlobalFxns from '@/utils/utilities'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'intro.js/introjs.css'
import Router from 'vue-router'

library.add(faCheck, faCirclePlus, faGrip, faHeart, faEdit, faExchange, faTrash, faPlus, faLock, faEnvelope, faCamera, faUndo, faRedo, faList, faTh,
    faTimesCircle, faCheckCircle, faPrint, faUser, faUsers, faStar, faHeartOpen, faChevronDown, faChevronRight, faArrowLeft, faBars, faSave);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);

Vue.use(Router)
Vue.use(BootstrapVue)

Vue.config.productionTip = false;
Vue.mixin(GlobalFxns);

new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')
