<template>
  <div class="row">
    <div class="col">
      <auth-header/>
      <div class="d-flex justify-content-center">
        <div class="card m-4">
          <div class="card-body">
            <div class="pt-0 pb-2">
              <h5 class="card-title text-center pb-0">Login to Your Account</h5>
            </div>
            <v-alert
                class="mx-auto my-3 text-center"
                variant="success"
                :show="showAlertMessage"
                @dismissed="showAlertMessage=false"
            >
              {{ alertMessage }}
            </v-alert>
            <v-alert
              class="mx-auto my-3 text-center"
              variant="danger"
              :show="!!error"
              @dismissed="error=null"
            >
              {{error}}
            </v-alert>
            <b-form @submit.stop.prevent="login" class="row g-3 needs-validation">
              <div class="col-12 mt-2">
                <label for="email" class="form-label">Username</label>
                <div class="input-group has-validation">
                  <span class="input-group-text" id="inputGroupPrepend">@</span>
                  <b-form-input
                      id="email"
                      v-model="$v.maker.email.$model"
                      type="text"
                      class="form-control"
                      placeholder="email"
                      :state="validateState('email')"/>
                  <b-form-invalid-feedback>Please enter your username (email).</b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-12 mt-2">
                <label for="password" class="form-label">Password</label>
                <div class="input-group has-validation">
                  <span class="input-group-text" id="inputGroupPrepend"><font-awesome-icon icon="lock" /></span>
                  <b-form-input
                      id="password"
                      v-model="$v.maker.password.$model"
                      type="password"
                      class="form-control"
                      @keyup="checkKeypress"
                      placeholder="password"
                      :state="validateState('password')"
                  />
                  <b-form-invalid-feedback>Please enter your password.</b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-12 mt-3">
                <button class="btn btn-primary w-100">Login</button>
              </div>
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-5">
                    <span class="small link-look" @click="$emit('transition', 'forgotPassword')">Forgot Password</span>
                  </div>
                  <div class="col-7">
                    <p class="float-right small mb-0">No account?
                      <span class="link-look" @click="$emit('transition', 'register')">Create one!</span>
                    </p>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login} from "./auth-services";
import Alert from "@/pages/form/alert";
import {SET_FORGOT_PASSWORD} from "@/utils/actionConstants";
import router from "@/router";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import AuthHeader from "@/pages/auth/auth-header.vue";

export default {
  name: "LoginPage",
  components: {
    AuthHeader,
    'v-alert': Alert
  },
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      alertMessage: null,
      showAlertMessage: false,
      maker: {
        email: '',
        password: ''
      },
    }
  },
  validations: {
    maker: {
      email: {
        required
      },
      password: {
        required
      }
    }
  },
  created() {
    if (this.$store.getters.userCompletedForgotPassword) {
      this.showAlertMessage = true;
      this.alertMessage = 'Check your email for your password reset link';
      this.$store.commit(SET_FORGOT_PASSWORD, false);
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.maker[name];
      return $dirty ? !$error : null;
    },
    login() {
      this.$v.maker.$touch();
      if (this.$v.maker.email.$anyError || this.$v.maker.password.$anyError) {
        return;
      }

      login(this.maker.email, this.maker.password).then(() => {
        // We are all logged in, so transition to the "root" page
        router.push({name: 'dashboard'}).catch(() => {});
      })
          .catch((error) => {
            if (error.response) {
              this.error = error.response.data.detail;
            } else {
              this.error = "Unable to reach the server."
            }
            this.$v.$reset();
          });
    },
    checkKeypress(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    }
  },
}
</script>
