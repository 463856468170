<script>

import * as fxns from '@/pages/support/support-fxns';

export default {
  name: "supportPage",
  props: ['question', 'answer', 'index'],
  data() {
    return {
      linkList: []
    }
  },
  mounted(){
    this.linkList = document.getElementsByTagName('a');
  },

  // watch for changes to linkList and do something
  watch: {
    linkList: function() {
      for(let i=0; i < this.linkList.length; i ++ ) {
        if (this.linkList[i].target === '_blank') {
          this.linkList[i].onclick = this.linkAction;
        } else if (this.linkList[i].target === '_fxn') {
          this.linkList[i].onclick = this.linkFunction;
        }
      }
    }
  },
  methods: {
    detectMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    linkFunction(event) {
      event.preventDefault();
      fxns[event.target.name]();
    },
    linkAction(event) {
      event.preventDefault();
      if (this.detectMobile()) {
        window.open(encodeURI(`${process.env.VUE_APP_SERVER_BASE_URL}/static/docs/${event.target.name}`), '_blank');
      } else {
        this.$router.push({name: 'helpDoc', params: {fileName: event.target.name}});
      }
    }
  }
}
</script>

<template>
  <b-card no-body class="support mb-1">
    <b-card-body class="p-1">
      <div class="row align-items-center m-0 p-0">
        <b-btn class="text-left font-weight-bolder w-100" href="#" v-b-toggle="`accordion-${index}`" variant="primary">
          <span class="when-opened">
            <font-awesome-icon icon="chevron-down"/>
          </span>
          <span class="when-closed">
            <font-awesome-icon icon="chevron-right"/>
          </span>
          &nbsp;&nbsp;{{ question }}
        </b-btn>
      </div>
      <b-collapse :id="`accordion-${index}`" accordion="support-accordion" role="tabpanel">
        <b-card-text class="my-3 mx-2" v-html="answer"></b-card-text>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<style scoped lang="scss">
.collapsed > .when-opened, :not(.collapsed) > .when-closed {
  display: none;
}
</style>