<!--suppress CssUnusedSymbol -->
<template>
  <div class="table-responsive table-bordered overflow-scroll">
    <b-table
        id="userProductList"
        sort-by="item_number"
        primary-key="item_number"
        :items="userProductList"
        :fields="fields"
        hover
        fixed
        striped>
      <template v-slot:table-colgroup="scope">
        <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{width: field.width}"
        >
      </template>
      <template #cell(image)="data">
        <div class="position-relative text-center d-flex p-1">
          <div class="list-image-container">
            <b-img :id="`ctmh_row_${data.item.id}`" :src="getImageUrl(data.item)" class="product-list-thumb"/>
            <span v-if="!hasImage(data.item)" class="no-image-text">No Image</span>
          </div>
          <b-tooltip v-if="hasImage(data.item)" :target="`ctmh_row_${data.item.id}`" triggers="hover"
                     placement="right"
                     boundary="viewport">
            <img :src="getImageUrl(data.item)" width="256px" height="256px" style="object-fit: contain"
                 :alt="`${data.item.item_number} image`"/>
            <font-awesome-icon v-if="!isGlobalProductImage(data.item)" class="tip-trash" icon="trash" size="lg"
                               @click="$emit('deleteMakerImage', data.item)"></font-awesome-icon>
          </b-tooltip>
          <div style="width: 12px">
            <font-awesome-icon v-if="!isGlobalProductImage(data.item)" class="tip-trash"
                               :class="{'d-none': !hasImage(data.item)}" icon="trash"
                               @click="$emit('deleteMakerImage', data.item)"></font-awesome-icon>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <font-awesome-icon :id="`userproduct-edit-${data.index}`" class="action-button mr-4" icon="edit" @click="$emit('editItem', data.item)"/>
        <font-awesome-icon :id="`userproduct-delete-${data.index}`" class="action-button text-danger" icon="trash" @click="$emit('deleteItem', data.item)"/>
      </template>
      <template #cell(tags)="data">
        <div tabindex="0" class="tag-wrapper" :id="`item-tags-${data.item.id}`">
            <span :ref="`item-tags-${data.item.id}`">
              {{ data.value }}
            </span>
          <b-tooltip v-if="data.value.length > 0" :ref="`item-tags-${data.item.id}-tooltip`"
                     :target="`item-tags-${data.item.id}`" triggers="hover"
                     custom-class="tag-tip-wrapper" boundary="viewport" placement="top">
            <div class="tag-tip">
              {{ data.value }}
            </div>
          </b-tooltip>
        </div>
      </template>
      <template #cell(location)="data">
        {{ data.value.name }}
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  name: "userListView",
  props: {
    userProductList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fields: [
        {key: "image", tdClass: 'align-middle p-0', width: '80px'},
        {
          key: "product.item_number",
          tdClass: 'align-middle text-center',
          label: 'Item #',
          thClass: 'text-center',
          width: '80px'
        },
        {key: "product.name", tdClass: 'align-middle', label: 'Name', width: '300px'},
        {key: "location.name", label: 'Location', tdClass: 'align-middle', width: '150px'},
        {key: "tags", tdClass: 'item-tags align-middle', formatter: this.formatTagList, width: '200px'},
        {key: 'product.ideabook.name', label: 'Idea Book', tdClass: 'align-middle', width: '250px'},
        {key: "actions", tdClass: 'list-edit-controls', thClass: 'align-middle text-center', width: '70px'},
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.tip-trash {
  color: red;
  padding: 2px;
  margin-left: 3px;
  vertical-align: top;
  cursor: pointer;
}

</style>

<style>

.item-tags {
  overflow: hidden;
  max-width: 15%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag-wrapper {
  max-height: 25px !important;
  height: 25px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-tip {
  text-align: start;
}

.tag-tip-wrapper {
  max-width: 50% !important;
}

</style>
