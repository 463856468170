<template>
  <b-carousel
      id="login-carousel"
      :interval="interval"
      no-hover-pause
      fade
      class="w-100 m-0"
      style="overflow: hidden"
  >
    <!-- Text slides with image -->
    <b-carousel-slide>
      <template #img>
        <img alt="image" class="login-image" src="/assets/img/slide1_lg.jpg"/>
      </template>
    </b-carousel-slide>

    <b-carousel-slide>
      <template #img>
        <img alt="image" class="login-image" src="/assets/img/slide2_lg.jpg"/>
      </template>
    </b-carousel-slide>

    <b-carousel-slide>
      <template #img>
        <img alt="image" class="login-image" src="/assets/img/slide3_lg.jpg"/>
      </template>

    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  name: "authCarousel",
  data() {
    return {
      interval: 3500,
    }
  }
}
</script>

<style lang="scss">

// Ensure the carousel image scales properly
.login-image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  -o-object-fit: cover;
}

</style>