<template>
  <div class="p-2 rounded-2 bg-white">
    <span class="font-weight-bold">Support</span>
    <div class="mt-3">
      <b-row>
        <b-col cols="12" lg="6" v-for="(faq, index) in faqList" :key="`support_${index}`">
          <SupportTopic :question="faq.question" :answer="faq.answer" :index="index"></SupportTopic>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import SupportTopic from "@/pages/support/supportTopic.vue";
import {getFaqs} from "@/pages/support/support-services";

export default {
  name: "supportPage",
  components: {
    SupportTopic
  },
  data: function () {
    return {
      faqList: []
    }
  },
  created() {
    getFaqs().then(response => {
      this.faqList = response.data.results;
    });
  }
}
</script>

<style scoped lang="scss">

</style>