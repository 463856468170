<template>
  <div>
    <div class="row">
      <div class="col">
        <auth-header/>
        <div class="d-flex justify-content-center">
          <div class="card m-4">
            <div class="card-body">
              <div class="pt-0 pb-2">
                <h5 class="card-title text-center pb-0">Register For An Account</h5>
              </div>
              <v-alert
                  class="mx-auto my-3 text-center"
                  variant="danger"
                  :show="showAlertMessage"
                  @dismissed="showAlertMessage=false"
              >
                {{ alertMessage }}
              </v-alert>
              <b-form @submit.stop.prevent="register" class="row g-3 needs-validation">
                <div class="col-12 mt-2">
                  <label for="first-name" class="form-label">First Name</label>
                  <div class="input-group has-validation">
                    <b-form-input
                        id="first-name"
                        v-model="$v.maker.first_name.$model"
                        type="text"
                        class="form-control"
                        placeholder="first name"
                        :state="validateState('first_name')"/>
                    <b-form-invalid-feedback>Please enter your first name.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <label for="last-name" class="form-label">Last Name</label>
                  <div class="input-group has-validation">
                    <b-form-input
                        id="last-name"
                        v-model="$v.maker.last_name.$model"
                        type="text"
                        class="form-control"
                        placeholder="last name"
                        :state="validateState('last_name')"/>
                    <b-form-invalid-feedback>Please enter your last name.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <label for="register-email" class="form-label">Username (email)</label>
                  <div class="input-group has-validation">
                    <b-form-input
                        id="register-email"
                        v-model="$v.maker.email.$model"
                        type="text"
                        class="form-control"
                        placeholder="email"
                        :state="validateState('email')"/>
                    <b-form-invalid-feedback>Please enter your email.</b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <label for="register-password" class="form-label">Password</label>
                  <b-form-input
                      id="register-password"
                      v-model="$v.maker.password.$model"
                      type="password"
                      class="form-control"
                      placeholder="password"
                      :state="validateState('password')"
                  />
                  <b-form-invalid-feedback>Password must be at least {{ $v.maker.password.$params.minLength.min }} characters.</b-form-invalid-feedback>
                  <div v-if="!!error" class="invalid-feedback d-block">{{ error }}</div>
                </div>
                <div class="col-12 mt-2">
                  <label for="confirm-password" class="form-label">Confirm Password</label>
                  <b-form-input
                      id="confirm-password"
                      v-model="$v.maker._password.$model"
                      type="password"
                      class="form-control"
                      placeholder="confirm password"
                      :state="validateState('_password')"
                  />
                  <b-form-invalid-feedback>Your confirmation password doesn't match.</b-form-invalid-feedback>
                </div>
                <div class="col-12 mt-3">
                  <button class="display-1 btn btn-primary w-100">Register</button>
                </div>
                <div class="col-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      <span class="small link-look"
                            @click="$emit('transition', 'forgotPassword')">Forgot Password</span>
                    </div>
                    <div class="col-6">
                      <p class="float-right small mb-0">
                        <span class="link-look" @click="$emit('transition', 'login')">Return to Login</span>
                      </p>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Stripe Redirect -->
    <b-modal v-model="confirmStripeRedirect" id="confirmStripeRedirect" centered title="Free-Trial Confirmation"
             no-close-on-backdrop
             hide-header-close
             hide-footer>
      <div class="text-center">
        <p>Redirecting to <strong>stripe.com</strong> ...</p>
      </div>
    </b-modal>
  </div>

</template>

<script>
import {login, register} from "./auth-services";
import Alert from "@/pages/form/alert";
import {SET_FORGOT_PASSWORD} from "@/utils/actionConstants";
import {validationMixin} from 'vuelidate'
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators'
import router from "@/router";
import AuthHeader from "@/pages/auth/auth-header.vue";

export default {
  name: "RegisterPage",
  components: {
    AuthHeader,
    'v-alert': Alert
  },
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      alertMessage: null,
      showAlertMessage: false,
      maker: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        _password: '',
      },
      validation: {},
      stripeUrl: null,
      confirmStripeRedirect: false,
    }
  },
  validations: {
    maker: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5)
      },
      _password: {
        required,
        sameAs: sameAs('password')
      }
    }
  },
  created() {
    if (this.$store.getters.userCompletedForgotPassword) {
      this.showAlertMessage = true;
      this.alertMessage = 'Check your email for your password reset link';
      this.$store.commit(SET_FORGOT_PASSWORD, false);
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.maker[name];
      return $dirty ? !$error : null;
    },
    register() {
      this.$v.maker.$touch();
      if (this.$v.maker.$anyError) {
        return;
      }

      register(this.maker).then((response) => {
        this.confirmStripeRedirect = true;
        this.stripeUrl = response.data.url;

        // Login the user so that after accepting the trial, we can go straight to the dashboard.
        login(this.maker.email, this.maker.password).then(() => {
          // We are all logged in, so transition to the "root" page
          router.push({name: 'dashboard'});
        }).catch((error) => {
          if (error.response) {
            this.alertMessage = error.response.data.details;
          } else {
            this.alertMessage = "Unable to reach the server."
          }
          this.showAlertMessage = true;
          this.$v.$reset();
        });
      }).catch((err) => {
        if (err.response) {
          this.alertMessage = err.response.data.details;
        } else {
          this.alertMessage = `Error ${err.response.status} - Unable to register account.`;
        }
        this.showAlertMessage = true;
        this.$v.$reset();
      });
    }
  },
}
</script>
