import {API_REQUEST} from "@/utils/actionConstants";
import store from "@/store";

export function fetchMakerRecipe(recipeId) {
    const request = {
        type: API_REQUEST,
        url: `/recipes/${recipeId}/`,
        method: 'get'
    }
    return store.dispatch(API_REQUEST, request);
}

export function fetchMakerRecipes(makerId, global, search=null) {
    const params = {}
    if (global) {
        params.global = global;
    }
    if (search && search.length > 0) {
        params.search = search;
    }
    const request = {
        type: API_REQUEST,
        url: `/recipes/maker/${makerId}`,
        method: 'get',
        params: params,
    }
    return store.dispatch(API_REQUEST, request);
}

export function saveMakerRecipe(recipe) {
    const request = {
        type: API_REQUEST,
        url: recipe.id ? `/recipes/${recipe.id}/` : '/recipes/',
        method: recipe.id ? 'put' : 'post',
        payload: recipe,
    }
    return store.dispatch(API_REQUEST, request);
}

export function saveMakerRecipeImage(image) {
    const request = {
        type: API_REQUEST,
        url: image.id ? `/recipes/${image.recipe}/images/${image.id}/` : `/recipes/${image.recipe}/images/`,
        method: image.id ? 'put' : 'post',
        payload: image,
    }
    return store.dispatch(API_REQUEST, request);
}

export function uploadRecipeImage(image, formData) {
    const request = {
        type: API_REQUEST,
        url: `/recipes/${image.recipe}/images/${image.id}/image/`,
        method: 'post',
        payload: formData,
        headers: {'Content-Type': undefined}
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteRecipe(id) {
    const request = {
        type: API_REQUEST,
        url: `/recipes/${id}/`,
        method: 'delete',
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteRecipeImage(image) {
    const request = {
        type: API_REQUEST,
        url: `/recipes/${image.recipe}/images/${image.id}/`,
        method: 'delete',
    }
    return store.dispatch(API_REQUEST, request);
}

export function addFavorite(makerId, recipeId) {
    const request = {
        type: API_REQUEST,
        url: `/makers/${makerId}/favorite/${recipeId}`,
        method: 'post',
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteFavorite(makerId, recipeId) {
    const request = {
        type: API_REQUEST,
        url: `/makers/${makerId}/favorite/${recipeId}`,
        method: 'delete',
    }
    return store.dispatch(API_REQUEST, request);
}