<template>
  <b-modal id="editMasterProduct" centered :title="title" :ok-title="localItem.id > 0 ? 'Update' : 'Save'"
           :ok-disabled="!validForSave" no-close-on-backdrop
           @ok="save" @hidden="resetModal" @shown="refreshChoices">
    <div class="product-picture-wrapper text-center d-flex align-items-center">
      <b-img v-if="hasImage(localItem)" class="product-list-thumb" v-bind:style="imageStyle" :src="displayUrl"
             :alt="`${localItem.item_number} image`"/>
      <div v-else class="d-flex align-items-center justify-content-center no-image w-100 h-100">
        <span>No Image</span>
      </div>
      <div>
        <Input type="file" class="file-input" @change="imageFileChanged($event.target.files)"/>
        <div class="change-photo">
          <font-awesome-icon v-if="showPhotoControls" icon="undo" class="rotate-icon mt-1 float-left"
                             @click="rotateLeft($event)"></font-awesome-icon>
          <font-awesome-icon icon="camera"></font-awesome-icon>&nbsp;{{ photoAction }}
          <font-awesome-icon v-if="showPhotoControls" icon="redo" class="rotate-icon mt-1 float-right"
                             @click="rotateRight($event)"></font-awesome-icon>
        </div>
      </div>
    </div>
    <div class="mx-3">
      <div class="row mt-3">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Item Number:</span>
        </b-input-group>
        <b-input class="col" id="item_number" placeholder="enter item number" maxlength="20"
                 :value="`${localItem.item_number}`" v-model="localItem.item_number"/>
      </div>

      <div class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Item Name:</span>
        </b-input-group>
        <b-input class="col" id="name" placeholder="enter item name" maxlength="128"
                 :value="`${localItem.name}`" v-model="localItem.name"/>
      </div>

      <div class="row my-1">
        <b-input-group class="col-3 p-0 pr-3 justify-content-end">
          <span>Idea Book:</span>
        </b-input-group>

        <b-input-group class="p-0 ml-0 col">
          <div class="w-100">
            <v-select
                id="addIdeaBook"
                :multiple="false"
                :clearable="true"
                :taggable="true"
                :options="ideaBookSelections"
                :appendToBody="false"
                label="name"
                :closeOnSelect="true"
                placeholder="Search for or enter new Idea Book"
                v-model="localItem.ideabook"
                :createOption="addNewIdeaBook"
                :calculatePosition="calculatePosition"
                @input="ideaBookUpdated"
            >
              <template #search="{ attributes, events }">
                <input
                    maxlength="128"
                    class="vs__search"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
            </v-select>
          </div>
        </b-input-group>
      </div>
    </div>
  </b-modal>
</template>


<script>
import {fetchIdeaBooks, saveProduct, uploadProductImage} from "@/pages/products/product-services";

export default {
  name: "editMasterProduct",
  props: {
    'item': {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      localItem: this.item ? {...this.item} : this.newItem(),
      file: null,
      imageUrl: null,
      rotation: 0,
      saveIsValid: false,
      ideaBooks: [],
      ideaBookSelections: [],
      isSaving: false,
      uniqueId: 0,
      defaultIdeaBook: null
    }
  },
  watch: {
    item: function (newItem) {
      if (!newItem || Object.keys(newItem).length === 0) {
        this.localItem = this.newItem();
      } else {
        newItem.ideabook = this.item.ideabook;
        this.localItem = JSON.parse(JSON.stringify(newItem));
        this.file = null;
      }
    },
  },
  computed: {
    title() {
      return this.customTitle || (this.localItem.id ? `Edit Product ${this.localItem.item_number}` : 'Add Product');
    },
    validForSave() {
      return !this.isSaving || this.localItem.item_number && this.localItem.item_number.length > 0 && this.localItem.name && this.localItem.name.length > 0;
    },
    imageStyle() {
      return {cursor: 'pointer', transform: `rotate(${-this.rotation}deg)`};
    },
    displayUrl() {
      if (this.imageUrl) {
        return this.imageUrl;
      }
      return this.getImageUrl(this.localItem);
    },
    photoAction() {
      return (this.localItem.image || this.imageUrl) ? 'Change' : 'Add'
    },
    showPhotoControls() {
      return !!((this.localItem && this.localItem.image) || this.imageUrl);
    }
  },
  methods: {
    refreshChoices() {
      fetchIdeaBooks().then((response) => {
        this.ideaBooks = response.data;
        this.ideaBookSelections = this.reduceList(this.ideaBooks, [], 'name');
        this.ideaBookSelections.sort((ib1, ib2) => {
          if (this.defaultIdeaBook === null) {
            return ib1.name < ib2.name ? -1 : 1;
          } else if (ib1.name === this.defaultIdeaBook.name) {
            return -1;
          } else if (ib2.name === this.defaultIdeaBook.name) {
            return 1;
          } else {
            return ib1.name < ib2.name ? -1 : 1;
          }
        });
      }).catch(() => {
        this.ideaBooks = []
      });
    },
    processComplete(success = true) {
      if (success) {
        this.$bvModal.hide("editMasterProduct");
        this.$emit('editComplete');
      }
    },
    save(event) {
      event.preventDefault();

      // Save the user's chosen idea book
      this.defaultIdeaBook = this.localItem.ideabook;

      const itemEdited = {...this.localItem};
      const imageData = itemEdited.imageData;
      delete itemEdited.imageData;
      saveProduct(itemEdited)
          .then((response) => {
            const newItem = {...response.data};

            if (imageData) {
              const formData = new FormData();

              // append the files to FormData
              formData.append('rotation', imageData.rotation);

              if (imageData.file) {
                formData.append('file', imageData.file, imageData.file.name);
              }
              uploadProductImage(newItem.id, formData)
                  .then(() => this.processComplete())
                  .catch(() => this.processComplete(false));
            } else {
              this.processComplete();
            }
          })
          .catch(() => this.processComplete(false));
    },
    newItem() {
      return {
        id: null,
        image: null,
        name: null,
        item_number: null,
        ideabook: this.defaultIdeaBook,
        imageData: null
      };
    },
    calculatePosition(dropdownList, component, {width, top, left}) {
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
      dropdownList.style.zIndex = 1500;
    },
    resetModal() {
      this.file = null;
      this.imageUrl = null;
      this.rotation = 0;
    },
    setRotation(rotation) {
      if (this.localItem.imageData) {
        this.localItem.imageData.rotation = rotation;
      } else {
        this.localItem.imageData = {
          rotation: rotation
        };
      }
      this.rotation = rotation;
    },
    rotateLeft(event) {
      event.preventDefault();
      this.setRotation((this.rotation + 90) % 360);
    },
    rotateRight(event) {
      event.preventDefault();
      this.setRotation((this.rotation - 90) % 360);
    },
    imageFileChanged(fileList) {
      // handle file changes
      this.rotation = 0;
      if (fileList.length > 0) {
        this.imageUrl = URL.createObjectURL(fileList[0]);
        this.file = fileList[0];
        this.localItem.imageData = {
          imageUrl: this.imageUrl,
          file: this.file,
          rotation: this.rotation
        };
      } else {
        delete this.localItem.imageData;
        this.imageUrl = null;
        this.file = null;
      }
    },
    ideaBookUpdated(updatedIdeaBook) {
      const selectedList = updatedIdeaBook ? [updatedIdeaBook] : [];
      this.ideaBookSelections = this.reduceList(this.ideaBooks, selectedList, 'name');
      this.ideaBookSelections.sort((ib1, ib2) => {
        if (this.defaultIdeaBook === null) {
          return ib1.name < ib2.name ? -1 : 1;
        } else if (ib1.name === this.defaultIdeaBook.name) {
          return -1;
        } else if (ib2.name === this.defaultIdeaBook.name) {
          return 1;
        } else {
          return ib1.name < ib2.name ? -1 : 1;
        }
      });
    },
    addNewIdeaBook(newIdeaBook) {
      const existingIdeaBook = this.ideaBooks.find(obj => {
        return obj.name.toLowerCase() === newIdeaBook.toLowerCase();
      });
      return existingIdeaBook || {
        id: this.uniqueItemId(),
        name: this.capitalize(newIdeaBook),
      };
    }
    ,
  }
}
</script>

<style scoped lang="scss">

.product-picture-wrapper {
  position: relative;
  overflow: hidden;
  width: 192px;
  min-height: 192px !important;
  margin: auto;
  border: rgba(204, 204, 204, 0.6) solid 1px;
  border-radius: 0 0 5px 5px;

  .product-list-thumb {
    width: 192px;
    height: auto;
    border-radius: 0 0 5px 5px;
  }

  .no-image {
    height: 192px !important;
    background-color: rgba(204, 204, 204, 0.6);
  }

  .change-photo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 5px 7px;
    background-color: rgba(204, 204, 204, 0.6);
    font-family: "Poppins", sans-serif;
    border-radius: 0 0 5px 5px;
    pointer-events: none;

    .rotate-icon {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
