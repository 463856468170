<template>
  <b-modal id="deleteMakerProduct" :title="`Delete Product ${item ? item.product.item_number : ''}`" ok-title="Delete"
           no-close-on-backdrop
           @ok="$emit('deleteMakerProduct', item)">
    <div class="text-center">
      <p class="m-auto">
        Are you sure you want to delete
        <br/>
        {{ item ? item.product.name : '' }}
        <br/>
        from your inventory?
      </p>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "confirmDelete",
  props: {
    'item': Object,
  },
}
</script>

