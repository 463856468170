<template>
  <div class="mt-3 mx-3">
    <b-button class="position-absolute" @click="printPage">Print</b-button>
    <div class="d-flex justify-content-center">
      <h2 class="font-weight-bold">Tag: {{ tagName }}</h2>
    </div>
    <div v-if="totalCount > 0" class="mt-3">
      <div class="row justify-content-center" v-for="product in productList" :key="product.id">
        <div class="col-5">
          <span class="float-right">{{ product.product.item_number }}</span>
        </div>
        <div class="col-7">{{ product.product.name }}</div>
      </div>
    </div>
    <div v-else>
      <div class="row" v-for="product in productList" :key="product.id">
        <h4 class="col-12 text-center font-weight-bold mt-5">
          No Products Found
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import {productsByTag} from "@/utils/actions";

export default {
  name: "printTag",
  data() {
    return {
      tagName: 'Fetching inventory...',
      tagId: null,
      productList: [],
      totalCount: 0,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.tagId = this.$route.query.id;
      productsByTag(this.tagId).then((response) => {
        this.productList = response.data.products;
        this.totalCount = response.data.products.length;
        this.tagName = response.data.tag.tag;
        if (this.totalCount === 0) {
          this.productList = [{id: 0, product: {name: 'No Items Found', item_number: ''}}]
        }

        // Sort the list by item number
        this.productList.sort((a, b) => {
          const idA = a.product.item_number.toUpperCase();
          const idB = b.product.item_number.toUpperCase();
          if (idA < idB) {
            return -1;
          } else if (idA > idB) {
            return 1;
          } else {
            return 0;
          }
        });
      }).catch((e) => console.log('failed', e));
    }
  },
  methods: {
    printPage() {
      window.print();
    }
  }
}
</script>

<style scoped>

</style>
