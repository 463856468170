/*
    App level actions
 */
import store from '@/store'

import {
    API_LOGOUT,
    API_REQUEST,
    API_SET_API_ERROR
} from "@/utils/actionConstants";



export function getSearchParams(search = null, page = null) {
    const params = {};
    if (search) {
        params['search'] = search;
    }
    if (page) {
        params['page'] = page;
    }
    return params;
}

export function logout() {
    return store.dispatch(API_LOGOUT);
}

export function checkVersion(module = '') {
    const request = {
        url: `/versions/${module}`,
        method: 'get',
        noAuth: true,
    };
    return store.dispatch(API_REQUEST, request);

}

export function clearApiError() {
    return store.dispatch(API_SET_API_ERROR, {status: 0, statusText: null});
}

export function fetchMakerLocations() {
    const request = {
        type: API_REQUEST,
        url: '/locations/',
        method: 'get',
        payload: null,
        params: null,
    }
    return store.dispatch(API_REQUEST, request);
}

export function productsByLocation(locationId) {
    const request = {
        type: API_REQUEST,
        url: `/locations/${locationId}/products`,
        method: 'get',
        payload: null,
        params: null,
    }
    return store.dispatch(API_REQUEST, request);
}

export function fetchMakerTags() {
    const request = {
        type: API_REQUEST,
        url: '/tags/',
        method: 'get',
        payload: null,
        params: null,
    }
    return store.dispatch(API_REQUEST, request);
}

export function productsByTag(tagId) {
    const request = {
        type: API_REQUEST,
        url: `/tags/${tagId}/products`,
        method: 'get',
        payload: null,
        params: null,
    }
    return store.dispatch(API_REQUEST, request);
}

export function saveLocation(item) {
    const request = {
        type: API_REQUEST,
        url: item.id > 0 ? `/locations/${item.id}/` : '/locations/',
        method: item.id > 0 ? 'patch' : 'post',
        payload: item,
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteLocation(item) {
    const request = {
        type: API_REQUEST,
        url: `/locations/${item.id}/`,
        method: 'delete',
        id: item.id,
    }
    return store.dispatch(API_REQUEST, request);
}

export function saveTag(item) {
    const request = {
        type: API_REQUEST,
        url: item.id > 0 ? `/tags/${item.id}/` : '/tags/',
        method: item.id > 0 ? 'patch' : 'post',
        payload: item,
    }
    return store.dispatch(API_REQUEST, request);
}

export function deleteTag(item) {
    const request = {
        type: API_REQUEST,
        url: `/tags/${item.id}/`,
        method: 'delete',
        id: item.id,
    }
    return store.dispatch(API_REQUEST, request);
}
