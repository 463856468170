<template>
  <div class="p-2 rounded-2 bg-white">
    <div class="row mb-2 d-flex align-items-center">
      <div class="col-8 col-lg-5 d-flex align-items-center">
        <b-input-group>
          <b-input id="searchBar" type="text" placeholder="Search..."
                   v-model="searchText" v-on:input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-4 col-lg-7 d-inline-flex align-items-center justify-content-end">
        <b-button id="addUserLocation" variant="primary" @click="editLocation({})">
          <font-awesome-icon role="button" class="align-middle"
                             icon="circle-plus"/>
          Add
        </b-button>
      </div>
    </div>
    <div class="table-container table-responsive table-bordered table-striped">
      <b-table sort-by="name" primary-key="id" :items="filteredLocations" :fields="fields">
        <template v-slot:table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{width: field.width}"
          >
        </template>
        <template #cell(actions)="data">
          <font-awesome-icon :id="`location-print-${data.index}`" class="action-button mr-4" icon="print" @click="printLocation(data.item)"/>
          <font-awesome-icon :id="`location-edit-${data.index}`" class="action-button mr-4" icon="edit" @click="editLocation(data.item)"/>
          <font-awesome-icon :id="`location-delete-${data.index}`" class="action-button text-danger" icon="trash" @click="deleteLocation(data.item)"/>
        </template>
      </b-table>
    </div>

    <EditLocation :location="currentLocation" @editLocationClosed="currentLocation=null"
                  @locationUpdated="refresh"/>
    <ConfirmDeleteLocation :location="currentLocation" @locationDeleted="refresh"/>

  </div>
</template>

<script>
import {fetchMakerLocations} from "@/utils/actions";
import EditLocation from "@/pages/locations/editLocation";
import ConfirmDeleteLocation from "@/pages/locations/confirmDeleteLocation";

export default {
  name: "userLocations",
  components: {ConfirmDeleteLocation, EditLocation},
  data() {
    return {
      searchText: '',
      searchActive: false,
      currentLocation: null,
      filteredLocations: [],
      locations: [],
      fields: [
        {key: "name", width: '0%'},
        {key: "actions", tdClass: 'list-edit-controls', thClass: 'text-center', width: '80px'},
      ]
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.currentLocation = null;
      fetchMakerLocations().then((response) => {
        this.locations = response.data.results;
        this.doSearch();
      });
    },
    doSearch() {
      this.filteredLocations = this.locations.filter(l => l.name.toLowerCase().includes(this.searchText.trim().toLowerCase()) || this.searchText.length === 0);
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    editLocation(item) {
      this.currentLocation = item;
      this.$bvModal.show("editLocation");
    },
    deleteLocation(item) {
      this.currentLocation = item;
      this.$bvModal.show('confirmDeleteLocation');
    },
    printLocation(item) {
      let route = this.$router.resolve({path: "/print_location", query: {id: item.id}});
      window.open(route.href);
    }
  }
}
</script>
