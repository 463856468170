<template>
  <div class="p-2 rounded-2 bg-white">
    <div id="userProductCards" v-for="section in cardList" :key="section.name">
      <div v-if="section.items.length > 0" class="section-title p-1">
        <hr class="my-1"/>
        <div v-if="section.name.length > 0" class="col-12 location-title">{{ section.name }}</div>
      </div>
      <div class="row m-0 p-0">
        <div v-for="item in section.items" :key="item.id" class="col-xl-4 col-lg-6 col-12 p-1">
          <UserProductCard :item="item"
                           @editItem="editItem" @deleteItem="deleteItem" @deleteMakerImage="deleteMakerImage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserProductCard from './user-products-comp-card.vue'

export default {
  name: "userProductCardView",
  components: {UserProductCard},
  props: {
    userProductList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cardList() {
      // TODO - Group by location functionality
      const userCardList = [];
      // const noLoc = {name: '-- none --', items: []};
      const noLoc = {name: '', items: []};
      this.userProductList.forEach((item) => {
        noLoc.items.push(item);
        // Find this location
        // const loc = userCardList.find((loc) => loc.name.toLowerCase() === (item.location && item.location.name ? item.location.name.toLowerCase() : null));
        // if (loc) {
        //   loc.items.push(item);
        // } else if (item.location) {
        //   userCardList.push({name: item.location.name, items: [item]});
        // } else {
        //   noLoc.items.push(item);
        // }
      });

      // Sort the location groupings by name
      // userCardList.sort((a, b) => {
      //   const aVal = a.name.toLowerCase();
      //   const bVal = b.name.toLowerCase();
      //   if (aVal < bVal) {
      //     return -1;
      //   } else if (bVal < aVal) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });

      // Put "no location" group at the front of the list
      userCardList.unshift(noLoc);
      return userCardList;
    }
  },
  methods: {
    editItem(item) {
      this.$emit('editItem', item);
    },
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    deleteMakerImage(item) {
      this.$emit('deleteMakerImage', item);
    }
  }
}
</script>

<style scoped lang="scss">

.section-title hr {
  border-top: 2px solid rgba(0, 0, 0, 1);
}

.location-title {
  font-size: large;
  font-weight: bold;
}
</style>
