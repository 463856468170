<template>
  <div id="app-container" class="d-flex flex-column min-vh-100">
    <HeaderView/>
    <div class="px-2 py-2">
      <router-view></router-view>
      <ApiError/>
    </div>
    <FooterView/>
    <SubscriptionOverlay v-if="!$store.getters.hasSubscription"/>
  </div>
</template>

<script>
import HeaderView from '@/pages/app/headerView'
import FooterView from '@/pages/app/footerView';
import ApiError from '@/pages/app/apiError'
import SubscriptionOverlay from '@/pages/subscription/subscriptionOverlay.vue'
import {API_REQUEST, SET_AUTH_TOKEN, SET_LAST_VERSION_CHECK} from "@/utils/actionConstants";
import {DateTime} from "luxon";
import {checkVersion} from "@/utils/actions";
import {showWelcomeTutorial} from "@/tutorial/tutorial-welcome";

export default {
  name: 'main-view',
  components: {
    FooterView,
    HeaderView,
    ApiError,
    SubscriptionOverlay
  },
  data() {
    return {
      timerId: null
    }
  },
  mounted() {
    this.performTimerChecks();

    if (!this.$store.getters.hasSubscription) {
      this.$bvModal.show('subscriptionNotice');
    } else if (!this.$store.getters.seenFirstTimeTutorial) {
      this.startTutorial();
    }
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    }
  },
  methods: {
    startTutorial() {
      showWelcomeTutorial();
    },
    performTimerChecks() {
      this.checkVersion();
      this.refreshToken();
      this.timerId = setTimeout(this.performTimerChecks, 5 * 60 * 1000);
    },
    serverHasNewerVersion(serverVersion) {
      const serverVersions = serverVersion.split('.');
      const localVersions = this.appVersion.split('.');
      const maxIndex = Math.min(serverVersions.length, localVersions.length);

      for (let i = 0; i < maxIndex; i++) {
        if (parseInt(serverVersions[i], 10) > parseInt(localVersions[i], 10)) {
          return true;
        } else if (parseInt(serverVersions[i], 10) < parseInt(localVersions[i], 10)) {
          return false;
        }
      }
      return false;
    },
    checkVersion() {
      // Determine if more than 5 minutes have passed since the last version check
      if (this.$store.getters.lastVersionCheck.plus({minutes: 15}) < DateTime.now()) {
        this.$store.commit(SET_LAST_VERSION_CHECK);
        checkVersion('portal').then((response) => {
          if (this.serverHasNewerVersion(response.data.version)) {
            location.reload();
          }
        }).catch(() => {
        })
      }
    },
    refreshToken() {
      if (!this.$store.getters.nextTokenRefresh || this.$store.getters.nextTokenRefresh.diffNow('seconds').values.seconds < 5 * 60) {
        const refreshToken = this.$store.getters.refreshToken;
        if (refreshToken) {
          const request = {
            url: '/token/refresh/',
            method: 'post',
            payload: {refresh: refreshToken},
          };
          this.$store.dispatch(API_REQUEST, request).then(response => {
            response.data.refresh = refreshToken; // Manually add the refresh token back into the payload.
            this.$store.commit(SET_AUTH_TOKEN, {
              data: response.data, request: request, status: response.status, statusText: response.statusText
            });
          }).catch(() => {
          });
        }
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
}
</script>

<style scoped lang="scss">

.page-content {
  position: relative;
  min-height: 100%;
  height: 100%;
  padding: 0 2rem;
}
</style>
