<template>
  <div class="d-block col-12 col-md-6 p-0 pl-4 order-last order-md-first entice-info">
    <div class="min-vh-100 d-flex flex-column order-lg-1">
      <div class="col-10 my-4">
        <div class="d-block align-middle">
          <h2>Paper Crafters, get organized!</h2>
          <h5>Track and tag all your stuff so you can always find what you are looking for!</h5>
          <br/>
          <h5><u>Features include:</u></h5>
          <ul class="fa-ul">
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Over 13,500
              pre-loaded
              products and images in the system</h5>
            </li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Add your
              own custom items & pictures</h5>
            </li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Create and
              Browse Projects /
              Recipes</h5>
              <ul class="fa-ul">
                <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Add
                  multiple pictures, short videos, notes and ingredients for making your project</h5></li>
                <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Store
                  privately or share with the MyStampingStuff community</h5></li>
                <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>
                  Items from community shared projects are bolded if they are in your inventory</h5></li>
              </ul>
            </li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Import from
              Evernote</h5></li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Usable from laptops, phones, and tablets</h5>
            </li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Searchable
              tags, locations, names, and idea books</h5>
            </li>
            <li><span class="fa-li"><font-awesome-icon icon="heart" size="xs"></font-awesome-icon></span><h5>Free 14-day
              trial</h5>
            </li>
          </ul>
          <br/>
          <h3 class="font-weight-bold">Log in and get started!</h3>
        </div>
      </div>
      <div class="small mr-2 mb-2" style="position: fixed; bottom: 0; right: 0; z-index: 1030">v{{ $store.getters.appVersion }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "entice-page"
}
</script>

<style scoped lang="scss">

h4 {
  font-family: "Poppins", sans-serif;
}

h5 {
  font-family: "Poppins", sans-serif;
}

.entice-info {
  text-align: left !important;
  padding: 2rem;
  color: white !important;
  background-color: rgba(1, 1, 1, .35) !important;
  overflow-y: auto;
}

</style>
