<script>

export default {
  name: "help-doc",
  computed: {
    documentName() {
      return encodeURI(`${process.env.VUE_APP_SERVER_BASE_URL}/static/docs/${this.$route.params.fileName}`);
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}

</script>

<template>
  <div>
    <div role="button" class="ml-1 mt-1 mb-2" @click="goBack"><font-awesome-icon icon="arrow-left"/>&nbsp;Back</div>
    <object :data="documentName" type="application/pdf" width="100%" height="1500px">Can't render file "{{this.$route.params.fileName}}" on this device. If this is a mobile device, try viewing on a laptop or desktop computer.</object>
  </div>
</template>
