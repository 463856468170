<template>
  <div class="p-2 rounded-2 bg-white">
    <div class="row mb-2 d-flex align-items-center">
      <div class="col-8 col-lg-5 d-flex align-items-center">
        <b-input-group>
          <b-input id="searchBar" type="text" placeholder="Search..."
                   v-model="searchText" v-on:input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-4 col-lg-7 d-inline-flex align-items-center justify-content-end">
        <b-button id="addUserTag" variant="primary" @click="editTag({})">
          <font-awesome-icon role="button" class="align-middle"
                             icon="circle-plus"/>
          Add
        </b-button>
      </div>
    </div>
    <div class="table-container table-responsive table-bordered table-striped">
      <b-table sort-by="name" primary-key="id" :items="filteredTags" :fields="fields">
        <template v-slot:table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{width: field.width}"
          >
        </template>
        <template #cell(actions)="data">
          <font-awesome-icon :id="`tag-print-${data.index}`" class="action-button mr-4" icon="print" @click="printTag(data.item)"/>
          <font-awesome-icon :id="`tag-edit-${data.index}`" class="action-button mr-4" icon="edit" @click="editTag(data.item)"/>
          <font-awesome-icon :id="`tag-delete-${data.index}`" class="action-button text-danger" icon="trash" @click="deleteTag(data.item)"/>
        </template>
      </b-table>
    </div>

    <EditTag :tag="currentTag" @editTagClosed="refetchList"/>
    <ConfirmDeleteTag :tag="currentTag" @deleteTagClosed="refetchList"/>

  </div>
</template>

<script>
import {fetchMakerTags} from "@/utils/actions";
import EditTag from "@/pages/tags/editTag";
import ConfirmDeleteTag from "@/pages/tags/confirmDeleteTag";

export default {
  name: "userTags",
  components: {ConfirmDeleteTag, EditTag},
  data() {
    return {
      searchText: '',
      searchActive: false,
      filteredTags: [],
      currentTag: null,
      tags: [],
      fields: [
        {key: "tag", width: '0%'},
        {key: "actions", tdClass: 'list-edit-controls', thClass: 'text-center', width: '80px'},
      ]
    }
  },
  created() {
    this.refetchList();
  },
  methods: {
    doSearch() {
      this.filteredTags = this.tags.filter(t => t.tag.toLowerCase().includes(this.searchText.trim().toLowerCase()) || this.searchText.length === 0);
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    refetchList() {
      this.currentTag=null;
      fetchMakerTags().then((response) => {
        this.tags = response.data;
        this.doSearch();
      }).catch(() => {});
    },
    editTag(item) {
      this.currentTag = item;
      this.$bvModal.show("editTag");
    },
    deleteTag(item) {
      this.currentTag = item;
      this.$bvModal.show('confirmDeleteTag');
    },
    printTag(item) {
      let route = this.$router.resolve({ path: "/print_tag", query: {id: item.id} });
      window.open(route.href);
    }
  }
}
</script>
