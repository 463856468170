<template>
  <div class="form-group">
    <label v-if="field.label" :for="field.id">{{field.label}}</label>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input
      :id="field.id"
      :aria-describedby="field.name + '-feedback'"
      :value="value"
      @input="$emit('input', $event.target.value, field.name)"
      @keyup="keyup_handler"
      :placeholder="field.placeholder"
      :class="{'form-control': true, 'is-invalid': !!error}"
      :type="field.type || 'text'"/>
    <div
      v-if="!!error"
      :id="field.name + '-feedback'"
      :class="{'invalid-feedback': !!error, 'd-block': !!error}"
      >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCtl",
  props: {
    'value': String,
    'field': Object,
    'error': String,
    'keyup_handler': {
      default: () => {},
      type: Function,
    }
  }
}
</script>

<style scoped>

</style>
