<template>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-center p-4">
        <div class="logo d-flex align-items-center w-auto">
          <img src="assets/img/logo.png" alt="">
          <span class="text-dark d-lg-block">My Stamping Stuff</span>
        </div>
      </div><!-- End Logo -->
      <div class="d-flex justify-content-center">
        <div class="card col-lg-6 col-md-8 col-sm-11 mx-4">
          <div class="card-body">
            <div class="pt-4 pb-2">
              <h5 class="card-title text-center pb-0">Update Password</h5>
            </div>
            <v-alert
                class="my-3"
                variant="danger"
                :show="showError"
                @dismissed="showError=false"
            >
              {{ this.error }}
            </v-alert>

            <v-input
                v-model="password"
                :field="{name: 'password', label: 'Password', placeholder: 'Password', type: 'password', id: 'password_id'}"
            />
            <v-input
                v-model="confirmPassword"
                :keyup_handler="checkKeypress"
                :field="{name: 'confirm_password', label: 'Confirm Password', placeholder: 'Confirm password', type: 'password', id: '_password_id'}"
            />
            <b-button variant="primary" class="w-100" @click="updatePassword">Update</b-button>
            <div class="mt-3 text-center">
              <span class="small link-look" @click="$router.push({name: 'auth'})">Back to login</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Input from '@/pages/form/input';
import {resetPassword} from "./auth-services";
import Alert from "@/pages/form/alert";

export default {
  name: "updatePassword",
  components: {
    'v-input': Input,
    'v-alert': Alert
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      error: null,
      showError: false,
    }
  },
  methods: {
    updatePassword() {
      resetPassword(this.password, this.confirmPassword, this.$route.query.token)
          .then(() => this.$router.push({name: 'auth'}))
          .catch((err) => {
            if (err.response && err.response.data) {
              this.error = err.response.data.error;
              this.showError = true;
            }
          });
    },
    checkKeypress(event) {
      if (event.keyCode === 13) {
        this.updatePassword();
      }
    },
  }
}
</script>
