/*
 Constants used by the API dispatcher to find the proper store mutation to call onStart, onSuccess, onError.

 Types determine the action that the dispatcher will take. If the type === API_REQUEST, then a server call will be made.
 Otherwise, the 'type' will contain the mutation name that should be handled by the global store.
*/

/* actions */
export const API_LOGIN = 'api/auth'
export const API_REQUEST = 'api/doRequest';
export const API_LOGOUT = 'api/logout';
export const API_SET_API_ERROR = 'api/setApiError';

export const APP_UPDATE_ITEM_ID = 'app/updateItemId';

/* onSuccess mutations */
export const SET_AUTH_TOKEN = 'app/setAuthToken';
export const SET_PROFILE = 'app/setProfile';
export const SET_LAST_VERSION_CHECK = 'app/setLastVersionCheck';
export const SET_FORGOT_PASSWORD = 'app/setForgotPassword';
