<template>
  <div class="section-title text-center">
    <div class="my-5">
      Page Not Found
    </div>
    <div>
      Returning you to the dashboard in {{ secondsLeft }} seconds.
    </div>
  </div>
</template>

<script>
export default {
  name: "pageNotFound",
  data() {
    return {
      secondsLeft: 5
    }
  },
  created() {
    this.countDown();
  },
  methods: {
    countDown() {
      if (this.secondsLeft > 0) {
        this.secondsLeft = this.secondsLeft - 1;
        setTimeout(() => this.countDown(), 1000);
      } else {
        // Re-route, ignore any redirection "warnings" dispatched by the router logic. For instance, we try and go
        // to "/" but we are logged in so the router beforeEach call will re-route us to "/dashboard". This
        // re-routing will cause our catch block to fire.
        this.$router.replace('/').catch(() => {});
      }
    }
  }
}
</script>

<style scoped>

</style>
