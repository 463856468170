<template>
  <b-modal id="confirmDeleteTag" :title="`Delete Tag ${tag ? tag.tag : ''}`" ok-title="Delete"
           no-close-on-backdrop
           @ok="deleteItem"
           @hidden="$emit('deleteTagClosed')">
    <div class="text-center">
      <p class="m-auto">
        Are you sure you want to delete
        <br/>
        {{ tag ? tag.tag : '' }}
        <br/>
      </p>
    </div>
  </b-modal>
</template>

<script>
import {deleteTag} from "@/utils/actions";

export default {
  name: "confirmDeleteTag",
  props: {
    'tag': Object,
  },
  methods: {
    deleteItem() {
      deleteTag(this.tag).finally(() => {
        this.$bvModal.hide("confirmDeleteTag");
      });
    }
  }
}
</script>

