<template>
  <b-modal id="apiError" title="Server Error" no-close-on-backdrop ok-only @ok="dismiss" @hide="clearError" v-model="showErrors">
    <div class="text-center mb-4">
      <span>{{ status }}</span>
    </div>
    <div class="text-center mb-2">
      <span>{{ statusText }}</span>
    </div>
  </b-modal>
</template>


<script>
import {clearApiError} from "@/utils/actions";

export default {
  name: "apiError",
  props: {
    'item': Object,
    'admin': Boolean,
  },
  computed: {
    status() {
      const isNumeric = !isNaN(this.$store.getters.apiStatus.status);
      if (isNumeric) {
        return `Server Error ${this.$store.getters.apiStatus.status}`;
      } else {
        return this.$store.getters.apiStatus.status;
      }
    },
    statusText() {
      return this.$store.getters.apiStatus.statusText;
    },
    showErrors: {
      get() {
        const isNumeric = !isNaN(this.$store.getters.apiStatus.status);
        if (!isNumeric && !!this.$store.getters.apiStatus.status) {
          return true;
        } else if (!isNaN(this.$store.getters.apiStatus.status) && this.$store.getters.apiStatus.status >= 400) {
          return true;
        }
        return false;
      },
      set(newValue) {
        return newValue;
      }
    }
  },
  methods: {
    dismiss: function (event) {
      // Prevent modal from closing
      event.preventDefault();

      this.$nextTick(() => {
        this.$bvModal.hide('apiError')
      });
    },
    clearError() {
      clearApiError();
    }
  },
}
</script>
