import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/store/app';
import api from '@/store/api';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        debug: process.env.VUE_APP_DEBUG ? process.env.VUE_APP_DEBUG.toLowerCase() === 'true' : false,
    },
    mutations: {},
    actions: {},
    modules: {
        app,
        api
    }
});
