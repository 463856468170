import axios from "axios";
import store from "@/store";
import router from "@/router"

import {
    API_LOGIN,
    API_REQUEST,
    API_SET_API_ERROR,
    SET_AUTH_TOKEN,
    SET_LAST_VERSION_CHECK
} from "@/utils/actionConstants";
import {clearApiError, logout} from "@/utils/actions";
import {fetchMaker} from "@/pages/makers/maker-services";
import {DateTime} from "luxon";

export function showServerError(error, commit) {
    const status = error.response ? error.response.status : 500;
    const statusText = error.response && error.response.data ? `${error.response.statusText} (${JSON.stringify(error.response.data)})` : error.message;
    commit(API_SET_API_ERROR, {status, statusText});
}

const defaultConfig = {
    baseURL: process.env.VUE_APP_SERVER_BASE_URL, headers: {
        'Content-Type': 'application/json'
    }
};

const actions = {
    async [API_LOGIN]({commit}, request) {
        return new Promise((resolve, reject) => {
            const config = {
                url: '/token/', method: 'post', data: request.payload, baseURL: defaultConfig.baseURL, headers: {
                    ...defaultConfig.headers,
                }
            };

            // Authenticate
            axios.request(config).then((response) => {
                commit(SET_AUTH_TOKEN, {
                    data: response.data, request: request, status: response.status, statusText: response.statusText
                });

                if (store.state.debug) {
                    console.log(`${SET_AUTH_TOKEN} called successfully.`);
                }

                // Successfully authenticated, fetch the user.
                fetchMaker(response.data.maker_id).then(() => {
                    clearApiError();
                    commit(SET_LAST_VERSION_CHECK, DateTime.now().minus({hours: 1}).toISO());
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    },

    async [API_REQUEST]({commit}, request) {
        return new Promise((resolve, reject) => {
            const config = {
                url: request.url,
                method: request.method.toLowerCase(),
                data: request.payload,
                params: request.params,
                baseURL: defaultConfig.baseURL,
                headers: {
                    ...defaultConfig.headers, ...request.headers,
                }
            };

            if (!request.noAuth) {
                config.headers = {
                    ...config.headers, Authorization: `Token ${store.state.app ? store.getters.accessToken : ''}`
                };
            }

            if (store.state.debug) {
                console.log(`Calling ${request.method.toUpperCase()} ${request.url} with payload:`, request.payload);
            }

            axios.request(config)
                .then((response) => {
                    if (request.onSuccess) {
                        commit(request.onSuccess, {
                            data: response.data,
                            request: request,
                            status: response.status,
                            statusText: response.statusText
                        });
                        if (store.state.debug) {
                            console.log(`${request.onSuccess} called successfully.`, response);
                        }
                    } else if (store.state.debug) {
                        console.log(`${request.method.toUpperCase()} ${request.url} succeeded:`, response);
                    }

                    clearApiError();

                    resolve(response);
                })
                .catch((error) => {
                    // Commit the error to the store
                    const status = error.response ? error.response.status : 500;
                    const statusText = error.response ? error.response.statusText : error.message;
                    if (status === 401) {
                        if (store.getters.isLoggedIn) {
                            logout().then(() => {
                                router.push({name: 'auth'});
                            });
                        }
                    } else {
                        if (request.noAlert) {
                            clearApiError();
                        } else {
                            showServerError(error, commit);
                        }

                        // See if action payload provided an onError handler and dispatch
                        if (request.onError) {
                            const payload = {
                                data: null, request: request, status, statusText
                            };
                            commit(request.onError, payload);
                        } else {
                            // Let someone further up the chain know the error occurred.
                            reject(error);
                        }
                    }
                });
        });
    },
}

export default {
    actions,
}
