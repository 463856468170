<script>


import {importMakerProducts} from "@/pages/products/product-services";

export default {
  name: "importEvernote",
  data() {
    return {
      prevRoute: null,
      file: null,
      importing: 0,
      numberImported: 0,
      importErrors: [],
      fields: [
        {key: "item_number", tdClass: 'font-weight-normal', label: 'Item'},
        {key: "message", tdClass: 'text-left font-weight-normal'}
      ]
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  methods: {
    returnToPrior() {
      // Don't go back to root. Go back to the Dashboard if no other path defined.
      if (!this.prevRoute || this.prevRoute.path.length < 2) {
        this.$router.push({name: 'dashboard'})
      } else {
        this.$router.push(this.prevRoute.path);
      }
    },
    importFile(fileList) {
      if (fileList.length > 0) {
        this.file = fileList[0];
      }
    },
    doImport() {
      if (this.importing === 0) {
        this.importing = 1;
        const formData = new FormData();
        // append the files to FormData
        formData.append('file', this.file, this.file.name);
        importMakerProducts(formData, 'enex')
            .then((response) => {
              // Start with an empty list
              this.importErrors = [];
              this.numberImported = response.data.length;
              const data = response.data;
              for (let i = 0; i < data.length; i++) {
                if (data[i].errors.length > 0) {
                  for (let e = 0; e < data[i].errors.length; e++) {
                    this.importErrors.push({
                      item_number: data[i].item_number,
                      message: data[i].errors[e].message
                    });
                    if (data[i].errors[e].type === 'missing_id' || data[i].errors[e].type === 'no_title') {
                      this.numberImported--;
                    }
                  }
                }
              }
              this.importing = 2;
            })
            .catch((err) => {
              console.log(err);
              this.importErrors = `Error ${err.response.status}: ${err.response.data}`;
              this.importing = 3;
            });
      } else if (this.importing >= 2) {
        this.importErrors = [];
        this.numberImported = 0;
        this.file = null;
        this.importing = 0;
      }
    }
  }
}

</script>

<template>
  <div>
    <div class="w-100 d-inline-flex justify-content-center font-weight-bold">
      <b-card id="importEvernote" class="row mt-2 p-0 col-xl-6 col-sm-8 col-12 rounded-3 border border-5">
        <template #header>
          <h4 class="mb-0 text-center">Import Evernote File</h4>
        </template>
        <div class="m-2 bullet-list">
          <p class="font-weight-bolder mb-2">Use this tool to import your Evernote export file (.enex) into MyStampingStuff.</p>
          <h6 class="font-weight-normal mb-2">The following format will be imported:</h6>
          <ul class="font-weight-normal">
            <li>The Item Number must be contained in the note's title</li>
            <li>The product/item number must conform to a <span class="font-weight-bolder font-italic">CTMH</span> or <span class="font-weight-bolder font-italic">Stampin' Up!</span> product identifier</li>
            <li>Tags up to 30 characters will be imported</li>
            <li>Tags are either a defined Evernote tag or a word prefixed with a hashtag such as '#mytag'</li>
            <li>Tags using a hashtag cannot contain spaces (think social media tag)</li>
            <li>First image within each note will be used</li>
          </ul>
        </div>
        <div class="product-picture-wrapper text-center d-inline-block align-items-center">
          <div v-if="importing === 0" class="w-100">
            <div class="justify-content-center mb-3">
              <h6 class="font-weight-bold">Drop your file or click on the example note below.</h6>
            </div>
            <div class="d-inline-block text-center import-box">
              <div v-if="file !== null">Ready to import {{ file.name }}</div>
              <b-img v-else class="pointer-event" src="/assets/img/evernote.png" alt="import image"/>
              <Input type="file" class="file-input" @change="importFile($event.target.files)" accept="application/xml"/>
            </div>
          </div>
          <div v-else-if="importing === 1" class="w-100">
            <h5>Importing...</h5>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
          <div v-else-if="importing === 2" class="w-100">
            <div v-if="importErrors.length > 0" class="justify-content-center mb-3">
              <h5>Imported {{ numberImported }} {{ numberImported === 1 ? 'note' : 'notes' }} successfully. {{ importErrors.length }} {{ importErrors.length === 1 ? 'issue was found.' : 'issues were found.' }}</h5>
                <div class="table-container table-responsive table-bordered table-striped">
                  <b-table primary-key="index" :items="importErrors" :fields="fields"/>
                </div>
            </div>
            <div v-else class="d-flex justify-content-center mb-3">
              <h5>Successfully imported {{ numberImported }} {{ numberImported === 1 ? 'note.' : 'notes.' }}</h5>
            </div>
          </div>
          <div v-else class="w-100">
            <h5>Your import was not successful.</h5>
            <p>{{ importErrors }}</p>
          </div>
        </div>
        <div class="mt-3 d-inline-flex justify-content-center w-100">
          <b-button variant="secondary" class="mr-5" @click="returnToPrior">Done</b-button>
          <b-button variant="primary" :disabled="file == null" @click="doImport">
            {{ importing >= 2 ? 'Import Another' : 'Import' }}
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-picture-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: auto;
  align-content: center;

  img {
    border: rgba(204, 204, 204, 0.6) solid 1px;
    border-radius: 0;
    flex-shrink: 0;
    width: 100%;
  }

  .import-box {
    position: relative;
    width: 320px;
  }

  .file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.bullet-list li {
  font-size: 11pt;
}
</style>