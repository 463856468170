<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand style="font-weight: bold" href="/dashboard"><b-img src="/assets/img/logo.png" height="32px"/>&nbsp;&nbsp;My Stamping Stuff</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"/>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item id="menu_products" :to="{ name: 'dashboard' }">Products</b-nav-item>
        <b-nav-item id="menu_locations" :to="{ name: 'locations' }">Locations</b-nav-item>
        <b-nav-item id="menu_tags" :to="{ name: 'tags' }">Tags</b-nav-item>
        <b-nav-item id="menu_recipes" :to="{ name: 'recipes' }">Recipes</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item id="menu_community" :to="{ name: 'community' }">Community</b-nav-item>
        <b-nav-item id="menu_support" @click="$router.push({name:'support'})">Support</b-nav-item>
        <b-nav-item-dropdown v-if="isAdmin()" class="mr-4" text="Admin" right no-caret>
          <b-dropdown-item :to="{ name: 'product-mgmt' }">Manage Products</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'maker-management' }">Manage Makers</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown id="menu_profile" right no-caret>
          <template v-slot:button-content>
            <font-awesome-icon icon="user"></font-awesome-icon>
          </template>
          <b-dropdown-item>
            <div>
              <span class="font-weight-bold">{{ getUserFullName() }}<br/>
              <small v-if="isAdmin()">Administrator</small>
              </span>
            </div>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="$router.push({name: 'profile'})">Profile</b-dropdown-item>
          <b-dropdown-item @click="$router.push({name: 'importEvernote'})">Import</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>

import {logout} from "@/utils/actions";

export default {
  name: "headerView",
  methods: {
    logout() {
      logout().then(() => this.$router.push({name: 'root'})).catch(() => {});
    }
  }
}
</script>
