<template>
  <b-modal id="deleteRecipe" title="Delete Recipe" ok-title="Delete"
           no-close-on-backdrop
           @ok="$emit('deleteRecipeConfirmed', recipe)">
    <div class="text-center">
      <p class="m-auto">
        Are you sure you want to delete
        <br/>
        <strong>{{ recipe.name }}?</strong>
        <br/>
      </p>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "confirmRecipeDelete",
  props: {
    'recipe': Object,
  }
}
</script>

