<template>
  <b-modal id="editTag" :title="`${ title } Tag`" ok-title="Save" no-close-on-backdrop @ok="saveItem" @hidden="$emit('editTagClosed')">
    <div class="text-center">
      <b-input class="edit-control" id="tag_name" placeholder="tag name" maxlength="50"
               :value="`${localItem.tag}`" v-model="localItem.tag"/>
    </div>
  </b-modal>
</template>

<script>

import {saveTag} from "@/utils/actions";

export default {
  name: "editTag",
  props: {
    'tag': Object,
  },
  data() {
    return {
      localItem: {},
      title: 'Add',
    }
  },
  watch: {
    tag: function (newTag) {
      this.localItem = newTag ? {...newTag} : {};
      this.title = this.localItem.id ? "Edit" : "Add";
    }
  },
  methods: {
    saveItem() {
      saveTag(this.localItem).then(() => this.$bvModal.hide("editTag"))
          .catch(() => this.$bvModal.hide("editTag"));
    }
  }
}

</script>
