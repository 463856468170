<script>

import {validationMixin} from "vuelidate";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import {updateMaker} from "@/pages/makers/maker-services";
import Alert from "@/pages/form/alert.vue";
import {DateTime} from "luxon";
import {cancelSubscription, subscribe} from "@/pages/subscription/subscription-service";

export default {
  name: "profileEdit",
  components: {Alert},
  mixins: [validationMixin],
  data() {
    return {
      fieldTypes: { // add this for change input type
        password: 'text',
        confirmPassword: 'text'
      },
      saved: false,
      saveMessage: 'Your profile update was successfully saved.',
      error: null,
      localMaker: this.extractMakerData(),
      prevRoute: null,
      validation: {},
      showCancelSubscription: false
    };
  },
  validations: {
    localMaker: {
      email: {
        required,
        email
      },
      password: {
        minLength: minLength(5)
      },
      _password: {
        sameAs: sameAs('password')
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  computed: {
    subscription() {
      return this.$store.getters.profile.subscription;
    },
    receipt() {
      if (this.subscription && this.subscription.receipt_url) {
        return this.subscription.receipt_url;
      } else {
        return null;
      }
    },
    subscriptionStartDate() {
      if (this.subscription) {
        return DateTime.fromISO(this.subscription.start_date).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      } else {
        // Should never get here.
        return 'No current subscription'
      }
    },
    subscriptionExpiration() {
      if (this.subscription) {
        return DateTime.fromISO(this.subscription.end_date).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
      } else {
        // Should never get here.
        return 'No current subscription'
      }
    },
    subscriptionType() {
      if (this.$store.getters.hasSubscription) {
        if (this.subscription.status === 'freeTrial') {
          return 'Free Trial';
        } else {
          return 'Active';
        }
      } else if (this.subscription && this.subscription.status === 'inactive') {
        return 'Inactive';
      } else {
        return 'Expired';
      }
    },
    showSubscribe() {
      return this.subscriptionType === 'Free Trial';
    },
    expireLabel() {
      return this.showSubscribe ? 'Expiration:' : this.subscription && this.subscription.cancel_date === null ? 'Auto Renews on:' : 'Expires on:';
    }
  },
  methods: {
    save() {
      updateMaker(this.localMaker).then(() => {
        this.localMaker.password = '';
        this.localMaker._password = '';
        this.saveMessage = 'Your profile update was successfully saved.';
        this.saved = true;
        setTimeout(() => this.returnToPrior(), 3000);
      });
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.localMaker[name];
      return $dirty ? !$error : null;
    },
    returnToPrior() {
      // Don't go back to root. Go back to the Dashboard if no other path defined.
      if (!this.prevRoute || this.prevRoute.path.length < 2) {
        this.$router.push({name: 'dashboard'})
      } else {
        this.$router.push(this.prevRoute.path);
      }
    },
    extractMakerData() {
      return {
        id: this.$store.getters.profile.id,
        last_name: this.$store.getters.profile.last_name,
        first_name: this.$store.getters.profile.first_name,
        email: this.$store.getters.profile.email,
        password: '',
        _password: ''
      };
    },
    handleType(event) {
      const {srcElement, type} = event;
      const {name, value} = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text'
      } else {
        this.fieldTypes[name] = 'password'
      }
    },
    subscribe() {
      // Call subscribe endpoint to redirect to Stripe.
      const rx = /(http(s)?:\/\/.*?)(\/.*)/g;
      const arr = rx.exec(window.location.href);
      const path = arr[3]
      subscribe(path).then((response) => {
        window.location.href = response.data.url
      });
    },
    confirmCancel() {
      this.showCancelSubscription = true;
    },
    cancel() {
      cancelSubscription(this.$store.getters.makerId).then(() => {
        setTimeout(() => window.location.reload(), 1000);
      });
    },

  }
}

</script>

<template>
  <div>
    <div class="w-100 d-inline-flex justify-content-center font-weight-bold">
      <b-card id="profileEdit" class="row mt-5 p-0 col-xl-6 col-sm-8 col-12 rounded-3 border border-5">
        <template #header>
          <h4 class="mb-0 font-weight-bold">Edit Profile</h4>
        </template>
        <template #footer>
          <div class="col d-inline-flex align-items-center justify-content-end">
            <b-button variant="secondary" @click="returnToPrior">Ok</b-button>
          </div>
        </template>
        <div class="w-100 text-center">
          <Alert
              variant="primary"
              :show="saved"
              :show-close="false"
              @dismissed="saved=false">
            {{ this.saveMessage }}
          </Alert>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>First Name:</span>
          </b-input-group>
          <b-input class="col" id="first_name" maxlength="128" :value="`${localMaker.first_name}`"
                   v-model="localMaker.first_name"/>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Last Name:</span>
          </b-input-group>
          <b-input class="col" id="last_name" maxlength="128" :value="`${localMaker.last_name}`"
                   v-model="localMaker.last_name"/>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Email:</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <b-form-input
                id="register-email"
                v-model="$v.localMaker.email.$model"
                type="text"
                class="form-control"
                placeholder="email"
                maxlength="128"
                :state="validateState('email')"/>
            <b-form-invalid-feedback>Please enter your email.</b-form-invalid-feedback>
          </div>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Update Password:</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <b-form-input
                id="password"
                name="password"
                v-model="$v.localMaker.password.$model"
                :type="fieldTypes.password"
                class="form-control"
                placeholder="password"
                :state="validateState('password')"
                @focus="handleType"
                @blur="handleType"
                autocomplete="off"
            />
            <b-form-invalid-feedback>Password must be at least {{ $v.localMaker.password.$params.minLength.min }}
              characters.
            </b-form-invalid-feedback>
            <div v-if="!!error" class="invalid-feedback d-block">{{ error }}</div>
          </div>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Confirm Password:</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <b-form-input
                id="confirm-password"
                name="confirmPassword"
                v-model="$v.localMaker._password.$model"
                :type="fieldTypes.confirmPassword"
                class="form-control"
                placeholder="confirm password"
                :state="validateState('_password')"
                @focus="handleType"
                @blur="handleType"
                autocomplete="off"
            />
            <b-form-invalid-feedback>Your confirmation password doesn't match.</b-form-invalid-feedback>
          </div>
        </div>
        <b-row class="m-2">
          <div class="col m-0 p-0 d-inline-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="save">Save Profile Changes</b-button>
          </div>
        </b-row>
        <div class="row p-0 mx-4 mb-4 mt-3">
          <div class="hb text-bg-info"/>
        </div>
        <div class="row m-2">
          <b-input-group class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span class="">Subscription</span>
          </b-input-group>
          <div v-if="receipt" class="col-lg-8 col-12 p-0">
            <a :href="receipt" target="_blank">Click for Receipt</a>
          </div>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Type:</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <span class="form-control">{{ subscriptionType }}</span>
          </div>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>Start Date:</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <span class="form-control">{{ subscriptionStartDate }}</span>
          </div>
        </div>
        <div class="row m-2">
          <b-input-group
              class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center">
            <span>{{ expireLabel }}</span>
          </b-input-group>
          <div class="col p-0 m-0">
            <span class="form-control">{{ subscriptionExpiration }}</span>
          </div>
        </div>
        <div v-if="showSubscribe" class="row m-2">
          <div class="col-lg-4 col-12 p-0 pr-3 justify-content-lg-end justify-content-start align-items-center"/>
          <div class="col-6 col-sm-4 p-0 m-0">
            <b-button @click="subscribe" variant="warning">Subscribe</b-button>
          </div>
        </div>
        <div v-if="subscription && subscription.cancel_date === null && !showSubscribe" class="row m-2">
          <div class="col-lg-4  p-0 pr-3 justify-content-lg-end justify-content-start align-items-center"/>
          <div class="col-8 p-0 m-0">
            <b-button @click="confirmCancel" variant="warning">Cancel Subscription</b-button>
          </div>
        </div>
      </b-card>
    </div>

    <b-modal v-model="showCancelSubscription" id="confirmCancelSubscription" centered title="Cancel Subscription"
             no-close-on-backdrop
             ok-title="Confirm"
             @ok="cancel">
      <div class="text-center">
        <p>Your subscription will end on</p>
        <p>{{ subscriptionExpiration }}</p>
        <p>Please confirm that you want to cancel your subscription.</p>
      </div>
    </b-modal>

  </div>
</template>

<style scoped lang="scss">

</style>