<template>
  <div ref="scroll-container" class="p-2 rounded-2 bg-white">
    <div class="row mb-2 d-flex align-items-center">
      <div class="col-12 col-lg-3 mb-1 d-flex align-items-center">
        <b-input-group>
          <b-input id="ctmhSearchBar" type="text" placeholder="Search..."
                   v-model="searchText" @input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-4 col-lg-2 d-inline-flex align-items-center">
        <h6 class="mb-0 mr-2">Per Page</h6>
        <b-form-select v-model="perPage" style="width: 65px" @change="updatePerPage"
                       :options="$store.getters.perPageChoices">
        </b-form-select>
      </div>
      <div class="col-3 d-inline-flex justify-content-left align-items-center">
        <b-checkbox v-model="showCustom" @change="doSearch">Show Custom
        </b-checkbox>
      </div>
      <div class="col d-inline-flex align-items-center justify-content-end">
        <ul class="list-inline my-2">
          <li class="list-inline-item">
            <b-btn variant="primary" @click="editItem({})">Add</b-btn>
          </li>
          <li class="list-inline-item">
            <b-btn variant="primary" v-b-modal.fileUpload>Import CSV</b-btn>
          </li>
        </ul>
      </div>
    </div>

    <div class="table-container table-responsive table-bordered table-striped">
      <b-table id="ctmhProductList"
               primary-key="id"
               show-empty
               :current-page="currentPage"
               :items="listItems"
               :fields="fields">
        <template v-slot:table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{width: field.width}"
          >
        </template>
        <template #cell(image)="data">
          <div class="position-relative text-center d-flex p-1">
            <div class="list-image-container">
              <b-img :id="`ctmh_row_${data.item.id}`" :src="getImageUrl(data.item)" class="product-list-thumb"/>
              <span v-if="!hasImage(data.item)" class="no-image-text">No Image</span>
            </div>
            <b-tooltip v-if="data.item.image" :target="`ctmh_row_${data.item.id}`" triggers="hover"
                       placement="right"
                       boundary="viewport">
              <img :src="getProductUrl(data.item.image)" width="256px" height="256px" style="object-fit: contain"
                   :alt="`${data.item.item_number} image`"/>
              <font-awesome-icon v-if="data.item.image && !data.item.isCustom" class="tip-trash" icon="trash" size="lg"
                                 @click="deleteProductImage(data.item)"></font-awesome-icon>
            </b-tooltip>
            <div v-if="!data.item.isCustom" style="width: 12px">
              <font-awesome-icon class="tip-trash" :class="{'d-none': !hasImage(data.item)}" icon="trash"
                                 @click="deleteProductImage(data.item)"></font-awesome-icon>
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <font-awesome-icon v-if="!data.item.isCustom" class="action-button mr-4" icon="edit" @click="editItem(data.item)"/>
          <font-awesome-icon v-if="!data.item.isCustom" class="action-button text-danger" icon="trash" @click="deleteItem(data.item)"/>
          <span v-if="data.item.isCustom" :id="`ctmh_custom-${data.item.id}-${data.item.owner}`">Custom</span>
          <b-tooltip :id="`ctmh_tooltip-${data.item.id}-${data.item.owner}`" v-if="data.item.isCustom" :target="`ctmh_custom-${data.item.id}-${data.item.owner}`" triggers="hover" boundary="viewport">
            {{ getOwnerName(currentOwner) }}
          </b-tooltip>
        </template>
      </b-table>
    </div>

    <div ref="paging" class="paging">
      <b-row class="m-0 page-label">
        <b-col cols="4" lg="4" class="px-0 small">{{ countLabel }}</b-col>
        <b-col cols="8" lg="4">
          <b-pagination v-if="rows > 0"
                        class="mb-0"
                        align="center"
                        size="md"
                        :total-rows="rows"
                        :per-page="perPage"
                        v-model="currentPage"
                        @page-click="pageClick"
                        aria-controls="ctmhProductList"
                        :disabled="searchActive"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

    <FileUpload title="Import CSV File" accept="text/csv" :save-fxn="importCsv"
                @completed="handleCsvUploadComplete"></FileUpload>
    <EditMasterProduct :item="currentItem" @editComplete="onEditDone"/>

    <!-- Confirm Image Delete -->
    <b-modal v-model="confirmDelete" id="confirmDelete" centered
             :title="`Confirm ${isDeleteImage ? 'Image' : 'Product' } Delete`" no-close-on-backdrop
             ok-title="Yes"
             cancel-title="No" @ok="deleteConfirmed">
      <div class="text-center">
        <p>{{ confirmDeleteMessage }}</p>
        <p>Are you sure you want to delete this {{ isDeleteImage ? 'image?' : 'product?' }}</p>
        <p>{{ currentItem ? currentItem.name : '' }} ({{ currentItem ? currentItem.item_number : '' }})</p>
      </div>
    </b-modal>

  </div>
</template>

<script>

import {fetchMaker, updateMaker} from "@/pages/makers/maker-services";
import FileUpload from "@/pages/utilities/fileUpload.vue";
import EditMasterProduct from "@/pages/products/global-products/editMasterProduct.vue"
import {showServerError} from "@/store/api";
import {deleteImage, deleteProduct, productSearch, uploadProductCsv} from "@/pages/products/product-services";

export default {
  name: "ctmhProductList",
  components: {FileUpload, EditMasterProduct},
  data() {
    return {
      fields: [
        {key: "image", tdClass: 'align-middle p-0', width: '80px'},
        {
          key: "item_number",
          label: "Item #",
          tdClass: 'align-middle text-center',
          thClass: 'text-center',
          width: '80px'
        },
        {key: "name", tdClass: 'align-middle', width: '0%'},
        {key: "ideabook.name", label: 'Idea Book', tdClass: 'align-middle', width: '20%'},
        {key: "actions", tdClass: 'list-edit-controls', width: '80px'},
      ],
      productList: [],
      currentItem: null,
      searchText: '',
      showSearchTip: false,
      showImport: false,
      confirmDelete: false,
      isDeleteImage: true,
      confirmDeleteMessage: 'Are you sure you want to delete the image for',
      currentPage: 1,
      perPage: this.$store.getters.perPage,
      showCustom: false,
      totalCount: 0,
      searchActive: false,
      countLabel: '',
      scrollMax: 0,
      paddingHeight: 0,
      owners: {},
      currentOwner: null,
      fetchingOwner: false
    };
  },
  created() {
    let pageNum = 1;
    if (this.$route.query.page) {
      pageNum = Number(this.$route.query.page);
    }
    this.getProducts(pageNum);
    window.addEventListener('scroll', this.captureWindowPosition);
    window.addEventListener('resize', this.setPagingSizes);
  },
  destroyed() {
    window.removeEventListener('scroll', this.captureWindowPosition);
    window.removeEventListener('resize', this.setPagingSizes);
  },
  mounted() {
    this.$root.$on('bv::tooltip::show', bvEvent => {
      this.fetchOwner(bvEvent);
    });
    this.$root.$on('bv::tooltip::hide', bvEvent => {
          if (bvEvent.target.id.startsWith('ctmh_custom-')) {
            this.currentOwner = null;
          }
    });
  },
  updated: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.setPagingSizes();
    })
  },
  computed: {
    listItems() {
      return this.productList;
    },
    rows() {
      return this.totalCount;
    }
  },
  methods: {
    fetchOwner(bvEvent) {
      if (!this.fetchingOwner) {
        this.fetchingOwner = true;
        const target = bvEvent.target;
        if (target.id.startsWith('ctmh_custom-')) {
          const lastDashIdx = target.id.lastIndexOf('-');
          const makerId = target.id.substr(lastDashIdx + 1);
          if (!this.owners[makerId]) {
            fetchMaker(makerId).then(response => {
              this.owners[makerId] = response.data;
              this.currentOwner = response.data;
              this.fetchingOwner = false;
            }).catch(() => this.fetchingOwner = false);
          } else {
            this.currentOwner = this.owners[makerId];
            this.fetchingOwner = false;
          }
        } else {
          this.fetchingOwner = false;
        }
      }
    },
    getOwnerName(owner) {
      if (owner) {
        return `Maker: ${owner.first_name} ${owner.last_name}`;
      } else {
        return '';
      }
    },
    captureWindowPosition() {
      if (window.scrollY < this.scrollMax) {
        this.$refs.paging.classList.add("sticky");
        this.$refs["scroll-container"].setAttribute('style', `padding-bottom: ${this.paddingHeight}px !important;`);
      } else {
        this.$refs.paging.classList.remove("sticky");
        this.$refs["scroll-container"].removeAttribute('style');
      }
    },
    setPagingSizes() {
      // Remove the extra padding before calculating the new dimensions
      this.$refs.paging.classList.remove("sticky");
      this.$refs["scroll-container"].removeAttribute('style');

      // Since we manually updated the DOM, need to ensure that the re-render has completed before performing the
      // calculations. That is done with the requestAnimationFrame + setTimeout
      requestAnimationFrame(() => {
        setTimeout(() => {
          const footer = document.getElementsByTagName('footer')[0]
          this.paddingHeight = (footer.offsetHeight + this.$refs.paging.clientHeight + 16);
          this.scrollMax = document.documentElement.scrollHeight - window.innerHeight - this.paddingHeight + this.$refs.paging.clientHeight;
          // Reset the stickiness if needed
          this.captureWindowPosition();
        });
      });
    },
    getProducts(pageNum, search = null) {
      // Perform the search
      this.searchActive = true;
      productSearch(search, pageNum, this.showCustom).then((response) => {
        this.updateRoutePage('product-mgmt', pageNum);
        this.productList = response.data.results;
        this.totalCount = response.data.count;
        this.currentPage = pageNum;
        this.searchActive = false;
        const start = this.perPage * (this.currentPage - 1);
        this.countLabel = `Showing ${start + 1} to ${Math.min(start + this.perPage, this.totalCount)} of ${this.totalCount} entries`;
      }).catch((err) => {
        this.searchActive = false;
        if (err.response && err.response.status === 404) {
          this.pageClick(null, 1);
        }
      });
    },
    doSearch() {
      this.getProducts(1, this.searchText);
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    pageClick(event, pageNum) {
      // Perform the fetch
      this.getProducts(pageNum, this.searchText);
    },
    async importCsv(formData) {
      return new Promise((resolve, reject) => {
        uploadProductCsv(formData).then((response) => {
          this.searchText = '';
          this.productList = response.data;
          this.totalCount = response.data.length;
          this.currentPage = 1;
          resolve();
        }).catch((error) => {
          let statusText = error.response ? error.response.statusText : error.message;
          const status = error.response ? error.response.status : 500;
          if (status === 415) {
            statusText = "Your CSV file is not saved with a UTF-8 encoding. Please re-export the file from EXCEL using the CSV UTF-8 File Format."
          }
          reject({response: statusText})
        })
      });
    },
    onEditDone() {
      this.currentItem = {};
      this.getProducts(this.currentPage, this.searchText);
    },
    editItem(item) {
      this.currentItem = {...item}
      this.$bvModal.show("editMasterProduct");
    },
    deleteItem(item) {
      deleteProduct(item.id).then(() => this.getProducts(this.currentPage, this.searchText)).catch((err) => {
        if (err.response && err.response.status === 406) {
          this.isDeleteImage = false;
          this.confirmDeleteMessage = err.response.data;
          this.currentItem = item;
          this.confirmDelete = true;
        } else {
          showServerError(err, this.$store.commit);
        }
      });
    },
    deleteProductImage(item) {
      this.isDeleteImage = true;
      this.confirmDeleteMessage = '';
      this.currentItem = item;
      this.confirmDelete = true;
    },
    deleteConfirmed() {
      if (this.isDeleteImage) {
        deleteImage(this.currentItem.id).then((response) => {
          const item = this.productList.find((i) => i.id === response.data.id);
          if (item != null) {
            Object.assign(item, response.data);
          }
        }).catch();
      } else {
        deleteProduct(this.currentItem.id, true).then(() => {
          this.getProducts(this.currentPage, this.searchText);
        }).catch();
      }
    },
    handleCsvUploadComplete() {
    },
    updatePerPage() {
      updateMaker({id: this.$store.getters.makerId, preferences: JSON.stringify({perPage: this.perPage})}).then(() => this.getProducts(1));
    },
  }
}

</script>

<style scoped lang="scss">

.table-container {
  margin-bottom: 120px;
}

.pager {
  margin-bottom: 65px;
}

.tip-trash {
  color: red;
  padding: 2px;
  margin-left: 3px;
  vertical-align: top;
  cursor: pointer;
}

.paging {
  background-color: rgba(255, 255, 255, .75);
}

.sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .page-label {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

</style>
