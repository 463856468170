<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "recipe-card",
  props: {
    recipe: Object,
    staticCard: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    editRecipe(recipe) {
      this.$emit('editRecipe', recipe);
    },
    deleteRecipe(recipe) {
      this.$emit('deleteRecipe', recipe);
    },
    isVisible() {
      return this.recipe.isGlobal || !this.staticCard;
    }
  }
})
</script>

<template>
  <div class="p-2 m-0 mt-0 d-flex">
    <b-card class="rounded-5 w-100">
      <b-card-body class="p-0">
        <b-card-title class="p-0 position-relative">
          <b-row class="m-0 p-0">
            <b-col cols="2" class="p-0">
              <font-awesome-icon v-if="recipe.isGlobal" icon="users"/>
            </b-col>
            <b-col class="p-0 align-items-center text-center">{{ recipe.name }}</b-col>
            <b-col cols="2" class="p-0 d-inline-flex justify-content-end">
              <font-awesome-icon v-if="!staticCard" class="p-0 action-button mr-2" icon="edit"
                                 @click="editRecipe(recipe)"/>
              <font-awesome-icon v-if="!staticCard" class="p-0 action-button text-danger" icon="trash"
                                 @click="deleteRecipe(recipe)"/>
            </b-col>
          </b-row>
        </b-card-title>
        <b-row>
          <div v-if="isVisible()" class="col-12 text-center">
            <div v-if="recipe.images.length > 1">
              <div class="position-relative">
                <b-carousel controls indicators :interval="0">
                  <b-carousel-slide v-for="image in recipe.images" :key="image.id">
                    <template #img>
                      <div class="d-flex justify-content-center align-items-center">
                        <div v-if="isVideo(image)" class="embed-responsive embed-responsive-4by3 recipe-image recipe-video">
                          <video :src="getImageUrl(image)" loop controls muted></video>
                        </div>
                        <b-img v-else :id="`img_${image.id}`" :src="getImageUrl(image)" class="recipe-image"/>
                      </div>
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
            <div v-else>
              <div v-if="isVideo(recipe.images.length > 0 ? recipe.images[0] : {})"
                   class="embed-responsive embed-responsive-4by3">
                <video :src="getImageUrl(recipe.images.length > 0 ? recipe.images[0] : {})" loop controls muted></video>
              </div>
              <b-img v-else :src="getImageUrl(recipe.images.length > 0 ? recipe.images[0] : {})" class="recipe-image"
                     :class="{'no-image': recipe.images.length === 0}"/>
              <span v-if="recipe.images.length === 0" class="text-center no-image-text">No Image</span>
            </div>
          </div>
          <div v-else class="mt-3 position-relative text-center">
            <h5>This recipe has been removed by the owner.</h5>
          </div>
        </b-row>
        <div v-if="isVisible() && recipe.notes" class="mt-2 hb"/>
        <b-row v-if="isVisible() && recipe.notes" class="mt-1">
          <b-col>
            <span class="recipe recipe-card-text" v-html="recipe.notes"></span>
          </b-col>
        </b-row>
        <div :class="recipe.notes ? 'mt-1' : 'mt-2'" class="hb"/>
        <div v-if="isVisible()">
          <b-row v-for="item in recipe.items" :key="item.id" class="recipe-card-text m-0 my-2 align-items-center">
            <b-col cols="12">
              <b-row>
                <b-col cols="4" lg="2" class="px-0">
                  <span :class="{'font-weight-bold': item.makerHasProduct}">{{ item.product.item_number }}</span>
                </b-col>
                <b-col cols="7" lg="10" class="pr-0 text-truncate">
                  <span :class="{'font-weight-bold': item.makerHasProduct}">{{ item.product.name }}</span>
                </b-col>
              </b-row>
              <b-row v-if="item.notes">
                <b-col cols="4" lg="2"></b-col>
                <b-col cols="7" lg="10" class="pr-0">
                  <div class="ml-3">
                    <span class="sub-text">{{ item.notes }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped lang="scss">

.recipe-card-text {
  font-size: smaller;
}

.sub-text {
  font-size: small;
  color: black;
}

.recipe-image {
  border-radius: 15px;
  width: 300px;
  max-width: 90% !important;
  border: lightgray solid 1px;
  background-color: #D4E0E6;
  object-fit: contain;
  -o-object-fit: contain;

  .no-image {
    min-width: 200px !important;
  }
}

.recipe-video {
  margin: 15% !important;
}

@media (min-width: 768px) {
  .recipe-image {
    width: 350px;
  }

  .recipe-video {
    margin: 8% !important;
  }
}

.no-image-text {
  font-size: medium;
}

</style>

<style lang="scss">

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  height: 10px !important;
  width: 10px !important;
  background: gray;
  border: 2px solid black;
  border-radius: 50%;
}

ol.carousel-indicators li.active {
  background: white;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230069d9' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%230069d9' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
</style>