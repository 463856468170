<template>
  <div class="p-2 rounded-2 bg-white">
    <b-row class="row mb-2 align-items-center">
      <div class="col-8 col-lg-5">
        <b-input-group>
          <b-input id="recipeSearchBar" type="text" placeholder="Search..."
                   v-model="searchText" @input="searchList"/>
          <template #append>
            <b-input-group-text>
              <font-awesome-icon icon="times-circle" @click="clearSearch" role="button"></font-awesome-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </div>
      <div class="col-4 col-lg-7 d-flex justify-content-end">
        <b-button id="addUserRecipe" variant="primary" v-b-modal.addEditRecipeModal>
          <font-awesome-icon role="button" class="align-middle"
                             icon="circle-plus"/>
          Add
        </b-button>
      </div>
    </b-row>
    <b-row class="m-1">
      <RecipeCard :static-card="false" :recipe="recipe" v-for="recipe in filteredRecipes" class="col-12 col-md-6 col-xl-4" :key="recipe.id" @deleteRecipe="confirmDelete" @editRecipe="editRecipe"/>
    </b-row>
    <RecipeAdd :recipe="currentRecipe" @recipeComplete="refresh"></RecipeAdd>
    <ConfirmDelete v-if="currentRecipe !== null" :recipe="currentRecipe" @deleteRecipeConfirmed="deleteRecipeConfirmed"/>
  </div>
</template>


<script>
import {defineComponent} from 'vue'
import RecipeCard from "@/pages/recipes/recipe-card.vue";
import RecipeAdd from "@/pages/recipes/recipe-add.vue";
import {deleteRecipe, fetchMakerRecipes} from "@/pages/recipes/recipes-service";
import ConfirmDelete from "@/pages/recipes/recipe-deleteConfirm.vue";

export default defineComponent({
  name: "recipe-list",
  components: {ConfirmDelete, RecipeCard, RecipeAdd},
  data() {
    return {
      recipes: [],
      filteredRecipes: [],
      searchText: '',
      currentRecipe: null,
      searchActive: false,
      global: false,
    }
  },
  created() {
    this.currentRecipe = null;
    this.refresh();
  },
  methods: {
    refresh() {
      this.currentRecipe = null;
      fetchMakerRecipes(this.$store.getters.makerId, this.global).then((response) => {
        this.recipes = response.data.results;
        this.doSearch();
      });
    },
    doSearch() {
      const searchText = this.searchText.trim().toLowerCase();
      this.filteredRecipes = this.recipes.filter(recipe => {
        if (searchText.length === 0 ||
            recipe.name.toLowerCase().includes(searchText) ||
            recipe.notes.toLowerCase().includes(searchText)) {
          return true;
        } else {
          for (let i=0; i < recipe.items.length; i++) {
            if (recipe.items[i].product.name.trim().toLowerCase().includes(searchText) ||
                recipe.items[i].product.item_number.trim().toLowerCase().includes(searchText) ||
                recipe.items[i].notes.trim().toLowerCase().includes(searchText)) {
              return true;
            }
          }
        }
        return false;
      });
    },
    clearSearch() {
      this.searchText = '';
      this.doSearch();
    },
    searchList() {
      this.ctmhDebounce(this.doSearch, 500);
    },
    editRecipe(recipe) {
      this.currentRecipe = {...recipe};
      this.$bvModal.show('addEditRecipeModal');
    },
    confirmDelete(recipe) {
      this.currentRecipe = recipe;
      setTimeout(() => this.$bvModal.show("deleteRecipe"), 100);
    },
    deleteRecipeConfirmed(recipe) {
      deleteRecipe(recipe.id).then(() => {
        this.currentRecipe = null;
        this.refresh();
        this.$bvModal.hide("deleteRecipe");
      }).catch(() => {
        this.$bvModal.hide("deleteRecipe");
      });
    }
  }
})
</script>

<style scoped lang="scss">

</style>